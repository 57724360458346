import React, {useEffect, useState} from "react";
import {useStore} from "./store";
import {Burger} from "./icons";

export default function Header() {

	const [show, setShow] = useState(false)
	const [title, setTitle] = useState('Home')
	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);
	const setFocus = useStore(state => state.setFocus);

	const handleclick = (e) => {
		setHovered(e)
		setShow(false)
		setFocus(e)
	}

	const download = () => {
		return (
//			<a href="/download/download.php" target="_blank"><span
//				className="icon icon-download"></span> Download: NÖ Kulturstrategie 2021 (pdf, 1.8 MB)</a>

		<a href="/download/KulturSTRe_151121_xs.pdf" download target="_bank">dl</a>

		)
	}

	useEffect(() => {
//		if (hovered === -1 || hovered === 99) setTitle('Home')
//		if (hovered >= 0 && hovered <= 4) setTitle('Handlungsfelder')
//		if (hovered >= 5 && hovered <= 8) setTitle('Leitlinien')
//		if (hovered >= 9 && hovered <= 11) setTitle('Fokus')
//		if (hovered >= 12 && hovered <= 14) setTitle('Weitere Informationen')
	}, [hovered])

	function handleKey(e) {
		console.log(e.code)
		if (e.code === "Escape") {
			setShow(false)
		}


	}


	return (
		<div className={'header1'}>
				<div className={'headBox'}>
					<button tabIndex={0} aria-label={'Hauptmenue'} className={'burger'} onClick={() => setShow(!show)}>
						<Burger arg={show}/>
					</button>
				</div>
			{show &&
				<div className={'burgerMenu'}>
					<div className={'list'}>
						{/*<div className={'burgerItem'} onClick={() => handleclick(0)}>Home</div>*/}
						<button tabIndex={0} className={'burgerItem'} onClick={() => handleclick(12)} onKeyDown={handleKey}>Einleitung</button>
						<button tabIndex={0} className={'burgerItem'} onClick={() => handleclick(14)} onKeyDown={handleKey}>Organisationsstrukturen</button>
						{/*<div className={'burgerItem'} onClick={() => console.log("dl")}><a href="/download/KulturSTRe_151121_xs.pdf" download target="_bank">dl</a></div>*/}
						<button tabIndex={0} className={'burgerItem'} onClick={() => handleclick(15)} onKeyDown={handleKey}>PDF herunterladen</button>
						<button tabIndex={0} className={'burgerItemLast'} onClick={() => handleclick(13)} onKeyDown={handleKey}>Impressum</button>
						<button tabIndex={0} className={'burgerItemLast'} onClick={() => handleclick(16)} onKeyDown={handleKey}>Datenschutzerklärung</button>
					</div>
				</div>
			}
		</div>
	)
}
