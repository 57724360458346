import React from "react";

export const contentGuidelines = [
	{ id: 5,
//		heading: '1 | PARTIZIPATIV – KULTURELLE TEILHABE FÜR JEDE UND JEDEN',
		heading: 'PARTIZIPATIV – KULTURELLE TEILHABE FÜR JEDE UND JEDEN',
		icon:'GuidelinePart',
		iconwidth: '4',
		subheading: 'Daher regionales Kulturschaffen unterstützen, kulturelle Zentren weiterentwickeln und das Angebot für kulturelle Bildung ausbauen ',
		longdesc: [
			{descHeader: '', descText: 'Kunst und Kultur leisten einen wesentlichen Beitrag zum gesellschaftlichen Miteinander, zur Lebensqualität und zum Erlebnisreichtum im städtischen und im ländlichen Raum. Darüber hinaus schärft eine starke kulturelle Manifestation das eigenständige Profil des Bundeslandes in einem Europa der Regionen und macht es als Lebensraum von herausragender Qualität erkennbar. Der Ausbau und die Sicherung der kulturellen Grundversorgung gehören zu den wesentlichen Aufgaben der öffentlichen Hand.'},
			{descHeader: 'Grundprinzipien', descText: 'Da die angestrebte weitreichende Versorgung mit kulturellen Angeboten nur über zahlreiche regionale Einrichtungen möglich ist, fällt es in die Verantwortung des Landes Niederösterreich, diesen partnerschaftlich zur Seite zu stehen, sie zu stärken und zu fördern. Im Wissen um die Wechselwirkungen zwischen breiter Kulturakzeptanz und kulturellen Spitzenleistungen gilt es auch, die kulturellen Zentren mit überregionaler Strahlkraft weiter zu stärken. Eine wichtige Aufgabe dabei ist es, die Bürger*innen des Landes in die künstlerischen, kulturellen und wissenschaftlichen Gestaltungs- und Rezeptionsprozesse einzubinden.'},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Zentrale, regionale und überregionale Positionierung des Kulturangebots',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Um in den Bereichen des künstlerischen Schaffens und der Kulturarbeit einen hohen Wirkungsgrad zu erzielen, muss eine ausgewogene örtliche Verteilung der Kulturaktivitäten erfolgen. In dezentral angesiedelten Kompetenzzentren werden spezielle Standortqualitäten für das gesamte Land nutzbar gemacht. Diese sind als kulturelle Zentren zu verstehen, deren Wirken in das Land und über dessen Grenzen hinaus ausstrahlen soll. Lokale Kulturinitiativen als dezentrale Angebote bedürfen besonderer Unterstützung und Förderung. ',
			},
			{
				InfoboxItemTitle: 'Stärkung des Ehrenamtes ',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Ehrenamtliche und freiwillige Tätigkeit ermöglicht, dass Kulturleben dezentral stattfinden kann – gestaltet von Bürger*innen für Bürger*innen. Das Ehrenamt schafft vielfach erst jenen finanziellen Freiraum, den es für die Realisierung von Kulturprojekten braucht. In großem Respekt für die Tatsache, dass sich ehrenamtlich Tätige besonders engagiert für eine Sache einsetzen, sollen sie mehr als bisher in ihren Aktivitäten Unterstützung bekommen. Daher wird es als wichtige Aufgabe angesehen, sie in ihrem Tun zu stärken, indem die Rahmenbedingungen für freiwillige Tätigkeiten verbessert werden und das Bewusstsein um die Bedeutung des Ehrenamts in der Öffentlichkeit noch klarer sichtbar gemacht wird. Geschehen kann dies durch die Bemessung von materiellen Förderungen sowie durch die Bereitstellung von Weiterbildungsmöglichkeiten und Beratung. Beispielgebend in diesem Zusammenhang sind zwei Initiativen der Kultur.Region.Niederösterreich: die erfolgreich eingeführte Akademie in Schloss Atzenbrugg mit einem praxisnahen, spartenübergreifenden und interdisziplinären Weiterbildungsangebot sowie die Anlaufstelle „Service Freiwillige“, die Kulturverantwortliche mit Entscheidungsträger*innen anderer großer Dachorganisationen vernetzt.',
			},
			{
				InfoboxItemTitle: 'Ausbau der Kunst- und Kulturvermittlung',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Ziele und Standards für die Vermittlungsqualität sind gemeinsam mit den jeweiligen Akteur*innen sowie Institutionen zu definieren. Diese Standards werden durch regelmäßige, gezielte und praxisnahe Schulungen jener sichergestellt, die Kunst und Kultur vermitteln. So dient eine geplante Plattform der Kultur.Region.Niederösterreich dem Ausbau der Kunst- und Kulturvermittlung. In diesem Bereich gilt es, auch neue Akzente zu setzen: zum einen ausdifferenziert nach den verschiedenen Kultursparten wie Musik oder Theater; zum anderen durch die Entwicklung innovativer Formate für die Vermittlung kultureller Kompetenzen. Im Sinne einer qualitativen Optimierung wird ein enger Austausch mit universitären Einrichtungen angestrebt. Schließlich soll die Vermittlung die Türen für einen Dialog öffnen, der auch auf eine weitgehende Partizipation abzielt.',
			},
			{
				InfoboxItemTitle: 'Weiterentwicklung kultureller Bildungsangebote',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Am nachhaltigsten wirkt kulturelle Bildung auf dem Weg des aktiven künstlerischen Schaffens von Kindern und Jugendlichen. Die Basis dafür soll in den kommenden Jahren im Rahmen der Erweiterung von Musikschulen zu Musik- und Kunstschulen flächendeckend in allen Regionen gelegt werden. Auf die kulturelle Bildung und Ausbildung gilt es, in den kommenden Jahren besonderes Augenmerk zu richten. Da kulturelle Inhalte derzeit im Regellehrplan nur unzureichend Niederschlag finden, muss deren Vermittlung außerhalb des Kernbereichs Schule erfolgen. Bereits in der Vergangenheit war die Vermittlung ein wichtiger Aufgabenbereich jeder Kunst- und Kultureinrichtung in Niederösterreich. Eine Ausweitung der diesbezüglichen Aktivitäten ist strategisch wichtig. Bei möglichst allen künstlerischen und kulturellen Angeboten soll daher bereits in den Phasen der Programmgestaltung und der Produktion professionelle Vermittlung als integraler Bestandteil mitgedacht werden. ',
			},
			{
				InfoboxItemTitle: 'Gewährleistung niederschwelliger Kulturangebote',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Um das Kulturangebot möglichst vielen Menschen zugänglich zu machen, ist weiter am Abbau von Zugangsschwellen zu arbeiten. Dies hat einerseits durch Vermittlungsaktionen zu erfolgen, die sich gezielt an Gruppen mit geringer Kulturaffinität richten. Andererseits gilt es, regionale Bedingungen zu berücksichtigen – wie etwa bei dem Projekt „Region braucht Kultur. Kultur braucht Region“, das in allen 573 Gemeinden Niederösterreichs Kulturangebote sicherstellen will. ' +
					'\n' +
					'Der Abbau von Zugangsschwellen bedeutet nicht zuletzt, leistbare Eintrittspreise zu gewährleisten, Vergünstigungen zu schaffen oder zu prolongieren. Um auch in der Zukunft auf eine ausreichende Zahl von ehrenamtlich und freiwillig Tätigen bei kulturellen Einrichtungen bzw. Veranstaltungen zählen zu können, sind junge Menschen durch gezielte Maßnahmen zur kulturellen und kreativen Betätigung zu motivieren – etwa durch vermehrte Calls im Bereich der Jugendkulturförderung.' +
					'',
			},
		],
	},
	{ id: 6,
//		heading: '2 | KREATIV – NEUE WEGE DURCH INNOVATION',
		heading: 'KREATIV – NEUE WEGE DURCH INNOVATION',
		icon:'GuidelineCreat',
		iconwidth: '2.5',
		subheading: 'Daher durch innovative Wege der kulturellen Vielfalt offen begegnen und damit der Kreativität Raum geben',
		longdesc: [
			{descHeader: '', descText: 'Als digitales, von den Medien stark geprägtes Zeitalter bringt das 21. Jahrhundert besondere Herausforderungen mit sich. So wie die Mittel der Kommunikation laufend im Umbruch sind, beschreitet auch die Kunst neue Wege. ' +
					'\n' +
					'Grundlage aller Schaffenskraft und Ideen ist Kreativität – sie verleiht Kunst und Kultur Ausdruck und Form. Das Neue, das entsteht, bereitet Innovation und Entwicklung den Boden. Vielen Künstler*innen ist es ein wesentliches Ansinnen, Etabliertes zu hinterfragen und erstarrte Normen aufzubrechen. Gerade von der zeitgenössischen Kunst gehen dabei wichtige Denkanstöße aus. Die Auseinandersetzung mit kreativen Leistungen kann das Publikum wiederum dazu befähigen, sich von vorgefertigten Meinungen zu emanzipieren, in Diskurse einzutreten und sich an der gesellschaftlichen Entwicklung zu beteiligen. Zum Ausbau des gegenseitigen Verständnisses trägt eine tiefgehende Auseinandersetzung mit Kunst und Kultur fundamental bei. Die daraus resultierende Toleranz für kulturelle Vielfalt unterstützt dabei, sich den Aufgaben der Zukunft mutig zu stellen. ' +
					''},
			{descHeader: 'Grundprinzipien', descText: 'Wesentliche Anliegen der Kulturstrategie sind die Förderung der Offenheit für Kultur in all ihren Facetten und die wechselseitige Befruchtung von Breite und Spitze. Um der Kreativität Anstöße zu geben, gilt es, die Freiräume zur Entfaltung zu erweitern und die Möglichkeiten für die Erschließung des kreativen Potenzials in allen Altersgruppen und Landesteilen zu vertiefen. '},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Schaffung eines kreativitäts- und innovationsfreundlichen Klimas ',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Motivation zum Überschreiten der Grenzen von Konventionen und Routinen geht Hand in Hand mit der Akzeptanz unkonventioneller künstlerischer Leistungen – wie etwa in der bildenden und performativen Kunst, in Literatur und Musik, im Film und in der Medienkunst. ' +
					'\n' +
					'Jede Form von Kulturarbeit soll einen niederschwelligen Zugang zu Kunst und Kultur ermöglichen, den Abbau allfälliger Berührungsängste unterstützen und in weiterer Folge Freiräume für die Entfaltung eigener Kreativität schaffen. Hierfür gilt es, entsprechende Rahmenbedingungen zu etablieren. Ein bereits bestehendes erfolgreiches Format dafür stellen die über 100 Kulturwerkstätten in allen Regionen Niederösterreichs dar – Orte autonomen kreativen Handelns ohne jegliche programmatische Einschränkung. Sie sollen weiter unterstützt und ausgebaut werden.' +
					'\n' +
					'Ein solches Klima macht Mut zu Experimenten und soll gewährleisten, dass Menschen jeden Alters, v. a. auch Kinder und Jugendliche, Zugang zur Kreativitätsförderung bekommen. Dafür gilt es, Frei- und Schutzräume für dynamische Kreativitätsentfaltung zu sichern, das Scheitern als Nährboden für neue Kreativität zu schätzen und gestalterischen Mut zu würdigen. Beispielhaft geschieht dies im Rahmen des Donaufestivals, das auch künftig als Kraft- und Experimentierfeld der Avantgarde dienen soll. Innovation und kreatives Chaos in einem positiven Sinn sind beispielhaft in den Viertelfestivals und in Projekten lebendig, die im Rahmen der Jugendkultur-Förderung „come on“ Unterstützung erfahren. Diese und ähnliche Programme und Calls sollen weiterhin unterstützt und entwickelt werden – ebenso wie die Initiative „Kultur4kids“, die das Kunst- und Kulturprogramm des Landes Niederösterreich für junge Menschen bündelt und so Kinder und Familien den Zugang zu Kunst und Kultur erleichtert.' +
					'',
			},
			{
				InfoboxItemTitle: 'Stärkung der Instrumente der Begabtenförderung',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Das gleichberechtigte Nebeneinander von Hochkultur und Breitenkultur setzt einen wechselseitig befruchtenden Kreislauf in Gang: Die Breite bildet das kreative Potenzial für die Zukunft, die Spitze motiviert den Nachwuchs zu Aktivität und Leistung. ' +
					'\n' +
					'Um eine vitale Entwicklung an der Spitze zu gewährleisten, gilt es, Begabungen früh zu erkennen und optimal zu fördern. Ein wesentlicher Aspekt der Kulturstrategie des Landes Niederösterreich ist daher die Begabtenförderung, die bereits im Kindergartenalter einsetzt: Frei von Leistungsdruck sind die Kleinsten in ihrer Kreativitätsentfaltung durch altersadäquate Angebote gezielt zu unterstützen. Best-Practice-Beispielen wie dem Jugendmusikwettbewerb „prima la musica“ oder der Exzellenzförderung am Campus Grafenegg, aber auch erfolgreichen Formaten in den Genres Pop, Rock und Jazz sowie Volksmusik wird auch künftig eine wesentliche Rolle zukommen. Weitere interdisziplinäre Wettbewerbsformate sind in den kommenden Jahren zu entwickeln.' +
					'',
			},
			{
				InfoboxItemTitle: 'Förderung der Offenheit für kulturelle Vielfalt und Interdisziplinarität',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Neues, Ungewöhnliches und Gewagtes in der Kunst finden selten ungeteilte Zustimmung, werden in Niederösterreich aber auch künftig Platz haben. Das schließt innovative künstlerische Entwicklungen auf Grundlage des technologischen Fortschritts ein. Werke changieren hier oftmals zwischen den verschiedenen Domänen der künstlerischen Produktion, aber auch transdisziplinär mit Fachgebieten der Wissenschaft. Solche Tendenzen sollen in Niederösterreich in ihrer Komplexität wahrgenommen und unterstützt werden.' +
					'\n' +
					'Dass Tradition und Avantgarde hier nicht jeweils für sich stehen, sondern an vielen Schnittstellen miteinander kommunizieren, macht Niederösterreich unverwechselbar – auch in Zukunft. ' +
					'\n' +
					'Kunst und Kultur können zudem Räume für die menschliche Begegnung schaffen, gerade dort, wo die Kommunikation im Alltag herausfordernd ist – nämlich zwischen Menschen unterschiedlicher Herkunft oder mit unterschiedlichen Identitäten. So müssen Kunst und Kultur eine wichtige Rolle beispielsweise in der aktiven Einbeziehung von Menschen mit Migrationshintergrund oder Menschen mit Benachteiligungen einnehmen, etwa durch niederschwellige, partizipative und vernetzende Initiativen im Bereich der Inter- und Transkultur.' +
					'',
			},
		],
	},
	{
		id: 7,
//		heading: '3 | KOOPERATIV – DIE TRAGKRAFT DER VERNETZUNG STÄRKEN',
		heading: 'KOOPERATIV – DIE TRAGKRAFT DER VERNETZUNG STÄRKEN',
		subheading: 'Daher Einbindung und Beteiligung – Netzwerke regional, national und grenzüberschreitend festigen und erweitern',
		icon:'GuidelineCoo',
		iconwidth: '3.2',
		longdesc: [
			{
				descHeader: '',
				descText: 'Nie waren Kontaktaufnahme, Vernetzung und Dialog so einfach zu bewerkstelligen wie heute. Globalisierung, Digitalisierung und die Corona-Pandemie mit ihren Herausforderungen, aber auch Chancen haben uns ein Rüstzeug dafür in die Hand gegeben, tragfähige Partnerschaften aufzubauen und Netze zu knüpfen. Das gilt es nun zu nutzen.' +
					'\n' +
					'Denn durch ein Miteinander können Stärken gestärkt, Menschen gefordert und gefördert, kann der innovative und kreative Output vergrößert werden. Je engmaschiger das Netz, desto kürzer die Wege, je stärker die Bindungen, desto größer Vertrauen und gegenseitiger Halt.' +
					'',
			},
			{
				descHeader: 'Grundprinzipien',
				descText: 'Zu den Träger*innen der niederösterreichischen Identität gehören ganz wesentlich die Akteur*innen der Kunst- und Kulturszene. Die Impulse, die sie geben, kommen der Allgemeinheit, der Wissenschaft, aber auch dem Wirtschaftsstandort Niederösterreich zugute. Sie sind künftig noch stärker als gleichberechtigte Partner*innen einzubeziehen. Durch engere Vernetzung sollen das Interesse am Anderen und (Fach-)Fremden, das Bedürfnis nach Austausch, gegenseitiger Unterstützung und befruchtendem Diskurs zur tragfähigen, zukunftsträchtigen Kraftquelle werden.' +
					'\n' +
					'In Sachen Vernetzung sind die Kultur.Region.Niederösterreich und ihre Betriebe unverzichtbar. Nicht nur bringen sie Akteur*innen in Gemeinden und Regionen zusammen, sie unterstützen auch Verantwortliche für Kulturangelegenheiten und für Tourismus durch Service und Information. Durch den Fokus auf regionale Kulturangebote – etwa im Umland Wiens – will man auch die Bindung zwischen Gemeinde und Bevölkerung stärken.' +
					'',
			}
		],
		infobox: [
			{
				InfoboxItemTitle: 'Verdichtung der Kooperationen innerhalb des Kunst- und Kulturgeschehens',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Niederösterreichs Kulturlandschaft prägen und strukturieren zahllose Kultureinrichtungen, die unterschiedliche Funktionen erfüllen. Um Doppelgleisigkeiten und Konkurrenzsituationen zu vermeiden, kommt der Kulturverwaltung und -förderung sowie den Kulturinstitutionen selbst die Aufgabe zu, Kulturprogramme sowie deren Bewerbung künftig noch stärker organisatorisch, zeitlich und inhaltlich abzustimmen und zu vernetzen. Etwa, indem Kultur- und Tourismus-Angebote in den digitalen „Veranstaltungskalender Niederösterreich“ eingespeist werden, der Interessierten einen stets aktuellen Überblick über Veranstaltungen im Bereich Kunst und Kultur bietet. Beispielgebend für die Zukunft sind diesbezüglich Formate wie der „Kultursommer Niederösterreich“ „Kultur4kids“ oder „Kultur beim Winzer“: Sie bauen auf der digitalen Datenbank auf und informieren ziel- und kundenorientiert. ' +
					'\n' +
					'Mit dem 2017 eröffneten Haus der Geschichte im Museum Niederösterreich in St. Pölten und mit der in Gehdistanz zum Kulturbezirk befindlichen ehemaligen jüdischen Synagoge, die bis 2024 Sanierung, Adaptierung und inhaltliche Neuausrichtung erfährt, wird in den kommenden Jahren der Weg der Vernetzung und wissenschaftlichen Begleitung weitergeführt. Vor allem die als Leuchttürme wahrgenommenen Kultureinrichtungen des Landes wie die Schallaburg, Grafenegg oder die Kunstmeile Krems bieten die Möglichkeit, auch überregional und international in einen kulturellen Austausch zu treten. Darauf soll auch der Leihverkehr der Landessammlungen Niederösterreich ausgerichtet werden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Vernetzung mit Bildungs-, Wissenschafts- und Forschungseinrichtungen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Kunstschaffende, Kulturbetriebe, Bildungs- und Wissenschaftseinrichtungen sowie die Forschung zusammenzubringen, ist von hoher Bedeutung für die künftige Entwicklung des Landes. Bestehende Kooperationen mit Fachhochschulen und Universitäten sollen daher erweitert und die geschaffenen Strukturen weiter verdichtet werden. ' +
					'\n' +
					'Das Land Niederösterreich sieht es als Aufgabe, Kooperationen zwischen Kulturbetrieben und Bildungseinrichtungen anzuregen, partizipative Projekte durchzuführen und Kulturbetriebe bei der Schaffung von maßgeschneiderten Vermittlungsprogrammen – beispielsweise für Kinder, Jugendliche, Familien und Senior*innen – zu unterstützen. Kooperationen zwischen schulischen und außerschulischen Organisationen sollen verstärkt und mit klaren Vorgaben und Zielsetzungen betrieben werden. Die Weiterentwicklung von Musikschulen hin zu Musik- und Kunstschulen ist in die Wege geleitet. Damit geht die Notwendigkeit der Schaffung neuer Berufsbilder von Kunstlehrenden Hand in Hand. Darüber wird aktuell in intensivem Austausch mit Kunstuniversitäten diskutiert, um in den kommenden Jahren entsprechende Studienangebote zu entwickeln.' +
					'',
			},
			{
				InfoboxItemTitle: 'Stärkung der Beziehungen zur Wirtschaft',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Vernetzung von Kultur und Wirtschaft läuft in Niederösterreich in einem partnerschaftlichen Prozess ab. Sie ist nicht zuletzt seit Gründung der NÖ Kulturwirtschaft GesmbH (NÖKU) nicht mehr wegzudenken. Die NÖKU-Gruppe umfasst mit aktuell 14 Tochtergesellschaften fast 40 künstlerische und wissenschaftliche Marken / Institutionen, die zu starken Kulturmarken wurden und großteils überregional bzw. auf den zentraleuropäischen Raum hin ausgerichtet sind. Die gelebte Public-Private-Partnerschaft der NÖKU hat sich in vielfacher Weise bewährt und ermöglicht, flexibel auf neue Herausforderungen zu reagieren.' +
					'\n' +
					'Die öffentlichen Ausgaben für Kunst und Kultur werden laufend einer kritischen Analyse unterzogen. Aus volkswirtschaftlicher Sicht steht freilich außer Zweifel, dass sich Kulturförderung positiv auf die Wirtschaft und die Schaffung sowie Sicherung von Arbeitsplätzen auswirkt. Im Rahmen des Regionalisierungsprogramms, umgesetzt von ecoplus, der Wirtschaftsagentur des Landes Niederösterreich, soll daher auch weiterhin der Ausbau der kulturellen Infrastruktur tatkräftig unterstützt werden.' +
					'\n' +
					'Ein wesentliches Bestreben der Abteilung Kunst und Kultur für die Zukunft ist es, die Finanzierungsbereitschaft der Wirtschaft durch die Schaffung geeigneter Rahmenbedingungen zu steigern.' +
					'',
			},
			{
				InfoboxItemTitle: 'Gemeinsame Weiterentwicklung von Kunst und Kultur durch grenzüberschreitendes Denken und Handeln',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Im Bewusstsein seiner historischen Rolle als Brückenbauer im Zentrum Europas ist Niederösterreich engagierter und verlässlicher Partner in einem Europa der Regionen. Im außenwirksamen Handeln des Landes Niederösterreich nimmt die Auslandskultur einen integralen Teil ein. Der Schwerpunkt der Auslandskultur-Kooperationen liegt auf der Zusammenarbeit mit den traditionellen Partnerländern, den Nachbar- und den Donauregionen. ' +
					'\n' +
					'Die neu erarbeitete „Auslandskulturstrategie Niederösterreich“ orientiert sich einerseits an übergeordneten europäischen Zielen und Strategien und ist andererseits auf die Strategien des Tourismus, der Wissenschaft und der Wirtschaft Niederösterreichs abgestimmt. Sie zielt darauf ab, das Kunst- und Kulturland Niederösterreich in Europa und darüber hinaus bekannt zu machen, den Kunst- und Kulturtätigen des Landes zu Sichtbarkeit zu verhelfen und ideale Rahmenbedingungen für das aktive Mitgestalten interkultureller Beziehungen sowie die Teilnahme am kulturellen Austausch und Dialog zu schaffen, wie er heute beispielsweise bereits im Haus der Regionen der Volkskultur Niederösterreich in Krems-Stein oder im Rahmen der „Europäischen Literaturtage“ geführt wird. ' +
					'\n' +
					'Die Abteilung Kunst und Kultur versteht sich darüber hinaus als Anlauf- und Beratungsstelle mit hoher fachlicher Expertise für die vielfältigen nationalen und europäischen Förderinstrumente im Kulturbereich. Das Wissen um und der Zugang zu Förderinstrumenten auf Bundes- und vor allem auf europäischer Ebene sollen weiter vertieft werden, um die Abteilung Kunst und Kultur in ihrer Funktion als Drehscheibe und Informationsstelle zu stärken.' +
					'',
			},
		]
	},
	{ id: 8,
//		heading: '4 | DIVERS – VIELFALT UND GLEICHBERECHTIGUNG LEBEN ',
		heading: 'DIVERS – VIELFALT UND GLEICHBERECHTIGUNG LEBEN ',
		icon:'GuidelineDivers',
		iconwidth: '3',
		subheading: 'Daher ein klares Bekenntnis zu Inklusion, Integration, Diversität und Gendergerechtigkeit',
		longdesc: [
			{descHeader: '', descText: 'Gesellschaftliche Spannungsfelder und Transformationsprozesse sind Gegenstand kulturellen und künstlerischen Schaffens und dienen diesem als Inspiration. Das bewusste Hinterfragen von Rollenbildern und die Fokussierung auf individuelle Fähigkeiten und Bedürfnisse abseits traditioneller Stereotype haben nicht nur große gesellschaftliche Relevanz, sondern sind auch wichtige Aktionsfelder für Kunst und Kultur. Indem sie Ungleichheiten, Diskriminierung und Ausgrenzung zum Thema machen, können Kunst und Kultur Brücken schlagen, um Menschen unterschiedlicher Herkunft, mit verschiedenen Lebensweisen, unterschiedlicher sexueller Orientierung und Identität, mit anderen religiösen Prägungen und jeweils eigenen Wertvorstellungen zusammenzuführen. Die Abteilung Kunst und Kultur bekennt sich zu dieser Verantwortung und wird ihr eigenes Tun verstärkt auf Inklusion, Integration, Diversität und Gendergerechtigkeit ausrichten.'},
			{descHeader: 'Grundprinzipien', descText: 'Kultur in ihrem weitesten Sinn – verstanden als „Gesamtheit der einzigartigen geistigen, materiellen, intellektuellen und emotionalen Aspekte, die eine Gesellschaft oder eine soziale Gruppe kennzeichnen“ (UNESCO) – fällt die Aufgabe zu, Barrieren gleich welcher Art abzubauen und Menschen gleich welchen persönlichen Hintergrundes zusammenzubringen. Chancengleichheit, Diversität und Vielfalt sowie Inklusion haben daher als durchgehende Prinzipien zu gelten. Diese Prinzipien heißt es jedoch noch besser sichtbar zu machen und an ihrer Umsetzung beständig weiterzuarbeiten. '},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Abbau von Zugangsbarrieren',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Barrierefreiheit – verstanden als Gewährleistung des diskriminierungsfreien Zugangs zu Dienstleistungen und Waren – ist in Österreich gesetzlich verankert. Die Abteilung Kunst und Kultur sieht sich dem Gedanken einer Barrierefreiheit im umfassenden Sinn verpflichtet, der nicht zwischen Personengruppen unterscheidet, sondern die Bedürfnisse aller Menschen berücksichtigt. Ziel ist es, diese umfassende Barrierefreiheit durch das Setzen entsprechender Maßnahmen zu erreichen. Das schließt ein, generationenübergreifend Menschen mit unterschiedlichen Lebenswirklichkeiten die aktive oder passive Teilhabe am Kulturgeschehen zu ermöglichen. Ein niedrigschwelliges Angebot muss sicherstellen, dass alle Mitglieder einer vielfältigen Gesellschaft, unabhängig von Alter, Bildungsstand, Herkunft und finanziellen Möglichkeiten, an Kunst und Kultur herangeführt werden können. ' +
					'\n' +
					'Ein wichtiger Aspekt von Zugänglichkeit ist auch die Erreichbarkeit mit öffentlichen Verkehrsmitteln, auf die künftig besonderes Augenmerk gerichtet werden soll.' +
					'',
			},
			{
				InfoboxItemTitle: 'Förderung von Integration',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Kultur kommt eine wichtige Rolle bei der Inklusion von Bevölkerungsgruppen zu, die nur schwer Zugang zu kulturellen Angeboten finden. Im Besonderen soll Personen mit Migrationsbiografie ermöglicht werden, gleichberechtigt an künstlerischen und kulturellen Prozessen mitzuwirken und sich selbst zu verwirklichen. Dies soll durch ein auf die Bedürfnisse der jeweiligen Zielgruppen ausgerichtetes Kunst- und Kulturangebot, begleitet von entsprechenden Vermittlungsaktivitäten, erreicht werden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Schaffung von Orten der Begegnung und des Dialogs',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Kunst- und Kulturangebote können Begegnungs- und Kommunikationsplattformen für das Publikum, für Kunst- und Kulturtätigen sowie die Veranstalter*innen sein. Dieser soziale und kommunikative Aspekt soll gezielt angestrebt und aktiv gestaltet werden. Kulturelle Veranstaltungen, Ausstellungen und Museen gilt es zu inter- und transkulturellen Begegnungsräumen, zu Orten des Dialogs und des Diskurses auszubauen. Bei der Programmgestaltung, bei den Vermittlungsaktivitäten und beim Veranstaltungsdesign sind soziale und gesellschaftliche Aspekte im Sinne der Integrationsförderung zu berücksichtigen.' +
					'',
			},
			{
				InfoboxItemTitle: 'Herstellung von Chancengleichheit',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Bei allen Planungs- und Entscheidungsprozessen gilt es, Gleichstellungsaspekte zu beachten und für deren Umsetzung Verantwortung zu tragen (Gender Mainstreaming). Ziel ist es, die tatsächliche Gleichstellung von Frauen und Männern zu erreichen. Dieses Ziel soll im Bereich von Verwaltung, Genehmigungen und bei der Abwicklung von Kulturförderungen sowie der Bestellung von Fachbeiräten und Gutachtergremien Berücksichtigung finden. Die Teilnahme am Fairnessprozess und die Berücksichtigung von Prinzipien wie „fair pay“ und „equal pay“ sind auch von Projektpartnern einzufordern. ' +
					'\n' +
					'2009 als Teilstrategie von Gender Mainstreaming in der Bundesverfassung verankert, verpflichtet Gender Budgeting Bund, Länder und Gemeinden dazu, bei der Haushaltsführung die gerechte Verteilung der finanziellen Mittel zwischen den Geschlechtern zu gewährleisten. Bis spätestens Ende 2026 soll eine erstmals vorgenommene Gender-Budgeting-Analyse der ausgabenseitigen Ansätze im Bereich Kunst und Kultur auf Grundlage der neun 2017 vom Land Niederösterreich vordefinierten Verwirklichungschancen abgeschlossen werden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Monitoring',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Um die Wirksamkeit diversitätssensibler Fördermaßnahmen im Auge zu behalten, wurde ein Monitoring entwickelt, das auch Fördernehmer*innen dazu verpflichtet, entsprechende Daten in statistische Datenblätter einzutragen, um eine laufende Evaluierung zu ermöglichen. Durch das Monitoring lässt sich die Ist-Situation kontinuierlich analysieren und lassen sich Korrekturschritte für das Handeln ableiten. Im Kulturbericht der Abteilung Kunst und Kultur wird bereits eine doppelseitige Statistik der Förderungsvergaben nach Gendergesichtspunkten publiziert. Diese Statistik soll in Zukunft ausgebaut werden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Materielle und immaterielle Förderungsmaßnahmen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Ein mittelbarer Beitrag zur Herstellung von Chancengleichheit besteht darin, die kulturellen Leistungen von Frauen explizit sichtbar zu machen. Das kann etwa durch die umfassende Dokumentation der Beiträge von Frauen zur Entwicklung Niederösterreichs oder durch das bewusste Aufgreifen von Frauenthemen in breitenwirksamen Veranstaltungen geschehen. Auch Mentoring-Programme für Künstlerinnen in Fächern, die durch die Unterrepräsentation von Frauen charakterisiert sind, gilt es zu forcieren.' +
					'\n' +
					'Die Abteilung Kunst und Kultur bekennt sich zur gendergerechten und diskriminierungsfreien Formulierung und setzt mit der Verwendung des Gendersternchens in der internen und externen Kommunikation ein sichtbares Zeichen.' +
					'',
			},
		],
	},
]

