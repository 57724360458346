import React from "react";

export const contentFocus = [
	{ id: 9,
		heading: 'KUNST & KULTUR FÜR DIE ZUKUNFT SCHAFFEN ',
		subheading: '',
		longdesc: [
			{
				descHeader: '',
				descText: 'Kunst und Kultur in all ihren Spielarten haben herausragende Bedeutung für die Entwicklung unserer Gemeinschaft. Sie sind Ausdruck des menschlichen Daseins: Seismografisch greifen sie gesellschaftliche Debatten auf und spiegeln sie wider. Kunst und Kultur zwingen dazu, sich mit der Wirklichkeit und relevanten Themen der Gegenwart auseinanderzusetzen. Zugleich zeichnen sie Visionen einer Gesellschaft von morgen.' +
					'\n' +
					'In der Beschäftigung mit Kunst bieten sich vielfältige Möglichkeiten der Auseinandersetzung mit sich selbst und tun sich ungeahnte neue Welten auf, die den Blick weiten. In dieser Offenheit für künstlerische Bildung liegt auch eine der Voraussetzungen für individuelle Kreativität und eigenes künstlerisches Schaffen.' +
					'\n' +
					'Das Land Niederösterreich bekennt sich zur zeitgenössischen Kunst in all ihren Facetten und Ausprägungen. Eine Säule dieser Überzeugung bildet die Freiheit der Kunst, die in der österreichischen Verfassung festgeschrieben ist. Eine andere Säule stellt das Verständnis für einen offenen Kunstbegriff dar, der Raum lässt für Neues – das gilt auch und besonders für gesellschaftlich nicht anerkannte Kunstformen. Das Unkonventionelle, Andersartige, Neue wird als positiv und anregend verstanden. Hinhorchen, Annehmen und Möglichmachen sind einem innovativen Klima förderlich, in dem drängende Fragen der Gegenwart kritisch und aus vielfältigen Perspektiven verhandelt werden können. Das schafft das Rüstzeug für die Zukunft einer sich beständig wandelnden Gesellschaft. ' +
					''
			},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Zeitgemäße Kulturpolitik',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Künstler*innen und das Land Niederösterreich sind gleichermaßen ständig mit Herausforderungen konfrontiert, die Offenheit und Flexibilität verlangen. Das haben gerade die Entwicklungen der jüngsten Vergangenheit eindrucksvoll vor Augen geführt. Als vielen Kunst- und Kulturtätigen durch die Corona-Pandemie von heute auf morgen der Boden entzogen war, um ihrem Beruf und ihrer Berufung nachzugehen, wurden neue Betätigungsfelder im digitalen Raum eröffnet.' +
					'\n' +
					'Für eine zeitgemäße Kulturpolitik, die den Herausforderungen der Gegenwart offen begegnet und jene der Zukunft antizipiert, ist es daher notwendig, die vorhandenen Strukturen einer laufenden kritischen Beurteilung zu unterziehen und unzeitgemäße Aspekte zu eliminieren. Nur so kann es gelingen, bestmögliche Ergebnisse zu erzielen – für die Kunst- und Kulturtätigen einerseits sowie für die Kunst- und Kulturrezipienten andererseits.' +
					'',
			},
			{
				InfoboxItemTitle: 'Partizipation als Prinzip',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Das Land Niederösterreich versteht Kunst und Kultur nicht als Einbahnstraße, sondern als partizipativen Prozess, der möglichst alle Bevölkerungsschichten und Altersgruppen einschließt. Entsprechend ist das künstlerische Angebot so zu gestalten, dass es bis hin zu Randgruppen zur Teilhabe motiviert. ' +
					'\n' +
					'Dafür gilt es auch, die jeweils eigenen Spezifika von ländlichem und urbanem Raum zu berücksichtigen: Was die Rahmenbedingungen, die Herausforderungen und die Bedürfnisse betrifft, unterscheiden sich Stadt und Land oft deutlich. Will man die Identität der Menschen stärken, hat dies in einem auf die Unterschiede ausgerichteten Kunst- und Kulturbetrieb Niederschlag zu finden.' +
					'\n' +
					'Das Bestehende regelmäßig zu hinterfragen und zu evaluieren, bedeutet auch, sich mit gesellschaftspolitisch relevanten Fragen auseinanderzusetzen: Nachhaltigkeit, Integration, Diversität, Digitalisierung und Fairness sind Themen, die Menschen heute betreffen und die in Zukunft weiter an Bedeutung gewinnen werden. Eine partizipativ ausgerichtete Kulturpolitik muss sie in Zusammenarbeit mit Kunst- und Kulturtätigen aufgreifen und mit künstlerischen Mitteln und Zugängen bearbeiten.' +
					'',
			},
			{
				InfoboxItemTitle: 'Zeitgenössisches Kunstschaffen fördern',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'In der überarbeiteten Kulturstrategie des Landes Niederösterreich findet ein elementares Anliegen Niederschlag: das zeitgenössische Kunst- und Kulturschaffen in Niederösterreich unter Einbeziehung der oben genannten Aspekte dynamisch zu entwickeln. Die rechtlichen Voraussetzungen dafür sind mit dem Niederösterreichischen Kulturförderungsgesetz von 1996 gegeben, das ein breites Spektrum an materiellen und immateriellen Förderinstrumenten für alle künstlerischen Sparten vorsieht. Um die Neuerungen der vorliegenden überarbeiteten Kulturstrategie ohne Hürden umsetzen zu können, wurden auch die Richtlinien für die Förderung nach dem Niederösterreichischen Kulturförderungsgesetz zeitgemäß adaptiert. ' +
					'\n' +
					'Entwicklung und Förderung bilden zentrale Kriterien der Kulturstrategie des Landes Niederösterreich. Ebenso hoher Stellenwert kommt der Qualität des Kunst- und Kulturschaffens sowie deren nachhaltiger Sicherung und Stärkung zu. Sie sind – auch in Hinblick auf die zweckmäßige Verwendung öffentlicher Finanzmittel – unabdingbar.' +
					'\n' +
					'Mit der Beurteilung der Qualität von Ideen und Projekten tut sich freilich ein sensibles Feld auf, vor allem vor dem Hintergrund der Freiheit der Kunst, zu der sich das Land Niederösterreich uneingeschränkt bekennt. Insbesondere hinsichtlich der Entscheidung über zu vergebende Fördermittel ist eine Qualitätsdiskussion dennoch unumgänglich. Für eine Beurteilung gilt es, Parameter zu erarbeiten. Als solcher kann etwa die Originalität dienen – im Sinne der originären künstlerischen Ideen bzw. der Schaffensaktideen für die Weiterentwicklung von Bestehendem oder die Implementierung von Neuem. Ebenso ist die Strahlkraft abzuschätzen, die von einem Werk oder vom Schaffensakt eines Werkes ausgeht, ob also örtliche, regionale, nationale oder internationale Rezeption zu erwarten ist. Die Reputation, die Rezeption und/oder die Rezension der Urheber*innen in Fachkreisen kann ein weiteres Kriterium darstellen, das in die Bewertung eines Projektes einfließt.' +
					'\n' +
					'Niederösterreich strebt in seiner Kulturpolitik danach, qualitätssteigernde Maßnahmen zu setzen bzw. diese einzufordern und die Professionalisierung im Umgang mit zeitgenössischer Kunst voranzutreiben, vor allem vor dem Hintergrund eines dynamischen Innovationsschubes, in dem unter Nutzung neuer Medien Sparten aufgebrochen, Grenzen verschoben und interdisziplinäre Projekte realisiert werden. ' +
					'',
			},
			{
				InfoboxItemTitle: 'Zeitgenössisches Kunstschaffen präsentieren',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Gerade weil sich Niederösterreich den beschriebenen Herausforderungen beständig stellt, hat sich das Bundesland in den vergangenen Jahren unbestritten zu einem lebendigen Ort für Kunst und Kultur entwickelt. Sein reiches zeitgenössisches Kunst- und Kulturschaffen findet weit über die Landesgrenzen hinaus Anerkennung. ' +
					'\n' +
					'Neues und Spannendes ist in allen künstlerischen Sparten entstanden. Um nicht nur das Schaffen selbst zu fördern, hat das Land Niederösterreich ein weites Netz an Präsentationsmöglichkeiten geschaffen, das auch für die Zukunft tragfähig ist: Damit wird Künstlerpersönlichkeiten – ob aus bildender Kunst, Musik, Theater oder Film – eine Bühne gegeben und zugleich die Teilhabe der Bevölkerung an Kunst und Kultur gestärkt.   ' +
					'\n' +
					'Dem reichen Potenzial bildender Künstler*innen wird durch die Bereitstellung entsprechender Ausstellungsflächen Rechnung getragen. Dabei kommt den etablierten landeseigenen Ausstellungshäusern wie der zuletzt eröffneten Landesgalerie Niederösterreich als neuem Flaggschiff der Kunstmeile Krems ebenso große Bedeutung zu wie einer Vielzahl privater und kommunaler Galerien und Kunsträume, die für eine lebendige Kunstlandschaft sorgen.' +
					'\n' +
					'Mit der Lower Austria Contemporary steht unabhängigen Kunstinstitutionen eine offene Plattform zur Verfügung. Sie dient als starkes Netzwerk für Museen, Galerien und Kunstinitiativen, die sich den vielfältigen Ausdrucksformen zeitgenössischer bildender Kunst widmen. Die Dachmarke soll weiter aufgewertet werden und als Drehscheibe wie Servicestelle für Kunstinstitutionen und Künstler*innen fungieren.' +
					'\n' +
					'Wachsende Bedeutung erlangte in den vergangenen Jahren Kunst im öffentlichen Raum. Früher unter der Bezeichnung „Kunst am Bau“ bekannt, versteht man darunter heute Kunstprojekte, die sich mit spezifischen Orten und deren Kontexten auseinandersetzen und öffentliche Räume ästhetisch wie diskursiv bereichern. Für sein diesbezügliches Engagement, gebündelt in Kunst im öffentlichen Raum Niederösterreich (KOERNOE), wird Niederösterreich international wahrgenommen. Begleitend geben regelmäßig erscheinende Publikationen einen Überblick über die realisierten Projekte, die von autonomen Skulpturen über kommunikative Interventionen, die Gestaltung von Plätzen und Mahnmalen bis zu partizipativen Aktionen mit der Bevölkerung reichen. Beschriftungen und vor Ort zur Verfügung stehende Drucksorten, zunehmend aber auch digitale Tools liefern Informationen und stellen Kontexte her. Immer größere Bedeutung nimmt die Vermittlung ein: Bereits in der Umsetzungsphase werden Projekte in den Gemeinschaften vor Ort kommuniziert und verankert, wird der Austausch zwischen Künstler*innen, Interessierten, Entscheidungsträger*innen und weiteren Expert*innen gefördert, um ein Feld für eine unvoreingenommene Auseinandersetzung zu öffnen und in weiterer Folge national und international ein Netzwerk zu spannen. Wesentlicher Aspekt der Vermittlung ist aber auch die Schaffung eines Bewusstseins für die Fülle und das Potenzial bereits bestehender Projekte in Niederösterreich, für deren Bedeutung im Landschaftsbild und im kulturellen Selbstverständnis des Landes. Schließlich gilt es, Kunst im öffentlichen Raum künftig noch viel stärker im gesellschaftlichen Leben Niederösterreichs zu verankern. ' +
					'\n' +
					'Die Filmförderung des Landes ermöglicht es, Filmschaffende zu unterstützen und Niederösterreich als vielseitige Kulisse für (inter-)nationale Filme zu präsentieren. Mit der Lower Austrian Film Commission (LAFC) wurde zudem eine zentrale Servicestelle für Filmschaffende eingerichtet, die bei der Realisierung von Projekten professionelle Unterstützung bietet. Sie fand in den vergangenen Jahren Erweiterung um Bestrebungen rund um Green Filming, das die Anforderungen eines zeitgemäßen Klimaschutzes erfüllt. Durch ihr EVERGREEN PRISMA, das sich aus einer einzigartigen, digitalen Plattform, einem fundierten Vermittlungsprogramm, Instrumenten für die Praxis und gezielter Netzwerkarbeit zusammensetzt, unterstützt die LAFC die Filmbranche bei der Umstellung auf nachhaltiges Filmschaffen mit einem effektiven Maßnahmenbündel. Mittlerweile international etabliert und ausgezeichnet, bearbeitet die LAFC auf diese Weise bereits wegweisend mehrere Handlungsfelder der neuen Kulturstrategie.' +
					'\n' +
					'Als wichtiger Programmpunkt der sommerlichen Festivallandschaft hat sich zudem der „kinosommer niederösterreich“ etabliert: Über das gesamte Bundesland verteilt wird Kinokultur mit programmatischem Schwerpunkt auf österreichischen und europäischen Film geboten. ' +
					'\n' +
					'Nachhaltig prägen auch die Festivals im Bereich der Darstellenden Kunst und Musik das künstlerische Geschehen im Bundesland. An erster Stelle sei hier Grafenegg genannt, das sich mit seinem hochkarätigen Konzertprogramm in Wolkenturm und Auditorium auch für die Zukunft international fest etabliert hat. In der strategischen Weiterentwicklung der Musiklandschaft knüpft Niederösterreich an die große Vergangenheit berühmter niederösterreichischer Komponist*innen an und schlägt den Bogen zur Gegenwart. Ausgehend von historischen Musikergedenkstätten entwickelte sich unter dem Titel „Serenadenkonzerte“ eine musikalische Reihe, die Musik am Ursprung – von Schuberts Aufenthaltsort in Atzenbrugg bis hin zum Geburtshaus Josef und Michael Haydns in Rohrau – lebendig werden lässt. Letzteres hat sich zum Zentrum des Musikfestivals „Haydnregion Niederösterreich“ entwickelt, in dessen Rahmen unterschiedliche Standorte im Bezirk Bruck an der Leitha ganzjährig bespielt werden. Niederösterreichs Theaterlandschaft prägen das Landestheater Niederösterreich, die Bühne Baden und künftig – nach baulicher Modernisierung und organisatorischer Neuaufstellung – das Stadttheater Wiener Neustadt mit einem aufeinander abgestimmten Ganzjahresprogramm. Der „Theatersommer Niederösterreich“ wiederum bietet niveauvolle Unterhaltung in den Genres Operette, Oper, Musical und Sprechtheater.' +
					'\n' +
					'Die Förderung des künstlerischen Nachwuchses nimmt in der Kulturarbeit besonders hohen Rang ein. Durch Nachwuchsstipendien in den Bereichen Theater und Literatur, Veröffentlichungen im landeseigenen Verlag Literaturedition Niederösterreich oder der Vergabe von Kompositionsaufträgen wird die Entwicklung der Kunstszene von morgen forciert und deren Einstieg in das professionelle Kunstschaffen erleichtert. ' +
					'\n' +
					'',
			},
			{
				InfoboxItemTitle: 'Richtungweisende Weichen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Aufbauend auf dem Erreichten, hat sich das Land Niederösterreich auch für die Zukunft dem Anliegen verschrieben, Kunst und Kultur zu stärken, sichtbarer zu machen und nachhaltig zu sichern. Die Weichen dafür sollen die in dieser Strategie festgehaltenen Leitlinien und neuen Handlungsfelder stellen. Über allem steht als höchstes Prinzip, dass Kunst und Kultur – ungeachtet der umfassenden Maßnahmen zur Stärkung des Umfelds – für sich stehen dürfen. Dass die Wege in die Zukunft vielfältig, verschlungen, nicht immer geradlinig und zuweilen unbequem verlaufen können, ist einkalkuliert: Im Ausprobieren, in der Möglichkeit, mit und durch Kunst (auch in der Wahl der Mittel) Grenzen zu überschreiten, im Aufruf zum Dialog und zum Mut, vertraute Wege zu verlassen, in der Schärfung des Bewusstseins für Offenheit und Toleranz ist die Richtung vorgezeichnet. Sie führt in eine vielfältige und spannende Kunstlandschaft in Niederösterreich.',
			},
		],
	},
	{ id: 10,
		heading: 'GEMEINSAM KUNST UND KULTUR LEBEN',
		subheading: '',
		longdesc: [
			{descHeader: '', descText: '' +
					'Die Strategie für Kunst und Kultur des Landes Niederösterreich setzt den Fokus auf zwei Grundaufgaben: die Pflege des umfassenden kulturellen Erbes und die Förderung des zeitgenössischen künstlerischen Schaffens. Damit werden vitale Wurzeln geschlagen, die für eine Zukunft Niederösterreichs in Vielfalt und Offenheit unumgänglich sind.' +
					'' +
					'In den Bereichen Kunst und Kultur spiegelt sich die dynamische Entwicklung Niederösterreichs auf besondere Weise wider. Seit Erstellung der Strategie für Kunst und Kultur des Landes Niederösterreich 2016 haben sich neue Chancen und Herausforderungen ergeben, die in einer überarbeiteten und ergänzten Kulturstrategie Niederschlag finden. \n' +
					'Gemäß dem Motto „gemeinsam KUNST KULTUR leben“ wurde die Bevölkerung im Vorfeld dazu eingeladen, sich aktiv an deren Weiterentwicklung zu beteiligen. Eine Umfrage auf der Internetseite kultur.noe.at stellte eine zentrale Frage an den Anfang: „Sehen Sie Niederösterreich als Kulturland?“ Überwältigende 96,5 Prozent der Teilnehmer*innen antworteten mit Ja, womit sich auf eindrucksvolle Weise bestätigt, dass das „Land unter der Enns“ als Kulturland wahrgenommen wird.' +
					''
			},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Weichen gestellt',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Das war nicht immer so, was sich zum einen aus historischen Gegebenheiten des 20. Jahrhunderts erklärt: etwa der alliierten Besatzung in der Nachkriegszeit, danach vor allem der undurchlässigen Grenze in Gestalt des „Eisernen Vorhangs“, der das Bundesland jahrzehntelang gegen Norden und Osten abschottete.' +
					'\n' +
					'Zum anderen befand sich Niederösterreich als „Land um Wien“ in keiner einfachen Lage. Das änderte sich ab 1920, als Wien zum eigenständigen Bundesland wurde und Niederösterreich in seiner heutigen Dimension entstand („Trennungsgesetz“ der Bundesländer Wien und Niederösterreich, in Kraft getreten per 1. Jänner 1922). Damit war der Weg frei, um ein eigenes kulturelles Selbstverständnis zu entwickeln. Er erwies sich freilich als steinig und langwierig, sollte doch die durchaus wichtige enge Verflechtung mit Wien und auch mit anderen Bundesländern nicht leichtfertig aufs Spiel gesetzt werden.' +
					'\n' +
					'Die Ernennung St. Pöltens zur Landeshauptstadt von Niederösterreich 1986 markierte einen Meilenstein in der eigenständigen Weiterentwicklung des Landes. Damit ging die Schaffung von neuen Kultureinrichtungen einher – in der neuen Landeshauptstadt, aber auch dezentral und über das Land verteilt. Sie haben als Orte der Aufführung und der Präsentation von Kunst die kulturelle Landschaft seither geprägt. Ihre Bedeutung geht aber heute weit darüber hinaus: Sie bieten vielfältige Möglichkeiten des Lernens, der Bildung und der wissenschaftlichen Auseinandersetzung, laden zur Begegnung, zum Dialog, zur Partizipation ein und ermutigen zu innovativem Handeln und eigenem künstlerischen Schaffen.\n' +
					''
			},
			{
				InfoboxItemTitle: 'Im Kern: zwei Grundaufgaben',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Kunst und Kultur sind zwei Betätigungsfelder, die gleichwertig nebeneinander bestehen und einander auf mannigfaltige Weise ergänzen. Sie bilden einen reichhaltigen Nährboden für die Zukunft des Landes: Kunst und Kultur sind der Humus für Dynamik und Weiterentwicklung in Vielfalt. Ganz wesentlich dafür ist ein interdisziplinärer Diskurs, in dem ein breites Spektrum an Ideen, Meinungen und Konzepten miteinander in Austausch tritt und verhandelt wird. So wird nicht auf einen einzigen Pfad gesetzt, sondern lassen sich verschiedene, vielfältige Wege entwerfen. Diese sind für die Zukunft essenziell: Denn mithilfe von Kunst und Kultur gilt es, noch mehr Menschen, insbesondere Migrant*innen, Minderheiten und marginalisierte Personen am Rande der Gesellschaft, zu erreichen und gemeinsam mit ihnen Entwicklungen voranzutreiben.' +
					''
			},
//			{InfoboxItemTitle: '', InfoboxItemSubtext: '', InfoboxDesc: '',},
		],
	},
	{
		id: 11,
		heading: 'DAS KULTURELLE ERBE PFLEGEN',
		subheading: '',
		longdesc: [
			{
				descHeader: '',
				descText: 'Im Lauf vieler Jahrhunderte wurde in Niederösterreich ein bedeutendes kulturelles Erbe geschaffen, dem seitens der Kulturpolitik des Landes Niederösterreich mit großer Wertschätzung begegnet wird. Im Bewusstsein um seine Verantwortung dafür räumt sie der fachlichen Auseinandersetzung mit den Hinterlassenschaften der Vergangenheit hohen Rang ein: Es gilt, deren Bedeutung zu erkennen, eine Basis für ihre Bewahrung zu schaffen und dabei dem Original – also dem Unikat – mit seiner einzigartigen Aura höchsten Wert beizumessen. ',
			},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Museales Erbe',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Das Land Niederösterreich besitzt mehr als sechs Millionen museale Objekte und damit eine einzigartige Sammlung zur Kunst, Kultur- und Naturgeschichte, die es langfristig zu bewahren gilt. Mit der Einrichtung der Landessammlungen Niederösterreich wurde die Grundlage dafür geschaffen. Um dies für die Zukunft zu gewährleisten, ist es unumgänglich, Wissenschaft und Forschung einzubinden. Die 2014 von den Landessammlungen Niederösterreich erstellte und nun in einer überarbeiteten Fassung vorliegende Sammlungsstrategie des Landes Niederösterreich zielt darauf ab, die musealen Bestände in hoher Qualität zu erhalten, wissenschaftlich zu erschließen und nachhaltig weiterzuentwickeln. Dies geschieht in enger Zusammenarbeit der Landessammlungen Niederösterreich mit universitären Einrichtungen wie etwa dem Zentrum für Museale Sammlungswissenschaften der Universität für Weiterbildung Krems. Neben der Erforschung und Bewahrung ist die Zugänglichmachung der Sammlungsbestände für alle an Kunst und Kultur interessierten Bürger*innen erklärtes Ziel. So steht seit Kurzem ein bedeutender Querschnitt der in zwölf Sammlungsbereiche gegliederten Gebiete Kulturgeschichte, Archäologie, Kunst und Natur auf ' +
					'<a href="https://www.landessammlungen-noe.at" target="yes" rel="noopener noreferrer"><u>www.landessammlungen-noe.at</u></a> ' +
					'digital zur Verfügung. Diese fortlaufend erweiterte Online-Sammlung bietet umfassende Informationen zu ausgewählten Sammlungsbeständen. Vielfältige Einblicke in die gemeinsame Arbeit der Landessammlungen Niederösterreich und des Zentrums für Museale Sammlungswissenschaften gibt der seit 2015 jährlich erscheinende Tätigkeitsbericht.' +
					'\n' +
					'Neben den Aufgaben des Sammelns, Bewahrens und Forschens gilt es, jene der Präsentation sowie der Vermittlung wahrzunehmen und die strukturellen Bedingungen dafür laufend zu optimieren. Um die Kulturschätze des Landes Niederösterreich in Ausstellungen bestmöglich zu zeigen, bedarf es neuer Grundsätze für eine präzise und verbindliche Abstimmung der Landessammlungen Niederösterreich mit den Ausstellungshäusern der NÖKU-Gruppe, vor allem der Kunstmeile Krems mit beispielsweise der Landesgalerie Niederösterreich, der Kunsthalle und dem Karikaturmuseum Krems, dem Museum Gugging als Teil des Art Brut Centers Gugging, dem Museum Niederösterreich mit dem Haus der Geschichte und dem Haus für Natur, dem Archäologischen Park Carnuntum samt Museum Carnuntinum, dem MAMUZ in Asparn/Zaya und Mistelbach sowie dem Weinviertler Museumsdorf Niedersulz, aber auch mit den Niederösterreichischen Landesausstellungen und der Schallaburg.' +
					'\n' +
					'Die Verantwortung für die landeseigenen Bestände soll auf Grundlage der Sammlungsstrategie des Landes Niederösterreich weiterentwickelt werden. Das 2010 am Campus Krems gegründete „Archiv der Zeitgenossen“, das sich künstlerischen Vor- und Nachlässen widmet, bietet die Möglichkeit, sich erfolgreich auch in der Bewahrung zeitgenössischen Kunst- und Kulturschaffens zu positionieren. Die Werke bedeutender Künstlerpersönlichkeiten werden zu Lebzeiten durch das Land Niederösterreich als Vorlass erworben, in Zusammenarbeit mit dem „Archiv der Zeitgenossen“ – einer wissenschaftlichen Einrichtung der Universität für Weiterbildung Krems – erschlossen und der Öffentlichkeit zugänglich gemacht. Das „Archiv der Zeitgenossen“ umfasst Bestände aus den Sparten Literatur, Film, Musik und Architektur.' +
					'',
			},
			{
				InfoboxItemTitle: 'Ein Kaleidoskop vielfältigen kulturellen Erbes',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Alle Bereiche der Kulturförderung in Niederösterreich eint ein Bestreben: Neben dem Augenmerk, das auf Spitzenleistungen liegt, soll der Basis des Kulturgeschehens gleichermaßen gebührende Wertschätzung entgegengebracht werden. Das gilt selbstverständlich auch für das flächendeckende museale Geschehen. Zahllose Museen, Sammlungen, Ausstellungshäuser, Themenwege und Gedenkstätten in Niederösterreich, deren Existenz sich vielfach ehrenamtlichem Engagement verdankt, ergeben ein beeindruckendes Kaleidoskop des vielfältigen kulturellen Erbes. Wenngleich sich das Scheinwerferlicht der öffentlichen Aufmerksamkeit nicht so häufig auf sie richtet, bilden sie zentrale Elemente von Kunst und Kultur in Niederösterreich.' +
					'\n' +
					'Betreut werden sie vom Museumsmanagement NÖ als wichtigster Einrichtung in diesem Kontext. Zur gezielteren Servicierung erfolgte eine Bündelung in die Bereiche Stadt-, Stifts- und Regionalmuseen bzw. private Sammlungen. Künftig will man Qualitätsmaßnahmen verstärken, um Museen zu zertifizieren bzw. für die Verleihung des Museumsgütesiegels zu prädestinieren. Um den Zugang zu den bedeutenden Kulturgütern zu erleichtern, die Niederösterreichs Stadt- und Regionalmuseen beherbergen, hat das Museumsmanagement NÖ in den vergangenen Jahren die digitale Plattform <a href="https://www.noemuseen.at/dipkatalognoemuseen/" target="yes" rel="noopener noreferrer" >DIP.noemuseen</a> entwickelt, die kontinuierlich ausgebaut werden soll. Mehr als 10.200 niederösterreichische Klein- und Flurdenkmäler sind in einer Datenbank erfasst und via Web (' +
					'<a href="https://www.marterl.at" target="yes" rel="noopener noreferrer">www.marterl.at</a>' +
					') oder „MarterlApp“ abrufbar.' +
					'\n' +
					'Künstlerische und kulturelle Hinterlassenschaften gibt es auch in vielen anderen Bereichen. So bilden das NÖ Landesarchiv und die NÖ Landesbibliothek mit dem Volksliedarchiv der Volkskultur Niederösterreich wichtige Wissensspeicher. Deren Weiterentwicklung ist elementar für Niederösterreich, setzt jedoch die Aufstockung bestehender Ressourcen voraus.' +
					'',
			},
			{
				InfoboxItemTitle: 'Bauliches Erbe',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Niederösterreich nennt ein besonders umfassendes gebautes kulturelles Erbe sein Eigen: Mit über 10.600 Denkmälern gemäß Denkmalschutzgesetz weist es den größten Bestand österreichweit auf. Damit ist eine hohe Verantwortung verbunden, die das Land Niederösterreich gemeinsam mit dem Bundesdenkmalamt, der für den Denkmalschutz verantwortlichen Institution, wahrnimmt. Seit Jahrzehnten fördert es kontinuierlich die Sanierung und Restaurierung denkmalgeschützter Objekte im Bundesland und ist Herausgeber zweier Publikationsreihen zum Thema Denkmalpflege.' +
					'\n' +
					'In der öffentlichen Diskussion setzt sich zunehmend eine breitere Sicht auf das gebaute Erbe durch. Der Blick wird nicht mehr nur auf unbestreitbare Zimelien wie Klöster und Stiftsanlagen, Schlösser und Burgen gerichtet. Vielmehr steht nun auch der Erhalt vernakulärer Architektur im Fokus, wie es in Niederösterreich die vor allem das Weinviertel prägenden Kellergassen, Lehmbauten wie der Brandlhof in Radlbrunn, die Gebäude im Museumsdorf Niedersulz oder auch die unzähligen Klein- und Flurdenkmäler landesweit sind.' +
					'\n' +
					'Bewusstseinsbildung soll aber nicht nur für den Erhalt des historischen Bauerbes gefördert werden, sondern auch für eine qualitätsvolle sowie ökologisch und gesellschaftlich nachhaltige Baukultur, die gewachsene Bautraditionen ebenso aufgreift wie zukunftsweisende Ansätze. In diesem Zusammenhang haben auch Fragen der Baukultur im Tourismus – insbesondere in Bezug auf UNESCO-Welterbestätten – oder solche der Raumordnung wie die Reduzierung der Bodenversiegelung, der Zersiedelung abseits von Dorf- und Stadtzentren eine Rolle zu spielen. So sollen vermehrt (Zwischen-)Nutzungskonzepte aus den Bereichen Kunst und Kultur für Leerstände in historischen Ortskernen entwickelt werden.' +
					'\n' +
					'In Fachkreisen wird mit Bezug auf die aktuelle Klimadiskussion auf die besondere Nachhaltigkeit des Denkmalbestandes hingewiesen, der einen Gegenpol zu große Flächen versiegelnden aktuellen Bauvorhaben mit immer kürzerer Bestandsdauer bildet. Verstärktes Augenmerk kommt auch der Umgebung von Einzelobjekten, Ensembles, Gartenanlagen oder Blickachsen zu. Letztere sind vor allem für Kulturlandschaften entscheidend, aber zunehmend bedroht, etwa durch ständig wachsende Gewerbezonen oder immer größer dimensionierte Windkraftanlagen. Die Diskussion um die Bewahrung von Sichtachsen wird besonders vehement im Zusammenhang mit UNESCO-Welterbestätten geführt. Einen zukunftsweisenden Schritt stellt die Einrichtung von Schutzzonen auf Basis örtlicher Bebauungsvorschriften dar. Hier soll ergänzend die Einführung von Schutzzonenkommissionen angedacht werden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Welterbe',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Aktuell kann Niederösterreich auf fünf UNESCO-Welterbestätten verweisen: die Semmeringbahn (seit 1998), die Wachau (seit 2000), das Wildnisgebiet Dürrenstein (seit 2017 Weltnaturerbe im Rahmen der Listung der „Alten Buchenwälder und Buchenurwälder der Karpaten und anderer Regionen Europas“), die Stadt Baden als eines von elf „Great Spas of Europe“ (seit 2021) sowie, ebenfalls im Rahmen einer transnationalen Initiative, der Donaulimes als Grenze des Römischen Reiches (seit 2021). Die außergewöhnliche Bedeutung dieser Stätten für die Weltengemeinschaft fand durch die Aufnahme in die UNESCO-Welterbeliste offizielle Anerkennung. In den kommenden Jahren soll die Aufnahme der Kellergassen in die Tentative List als Vorstufe für eine UNESCO-Nominierung geprüft werden.' +
					'\n' +
					'Neben konsequenter Öffentlichkeitsarbeit für das Verständnis des Wesens des Welterbes und seine regionale wie globale Bedeutung bedarf es für dessen Schutz und sorgsame Weiterentwicklung professioneller Strukturen, die von den betroffenen Gemeinden mit Bund und Land auf Basis gemeinsam erstellter Managementpläne zu erarbeiten und einzurichten sind. Nur so lassen sich allfällige Bedrohungen für das Welterbe antizipieren und im besten Fall auch abwenden – etwa durch Heritage Impact Assessments, die Auswirkungen von Maßnahmen auf das Welterbe einschätzen.' +
					'',
			},
			{
				InfoboxItemTitle: 'Archäologisches Erbe',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Viele Epochen haben archäologische Spuren in Niederösterreich hinterlassen. Carnuntum, einst römische Provinzhauptstadt und Metropole am Donaulimes, ist heute ein zentraler Ort der Präsentation und der Vermittlung von Wissen über die Zeit der Römer. Mithilfe gebauter Rekonstruktionen wird hier ein dreidimensionales Bild städtischer römischer Architektur und antiker Lebenskultur an der Nordgrenze des Römischen Reiches geschaffen. Nach Abschluss der Bauphase in den kommenden Jahren liegt der Fokus fortan auf dem Erhalt der Denkmäler, die einer steten konservatorischen und restauratorischen Betreuung bedürfen, und deren weiterer Erforschung. Zudem soll im Zuge zusätzlicher Befundungen und gezielter Grabungen der Kenntnisstand über die Zeit des Römischen Reiches auf heimischem Boden verdichtet und das neue Wissen öffentlich zugänglich gemacht werden. Die in Carnuntum erworbene herausragende Kompetenz will man auch für andere Stätten des Donaulimes und römische Fundorte nutzen. Geplant ist ein weiterer Ausbau der Aufbereitung und Präsentation der archäologischen Befunde, wie zuletzt in Wallsee oder auch in Carnuntum.' +
					'\n' +
					'In einem Flächenbundesland, wie es Niederösterreich ist, findet sich eine große Zahl archäologischer Fundstellen. Teils treten spektakuläre Spuren unterschiedlichster Zeitepochen zutage, sei es die schon ikonenhafte Venus von Willendorf vor über 100 Jahren, der Zwillingsfund am Wachtberg in Krems oder zuletzt der Schatzfund von Wiener Neustadt, seien es die Funde des jungsteinzeitlichen Massakers von Schletz oder die Kreisgrabenanlagen. Die Ergebnisse der Erforschung archäologischer Fundstellen lassen einen immer detaillierteren und differenzierten Blick auf die Geschichte des Landes zu. In den kommenden Jahren soll besonderes Augenmerk auf der Vernetzung zeitgleicher Fundstellen liegen, um ur- und frühgeschichtliche Siedlungsverbände sowie frühe soziale und politische Einheiten eingehend zu erforschen. Einen Schwerpunkt werden weiterführende Grabungen im Raum Gars am Kamp zur Aufarbeitung der Frühgeschichte und damit der Frühzeit der Babenberger bilden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Immaterielles Kulturerbe',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Einen wesentlichen Anteil am kulturellen Erbe hat das immaterielle Vermächtnis vergangener Zeiten. Auf Grundlage der 2003 verabschiedeten UNESCO-Konvention zur Erhaltung des immateriellen Kulturerbes, die Traditionen, darstellende Künste, gesellschaftliche Rituale und Feste, das Wissen um die Natur oder Handwerkskünste als fünf Bereiche nennt, ließ sich in Niederösterreich immaterielles Kulturerbe identifizieren und durch Aufnahme in das nationale Verzeichnis öffentlich sichtbar machen. Damit ist neben der jeweiligen Trägergemeinschaft auch die öffentliche Hand dazu aufgerufen, das immaterielle Kulturerbe lebendig zu halten.' +
					'\n' +
					'Eine wichtige Rolle bei der Bewahrung des immateriellen Erbes kommt der Tätigkeit der Kultur.Region.Niederösterreich zu, die sich im Sinne der Pflege kultureller Traditionen insbesondere um die Volkskultur, um das Kunst- und Musikschulwesen sowie das Chorwesen verdient macht. Die Pflege des Brauchlebens ist hierbei eine zentrale Aufgabe, die gemeinsam mit vielen ehrenamtlich Tätigen in allen Teilen des Landes getragen wird.' +
					'',
			},
			{
				InfoboxItemTitle: 'Belastetes Erbe',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Geschichtliche Verläufe und Zusammenhänge sind aufzuarbeiten und umfassend darzustellen. Das gilt im besonderen Maße für die Verbrechen während der NS-Zeit. Um Lehren aus der Geschichte ziehen zu können, damit sich Verbrechen wie der Holocaust nicht wiederholen, sollen die ehemalige Synagoge und das Haus der Geschichte Niederösterreich – beide in St. Pölten – zu führenden Lern- und Gedenkorten weiterentwickelt werden. Darüber hinaus wird Initiativen, die sich um die Verwaltung, Pflege und Zugänglichmachung von Orten des NS-Terrors verdient machen, in Zusammenarbeit mit den zuständigen Bundesstellen vermehrt Unterstützung für ihre Arbeit zukommen, wie dies beim Melk Memorial seit einigen Jahren Praxis ist.' +
					'\n' +
					'Seit Jahrzehnten hat zudem Kunst im öffentlichen Raum Niederösterreich in diesem Bereich Kompetenzen erworben, indem sich Kunstschaffende mit den dunklen Seiten der Geschichte auseinandersetzen und sie durch künstlerische Arbeiten im öffentlichen Raum zur Diskussion stellen. Auf Projektebene sollen diese Bemühungen auch künftig Fortsetzung finden.' +
					'\n' +
					'Der historischen Aufarbeitung musealer Sammlungen widmet sich in den Landessammlungen Niederösterreich die Provenienzforschung. Sie untersucht den Erwerb von Sammlungen sowie die Geschichte hinter den einzelnen Objekten bis hin zu möglichen widerrechtlichen Aneignungen. Die Provenienzforschung wird strukturell auf alle Sammlungsbereiche erweitert, um eine durchgehende Nachvollziehbarkeit von Objekt- und Sammlungsgeschichten gewährleisten zu können.' +
					'',
			},
		]
	},
]

