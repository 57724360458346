
import create from 'zustand';

export const useStore = create((set, get) => ({

	isMobile: false,
	setIsMobile: (isMobile) => set((state) => ({isMobile})),
	isLandscape: false,
	setIsLandscape: (isLandscape) => set((state) => ({isLandscape})),
	device: false,
	setDevice: (device) => set((state) => ({device})),
	query: "",
	setQuery: (query) => set((state) => ({query})),
	debugLevel: 0,
	setDebugLevel: (debugLevel) => set((state) => ({debugLevel})),
	focus: -1,
	setFocus: (focus) => set((state) => ({focus})),


	// interaction
	hovered: 99,
	setHovered: (hovered) => set ( state => ({hovered})),
	moreInfo: -1,
	setMoreInfo: (moreInfo) => set((state) => ({moreInfo})),

	// layout
	heightHead: 0,
	setHeightHead: (heightHead) => set((state) => ({heightHead})),
	height1: 0,
	setHeight1: (height1) => set((state) => ({height1})),
	height2: 0,
	setHeight2: (height2) => set((state) => ({height2})),
	height3: 0,
	setHeight3: (height3) => set((state) => ({height3})),


}));
