import React, {useState, useRef, useEffect} from 'react'
import {useSpring, a, config} from '@react-spring/web';
import parse from "html-react-parser";
import DOMPurify from "dompurify";

import {useStore} from "./store";
import {Bullet, IconFetcher, Arrow} from "./icons";
import Longtext from "./longtext";
import {contentMore} from "../assets/content_more";
import logo100 from '../ressources/100_Jahre_NOE.png';
import {useWindowDimensions} from "../utils/hooks";

function More() {

//	console.log("------ More -----");

	const {height, width} = useWindowDimensions();

	const hovered = useStore(state => state.hovered);
	const focus = useStore(state => state.focus);
	const setFocus = useStore(state => state.setFocus);

	var field = contentMore.find((d) => d.id === hovered);
	var longdesc = field ? field.longdesc : [];
	var introdesc = field ? field.introdesc : [];
	var normdesc = field ? field.normdesc : [];
	var infobox = field ? field.infobox : [];

	const refVis = useRef([]);
	const refContainer = useRef();

	const [show, setShow] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const setMoreInfo = useStore(state => state.setMoreInfo);
	const moreInfo = useStore(state => state.moreInfo);
	const [anim, setAnim] = useState(false);

	const [previous, setPrevious] = useState(-1)

	const executeScroll = (d) => {
//		console.log("scrolling", d)
		refVis.current[d].scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	useEffect(()=>{

		if (focus > -1) {
			refContainer.current.focus()
			setFocus(-1)
		}

	}, [focus])

	useEffect(() => {
		if (moreInfo > -1) {
			setPrevious(moreInfo)
			setTimeout(()=>executeScroll(moreInfo), 10)
		}
		if (moreInfo === -1 && previous > -1) {
			setTimeout(()=>executeScroll(previous), 10)
			setTimeout(()=>setPrevious(-1), 2000)
		}

	}, [moreInfo])


	const {o1} = useSpring({
		to: {o1: anim > -1 && refVis.current[anim] ? refVis.current[anim].getBoundingClientRect().height : 0},
//		delay: moreInfo > -1 ? 1700 : 700,
		config: config.default,
		onRest: () => setIsOpen(!isOpen),
	})

	const {o2} = useSpring({
		to: {o2: anim > -1 ? 1 : 0},
		config: config.default,
	})

	return (
		<div>
			{field && hovered <= 16 && hovered >= 12 &&
				<div className="Container">
					<div className='TextContainer'>
						<div ref={e => refContainer.current = e} tabIndex={0} className={'HeadingContainer'}>
							<div className={'headings'}>
								{ hovered === 12 &&
								<div className={'heading1cont'}>
									<div className={'heading1'}>{field.heading}</div>
									<div className={'logo1'}><img src={logo100} width={width/20} alt={"Logo 100 Jahre Niederösterreich"}></img></div>
								</div>
								}
								{ hovered > 12 && <div className={'heading'}>{field.heading}</div>}

								<div className={'subheading'}>{field.subheading}</div>

							</div>
							<div className={'icon1'} style={{width: field.iconwidth + 'em'}}><IconFetcher
								icon={field.icon}/></div>
						</div>

						{introdesc &&
						<div>
							<div className={'introHeader'}>{parse(DOMPurify.sanitize(introdesc.introHeader))}</div>
							<div className={'introSubhead'}>
								<div className={'introArrow'}>
									<Arrow></Arrow>
								</div>
								<div className={'introText'}>{introdesc.introText}</div>
							</div>
						</div>
						}

						{normdesc && normdesc.length > 0 && normdesc.map((desc, i) => {
							return (
								<div tabIndex={desc.normdescDownload ? -1 : 0} className={'descContainer'} key={'normdesc' + i}>
									{desc.normdescHeader &&
										<div className={'normdescHeader'}>
										{parse(DOMPurify.sanitize(desc.normdescHeader))}
										</div>
									}
									<div className={'normdescText'}>
										{desc.normdescText.split('\n').map((line, ii, arr) => {
											let key = 'normpmo' + ii + '_' + i;
											const lineOut = <span className='normdescText' key={i}>{parse(DOMPurify.sanitize(line))}</span>;
											return <div className={'normdescPar'} key={key}>{lineOut}</div>;
										})}
									</div>
									{desc.normdescDownload &&
										<a className="link" href="KulturSTRe_151121_xs.pdf" download target="_blank">
											<div className="downloadLine">
												<span className="downloadIcon">
													<svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 84 81.333">
														<polygon fill="#0071BC" points="76,74.667 8,74.667 8,52.667 15,52.667 15,67.667 69,67.667 69,52.667 76,52.667 "/>
														<g id="downloadIcon"><g><polygon fill="#0071BC" points="73.158,36.492 57.754,36.492 57.754,7.873 26.178,7.873 26.178,36.007 10.777,36.007 41.583,58.893"/></g></g>
													</svg>
												</span>
												<span><b>Download: NÖ Kulturstrategie 2021 (pdf, 1.8 MB)</b></span>
											</div>
										</a>
									}
								</div>
							)
						})
						}

						{longdesc && longdesc.length > 0 && longdesc.map((desc, i) => {
							let key = "hov_" + hovered + "item_" + i;
							return (
								<div className={'descContainer'} key={'longdesc' + i}>
									<div tabIndex={desc.descHeader ? 0 : -1} ref={el => refVis.current[i] = el}
									     className={'descHeader'}>{desc.descHeader ? desc.descHeader : ''}</div>
									<Longtext key={key} val={desc.descText ? desc.descText : ''}
									          new={1} index={i} wasActive={previous === i ? previous : -1}/>
								</div>
							)
						})
						}
						{infobox && infobox.length > 0 && <div className={'Infobox'}>
							{infobox.map((item, i) => {
								let index = i + longdesc.length;
								return (
									<div tabIndex={0} key={'ii' + index} className={'InfoboxItem'}>
										<div className={'InfoboxItemHeader'}>
											<div className={'bullet'}><Bullet/></div>
											<div className={'InfoboxItemTitle'}>
												{item.InfoboxItemTitle}
											</div>
										</div>
										<div className={'InfoboxItemSubtext'}>
											{item.InfoboxItemSubtext}
											{moreInfo !== index &&
												<button className={'link'} onClick={() => {
													refVis.current[index].focus();
													setAnim(index);
													setTimeout(() => {
														setMoreInfo(index);
													}, 10)
												}}
												>
												[mehr]
											</button>
											}
										</div>
										<a.div width='auto' style={{
											overflow: 'hidden',
											opacity: moreInfo === index ? o2 : 0,
											height: o1.to(h => moreInfo === index ? h + 'px' : '0px'),
										}}
										>
											{/*<div style={{overflow: 'hidden', height: moreInfo === index ? o : '0%'}}>*/}
											{/*{moreInfo === index &&*/}
											<div tabIndex={index === anim ? 0 : -1} ref={el => refVis.current[index] = el} className={'InfoboxDesc'}>
												{item.InfoboxDesc.split('\n').map((line, ii, arr) => {
													let key = 'pmo' + ii + '_' + i;
													const lineOut = <span className='descText'
													                      key={i}>{parse(DOMPurify.sanitize(line))}</span>;
													return <div className={'descPar'} key={key}>{lineOut}</div>;
												})}
											</div>
											{/*}*/}
										</a.div>
										<div key={'tw' + index} className={'InfoboxItemSubtext'}>
											{moreInfo === index &&
												<button className={'link'} onClick={() => {
													setAnim(-1);
													// wait a little bit to show div collapsing
													// ToDo: make this dependent in 'isOpen'
													setTimeout(() => {
														setMoreInfo(-1)
													}, 400)
												}}
												>
												{anim > -1 ? '[weniger]' : ''}
											</button>}
										</div>
									</div>
								)
							})}
						</div>
						}
					</div>
				</div>
			}
		</div>
	);
}

export default More;
