import React, {useState, useEffect} from 'react'
import {useStore} from "./store";
import * as d3 from "d3";
import {Lens} from "./lens";
import {a, config, useSpring} from "@react-spring/web";
import {isSafari} from "react-device-detect";

export default function Tree() {

	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);

	const focus = useStore(state => state.focus);
	const setFocus = useStore(state => state.setFocus);

	const SVG = d3.selectAll('#treeSVG');
	const climate = SVG.select("#iconClimate");

	const [init, setInit] = useState(false);

	const [infocus, setInfocus] = useState(false);

	const [loop, setLoop] = useState(false);
	const [cancel, setCancel] = useState(false);

//	const selGreen="red"
	const selGreen="#314e23";
	const selBlack="#000000";

	useEffect(()=>{

		// reset all selections
		SVG.selectAll("path").filter(function() {return d3.select(this).attr("fill") === selGreen}).attr("fill", "#548235")
		SVG.selectAll("path").filter(function() {return d3.select(this).attr("stroke") === selGreen}).attr("stroke", "#548235")

		// set on hover
		SVG.select("#iconFamily").selectAll("path").attr("fill", hovered === 0 ? selGreen : "#548235")
		SVG.select("#iconClimate").selectAll("path").attr("fill", hovered === 2 ? selGreen : "#548235")

		SVG.select("#iconDigit").selectAll("path").filter(function() {return d3.select(this).attr("fill") === "#548235"}).attr("fill", hovered === 1 ? selGreen : "#548235")
		SVG.select("#iconDigit").selectAll("path").filter(function() {return d3.select(this).attr("stroke") === "#548235"}).attr("stroke", hovered === 1 ? selGreen : "#548235")

		SVG.select("#iconTourism").selectAll("path").filter(function() {return d3.select(this).attr("fill") === "#548235"}).attr("fill", hovered === 3 ? selGreen : "#548235")
		SVG.select("#iconTourism").selectAll("path").filter(function() {return d3.select(this).attr("stroke") === "#548235"}).attr("stroke", hovered === 3 ? selGreen : "#548235")

		SVG.select("#iconModel").selectAll("path").filter(function() {return d3.select(this).attr("fill") === "#548235"}).attr("fill", hovered === 4 ? selGreen : "#548235")
		SVG.select("#iconModel").selectAll("path").filter(function() {return d3.select(this).attr("stroke") === "#548235"}).attr("stroke", hovered === 4 ? selGreen : "#548235")

		SVG.select("#iconPart").selectAll("path").attr("stroke", hovered === 5 ? selBlack : "#595959")
		SVG.select("#iconCreate").selectAll("path").attr("stroke", hovered === 6 ? selBlack : "#595959")
		SVG.select("#iconCoop").selectAll("path").attr("stroke", hovered === 7 ? selBlack : "#595959")
		SVG.select("#iconDivers").selectAll("path").attr("stroke", hovered === 8 ? selBlack : "#595959")
	},[hovered])


	function handleclick(e) {
		setInfocus(true) // set focus when tab-key is used for navigation
		e.stopPropagation()
		if (e.code === "Enter" || e.code === "Space") {
			setHovered(parseInt(e.target.id))
			setFocus(parseInt(e.target.id))
		}
	}

	function handleSVGClick(e) {
		setInfocus(false)       // avoid to set focus in SVG when clicked
	}

	useEffect(()=>{
		setInit(true)
	}, [init])

//	const mywob = {
//		mass: 5.5,
//		tension: 300,
//		friction: 42,
//		precision: 0.001,
//		velocity: 0.01
//	}

	const mywob = config.gentle;
//	const mywob = config.wobbly;

	const {o1} = useSpring({
		to: {o1: init ? 1 : 0},
		delay: 100,
		config: {duration: 1200},
	})

	const {o2} = useSpring({
		to: {o2: hovered === 12 ? 2 : 0.1},
		delay: 100,
//		config: {duration: 1200},
	})

	useEffect(()=>{
//			setCancel(true)
//			setTimeout (()=>{setCancel(false); setLoop(true)}, 100)
			setLoop(true)
	}, [hovered])

	const animateIcon0 = useSpring({transformOrigin: `${150}px ${280}px`, transform: hovered === 0 ? "scale(1.1,1.1)" : "scale(1,1)", config: mywob,})
	const animateIcon1 = useSpring({transformOrigin: `${428}px ${150}px`, transform: hovered === 1 ? "scale(1.1,1.1)" : "scale(1,1)", config: mywob,})
	const animateIcon2 = useSpring({transformOrigin: `${410}px ${345}px`, transform: hovered === 2 ? "scale(1.1,1.1)" : "scale(1,1)", config: mywob,})
	const animateIcon3 = useSpring({transformOrigin: `${228}px ${110}px`,  transform: hovered === 3 ? "scale(1.1,1.1)" : "scale(1,1)", config: mywob,})
	const animateIcon4 = useSpring({transformOrigin: `${310}px ${240}px`, transform: hovered === 4 ? "scale(1.1,1.1)" : "scale(1,1)", config: mywob,})

	const animateIcon5 = useSpring({transformOrigin: `${252}px ${515}px`, transform: hovered === 5 ? "scale(1,1)" : "scale(0.92,0.92)", config: mywob,})
	const animateIcon6 = useSpring({transformOrigin: `${282}px ${490}px`, transform: hovered === 6 ? "scale(1.05,1.05)" : "scale(0.94,0.94)", config: mywob,})
	const animateIcon7 = useSpring({transformOrigin: `${318}px ${518}px`, transform: hovered === 7 ? "scale(1,1)" : "scale(0.92,0.92)", config: mywob,})
	const animateIcon8 = useSpring({transformOrigin: `${350}px ${495}px`, transform: hovered === 8 ? "scale(1,1)" : "scale(0.92,0.92)", config: mywob,})

	const animateIcon9 = useSpring({transformOrigin: `${550}px ${715}px`,  transform: hovered === 9 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateIcon10 = useSpring({transformOrigin: `${310}px ${715}px`, transform: hovered === 10 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateIcon11 = useSpring({transformOrigin: `${66}px ${715}px`,  transform: hovered === 11 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})

	const animateText12 = useSpring({transformOrigin: `${610}px ${510}px`,  transform: hovered === 12 ? "scale(1.1,1.1)" : "scale(1,1)", config: mywob,})

	const animateText0 = useSpring({transformOrigin: `${150}px ${320}px`,  transform: hovered === 0 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateText1 = useSpring({transformOrigin: `${428}px ${175}px`,  transform: hovered === 1 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateText2 = useSpring({transformOrigin: `${410}px ${380}px`,  transform: hovered === 2 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateText3 = useSpring({transformOrigin: `${228}px ${160}px`,  transform: hovered === 3 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateText4 = useSpring({transformOrigin: `${310}px ${275}px`,  transform: hovered === 4 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})

	const animateText5 = useSpring({transformOrigin: `${250}px ${480}px`,  transform: hovered === 5 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateText6 = useSpring({transformOrigin: `${282}px ${530}px`,  transform: hovered === 6 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateText7 = useSpring({transformOrigin: `${318}px ${485}px`,  transform: hovered === 7 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})
	const animateText8 = useSpring({transformOrigin: `${350}px ${520}px`,  transform: hovered === 8 ? "scale(1.05,1.05)" : "scale(1,1)", config: mywob,})

	const noanimation = {};


	return (
		<>
			<a.svg opacity={o1} className={'treeSVG'} id="treeSVG"
			    tabIndex={infocus ? 0 : null}
			    role="menu" aria-label="Abschnittsauswahl"
				onClick={handleSVGClick}
			    x="0px" y="0px" width="100%" height="100%"
			    viewBox="0 0 640 740"
				>
				{/*<rect y="0" fill="#FCFCFC" width="1280" height="800"/>*/}

				<path fill="none" stroke="#767171" strokeWidth="0.6667" strokeMiterlimit="8"
				      strokeOpacity="0.7216" d="M39.117,501.5H230.93"
				/>
				<path fill="none" stroke="#767171" strokeWidth="0.6667" strokeMiterlimit="8"
				      strokeOpacity="0.7216" d="M383.617,501.5h210.499"/>

				<path fill="#CAE4AD" d="M459.166,216.685l-10.373,0.668l-2.521,10.692l-12.756,1.604l-8.135,7.89l-7.146,0.668l2.243,8.949
	l-4.626,9.356l6.308,7.617l9.252,1.47l4.345,3.208l6.448-5.346l14.021,4.678l2.103-3.876l6.869-2.673l2.242-2.806l6.448,0.133
	l4.902,6.278h6.448v-6.146l-6.448-4.544l-1.121-6.816l3.363-10.152l-2.383-6.282l-7.289-2.405l2.104-3.609l-6.592-1.197
	l-3.224,3.341l-3.504-3.479L459.166,216.685z M155.063,2l37.085,3.653l5.541,15.854l17.899-12.599l23.021,8.938l28.984,8.128
	l14.49,13.412l32.823,11.785l12.788-7.315l28.56,12.603l-2.132,12.188l30.692,14.631l11.509-6.097l21.74,9.353l31.543,2.438
	l5.542-20.32l26.854-4.063l30.69,9.754l0.853,15.85l17.479,6.502l19.605-6.096l14.07,9.347l11.51,56.085l-29.42,24.791l-3.83,16.261
	l18.33,12.188l11.511,15.854l-0.856,28.854l22.17,10.974l-8.95,23.571l-6.396,17.069l-3.41,0.401v-6.904l-22.16-6.909
	l-34.104,13.813l2.983,10.978l-22.166,25.193l-10.229,2.438L467.088,325.5l-31.543,44.701l-1.274,19.512l12.784,6.096l5.972,24.791
	l-17.479,20.321l5.971,18.69L418.069,484l-22.593-5.283l-5.541-19.914l-27.281,0.406l-0.854-7.727l-27.276-13.411l4.263-10.973
	l-27.281-7.722l-7.247-21.946l-22.592-9.347l-32.822-7.727l-3.837-14.631l-20.89-1.211l-3.41,9.347l-14.063-3.251l-1.279,14.631
	l-15.345,1.224h-26.003l-23.444,11.379l-7.673-7.315h-15.773l-17.477-7.313l-6.82-29.668l15.772-7.727l-10.231-15.037l-14.064,2.439
	l-22.595-12.604l-22.595-22.757l0.854-41.855l9.381-20.729l17.051,2.032l1.705,11.786l21.736,9.348l25.149-5.688l9.804-15.037
	l21.739,2.032l9.378-20.321l-18.755-6.502l9.804-17.069l-22.166-27.636l12.362-12.189l-19.183-12.191l-12.361,6.097l-13.641-11.785
	l2.558-30.479l17.903-14.227l-1.705-14.633l3.836-7.722l16.625,4.471l17.05,3.251l4.263-6.906L142.7,56.468l10.229-17.882
	l-5.114-19.509L155.063,2z"/>
				<path fill="#64B238" d="M324.95,386.031l0.369-33.919l-0.971,2.105l110.602-96.43V224.5c0-1.565-1.269-2.833-2.833-2.833
	s-2.833,1.268-2.833,2.833v31.999l0.971-2.141l-110.589,96.42l-0.382,35.188c-0.017,1.563,1.237,2.847,2.802,2.86
	C323.651,388.85,324.934,387.595,324.95,386.031z M437.784,227.333L432.117,216l-5.667,11.333H437.784z"/>
				<path fill="#3A911F" d="M306.95,380v-26.873l-0.239,1.141l18.239-41.471V299.5c0-1.565-1.269-2.838-2.833-2.838
	s-2.833,1.271-2.833,2.838v12.698l0.239-1.142l-18.239,41.471V380c0,1.563,1.269,2.829,2.833,2.829S306.95,381.565,306.95,380z
	 M327.784,302.329L322.117,291l-5.667,11.329H327.784z"/>

				<path fill="#72573F" stroke="#7F644F" d="M246.117,430v-22c0-1.657,1.343-3,3-3c1.657,0,3,1.343,3,3v22c0,1.648-1.343,3-3,3
	C247.46,433,246.117,431.656,246.117,430z"/>
				<path fill="#AD8053" stroke="#A5876B" d="M285.117,446v-40c0-1.657-1.343-3-3-3c-1.657,0-3,1.343-3,3v40c0,1.648,1.343,3,3,3
	C283.774,449,285.117,447.656,285.117,446z"/>
				<path fill="#A48952" stroke="#A5916D" d="M314.117,440v-34c0-1.657,1.343-3,3-3s3,1.343,3,3v34c0,1.648-1.343,3-3,3
	S314.117,441.656,314.117,440z"/>
				<path fill="#996633" d="M347.117,451v-18c0-1.657,1.343-3,3-3s3,1.343,3,3v18c0,1.648-1.343,3-3,3S347.117,452.656,347.117,451z"/>


				<path fill="#74B230" d="M347.284,420v-11.027l48.44-6.779l-2.44,2.811v-7.5c0-1.565,1.268-2.835,2.833-2.835s2.833,1.27,2.833,2.835
	v9.964l-48.44,6.774l2.44-2.805V420c0,1.563-1.268,2.828-2.833,2.828S347.284,421.565,347.284,420z M390.45,400.328L396.117,389
	l5.667,11.328H390.45z"/>

				<path fill="#7ABC32" d="M274.891,380v-35.218l0.506,1.7L206.982,244.38V183.5c0-1.565,1.133-2.833,2.531-2.833
	s2.532,1.268,2.532,2.833v59.938l-0.506-1.691l68.415,102.104V380c0,1.563-1.133,2.824-2.531,2.824
	C276.024,382.824,274.891,381.565,274.891,380z M204.45,186.333L209.514,175l5.064,11.333H204.45z"/>

				<path fill="#548235" d="M245.675,391v-14.571l2.125,2.805l-99.876-16.242V348.5c0-1.565,1.106-2.842,2.472-2.842
	s2.473,1.271,2.473,2.842v12.023l-2.125-2.805l99.876,16.242V391c0,1.563-1.106,2.824-2.472,2.824
	C246.781,393.824,245.675,392.565,245.675,391z M145.45,351.324L150.396,340l4.946,11.324H145.45z"/>


				<path fill="none" stroke="#996633" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M350.117,553.092v22.609
	L415.801,592l69.947-0.953"/>
				<path fill="none" stroke="#996633" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M415.794,592.192l40,20.75"/>
				<path fill="none" stroke="#996633" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M513.794,589.192"/>
				<path fill="none" stroke="#996633" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M461.007,602"/>
				<path fill="none" stroke="#996633" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M455.794,612.942
	l-7.177,28.404"/>
				<path fill="none" stroke="#A5916D" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M319.117,567v30.354
	l29.133,24.55L396.618,623"/>
				<path fill="none" stroke="#A5916D" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M347.284,623l-12.886,30.191"
				/>
				<path fill="none" stroke="#7C5B3E" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M251.117,561.809v20.819
	L190.418,597l-72.978-6.398"/>
				<path fill="none" stroke="#7C5B3E" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M151.13,616.13l41.654-19.136
	"/>
				<path fill="none" stroke="#7C5B3E" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M160.794,641.346
	l-10.074-25.433"/>
				<path fill="none" stroke="#7C5B3E" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M108.794,622l43.112-6.312"/>
				<path fill="none" stroke="#A5876B" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M285.896,563v38.25
	L260.782,625l-53.665,7"/>
				<path fill="none" stroke="#A5876B" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="8" d="M262.01,626.312
	l25.902,16.792"/>



				<a.g style={animateIcon0}>
				<g id={'iconFamily'} transform="translate(10,0)">
						<path fill="#548235" d="M134.117,262.5c0-2.485,2.015-4.5,4.5-4.5s4.5,2.015,4.5,4.5c0,2.48-2.015,4.5-4.5,4.5
			S134.117,264.985,134.117,262.5z"/>
						<path fill="#548235" d="M158.117,269.5c0-1.938,1.567-3.5,3.5-3.5s3.5,1.563,3.5,3.5c0,1.933-1.567,3.5-3.5,3.5
			S158.117,271.433,158.117,269.5z"/>
						<path fill="#548235"
						      d="M112.117,269c0-1.657,1.343-3,3-3s3,1.343,3,3c0,1.652-1.343,3-3,3S112.117,270.656,112.117,269z"/>
						<path fill="#548235" d="M125.117,273.406c2.266-0.459,3.304-3.216,5.57-3.675l7.552-0.73l9.252,2.848l4.626-1.654l-4.626,4.135
			l-4.815-0.46l-0.563,5.237l4.343,2.205l-1.039,8.636l-2.265-5.788l-5.948-0.825l-2.266,6.063l1.695,5.604l-6.229-5.329l2.643-8.544
			l0.567-7.166c-0.976-0.275-1.668-1.015-2.644-1.286L125.117,273.406z"/>
						<path fill="#548235" d="M153.117,273.237c1.284,0.206,2.829,0.943,4.112,1.149l3.188-0.088h3.566l7.134-2.298
			c-1.325,1.296-1.954,1.354-3.279,2.646l-2.46,2.121l-0.691,4.949l4.348,0.265l1.739,6.805l-4.087-3.005l-5.652,0.619
			c-0.928,2.238-3.246,3.063-4.87,4.6l1.827-5.307l0.869-3.977l-0.782-4.33c-0.659-0.619-1.752-0.796-2.41-1.414L153.117,273.237z"/>
						<path fill="#548235" d="M124.117,273c-1.718,0.021-2.768,0.762-4.486,0.783l-5.25-0.313l-6.205-0.079l-5.059,2.348l4.677,0.203
			l3.341,1.313l-0.568,5.356l-1.622,2.129l-4.396-1.262l5.063,4.494l3.813-2.365l4.773,2.208l-0.764,4.18l3.914-6.546l-4.014-2.759
			l-0.382-5.203c1.021-0.631,2.8-0.631,3.818-1.262L124.117,273z"/>
					</g>
				</a.g>


				<a.g style={animateIcon6}>
				<g id={'iconCreate'} transform={"translate(-12, 2)"}>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M291.991,483.984
		c-6.896-2.003-10.861-9.215-8.858-16.11c2.007-6.895,9.221-10.856,16.11-8.854c6.896,2.003,10.861,9.216,8.858,16.11
		c-0.991,3.407-3.335,6.269-6.486,7.903"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M291.647,484.5l7.97,3.116h-8.566l8.137,3.646
		h-8.57l2.928,1.792l0.217,1.445h2.656v-1.392l1.686-0.405"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M303.498,473h-2.381"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M288.498,473h-2.381"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M295.117,464v2.381"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M290.224,467.727
		l-1.688-1.684"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M300.503,468.502
		l1.688-1.688"/>
				</g>
				</a.g>

				<a.g style={animateIcon3}>
				<g transform="translate(10,0)" id={'iconTourism'}>
					<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M220.03,63.5
		c13.579,0,24.587,10.969,24.587,24.5c0,13.526-11.008,24.5-24.587,24.5c-1.698,0-3.355-0.171-4.956-0.498l-0.448-0.104
		l-0.646-0.202c-4.326-1.838-7.361-6.142-7.361-11.152c0-6.688,5.396-12.109,12.052-12.109l0.226,0.022l2.19-0.218
		c3.212-0.756,6.046-2.771,7.813-5.681c2.353-3.876,2.437-8.727,0.221-12.683c-1.663-2.972-4.425-5.084-7.607-5.952
		c-1.565-0.303-2.024-0.216-3.039-0.324c-0.37,0.002-0.697,0.021-1.006,0.041l-0.361,0.038l0.418-0.053
		C218.342,63.543,219.182,63.5,220.03,63.5z"/>
					<path fill="#548235" stroke="#548235" strokeMiterlimit="8" d="M225.731,74.495l-0.006,0.063
		c-0.237,0.954-0.625,1.928-1.171,2.872c-2.183,3.774-6.031,5.63-8.604,4.146c-2.573-1.486-2.892-5.75-0.712-9.525
		c0.544-0.943,1.194-1.767,1.902-2.449l0.304-0.215l1.045,2.099c0.632,0.826,1.436,1.545,2.395,2.104
		c0.96,0.554,1.984,0.891,3.021,1.021L225.731,74.495z"/>
					<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M219.869,78.635
		c-0.937,0.793-2.34,0.677-3.133-0.26c-0.195-0.231-0.342-0.5-0.43-0.79"/>
					<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M216.938,74.823
		c0.557-0.172,1.16,0.045,1.479,0.527"/>
					<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M220.088,75.718
		c0.562-0.171,1.16,0.044,1.483,0.528"/>
					<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M212.184,79.501
		c-1.063-0.521-2.346-0.117-2.917,0.914"/>
					<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M207.349,77.727
		c0.35-0.466,0.964-0.646,1.51-0.439"/>
					<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M210.424,76.601
		c0.354-0.466,0.969-0.646,1.515-0.445"/>
					<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M212.199,79.52
		c-0.838-0.892-2.042-0.774-2.689,0.254c-0.127,0.203-0.228,0.438-0.295,0.688"/>
					<path fill="none" stroke="#548235" strokeMiterlimit="8" d="M212.737,71.278l0.043,0.048c0.558,0.811,1.031,1.743,1.377,2.776
		c1.385,4.133,0.224,8.248-2.593,9.192c-2.817,0.939-6.229-1.646-7.608-5.774c-0.347-1.033-0.534-2.063-0.576-3.047l0.041-0.371
		l2.27,0.608c1.037,0.073,2.104-0.058,3.159-0.405c1.05-0.352,1.979-0.896,2.764-1.58L212.737,71.278z"/>
					<path fill="#CAE4AD" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M210.617,105.461l6.733-4.961l11.132,0.122
		l7.135,4.878L210.617,105.461z"/>
					<path fill="#CAE4AD" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M221.908,93.493
		c-0.916,0-1.66,0.738-1.66,1.655c0,0.916,0.744,1.66,1.66,1.66c0.917,0,1.66-0.744,1.66-1.66S222.825,93.493,221.908,93.493z
		 M221.908,90.013c1.303,0,2.605,0.497,3.6,1.491c1.989,1.989,1.989,5.212,0,7.2l-3.6,3.601l-3.6-3.6
		c-1.988-1.989-1.988-5.212,0-7.201C219.302,90.51,220.605,90.013,221.908,90.013z"/>
					<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round"
					      strokeMiterlimit="8" d="M219.861,78.649
		c-0.668,1.107-1.914,1.381-2.785,0.612c-0.441-0.39-0.72-0.996-0.769-1.673"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M195.617,88c0-13.531,10.745-24.5,24-24.5
		s24,10.969,24,24.5c0,13.526-10.745,24.5-24,24.5S195.617,101.531,195.617,88z"/>
				</g>
				</a.g>


				<a.g style={animateIcon1}>
				<g transform="translate(5,0)" id={'iconDigit'}>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M404.617,132.5c0-11.046,8.954-20,20-20
		s20,8.954,20,20s-8.954,20-20,20S404.617,143.546,404.617,132.5z"/>
					<path fill="#548235" d="M431.49,114.024l-3.803,1.04l1.385,3.582l-3.911-0.244l-0.326,2.854l-2.77,0.484l0.407,2.523l4.563-0.325
		l3.748,0.896l-0.326,3.338l-4.155-0.163l-2.77-1.547l-4.646,0.647l-2.77,5.861l2.2,3.908l5.704,0.326l0.565,3.175l1.063,1.546
		l0.082,2.85l1.874,1.226l4.97-2.854l0.163-4.478l3.748-4.152l-3.182-1.628l-1.223-3.501l2.771,1.384l1.386,1.389l0.896-2.936
		l-2.036-1.791l3.263-0.729l1.629,4.64l1.386-4.396c-0.876-1.271,1.413-2.358,0.538-3.631c-0.491-1.31-1.999-3.15-3.046-4.377
		c-0.946-1.114-1.817-1.781-2.66-2.427c-0.844-0.64-1.523-1.02-2.285-1.44c-1.229-0.81-1.918-0.862-2.249-1.075"/>
					<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M413.822,116.5l0.795,4.746l-4.594,1.438
		l-0.795,3.395l-1.944,0.424l0.442-2.373l-1.109,0.359c0.061-0.309,0.787-1.453,1.289-2.188s1.034-1.432,1.725-2.196
		c0.938-1.134,2.458-2.276,2.699-2.564"/>
					<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M408.618,143.715
		c-1.165-2.387-2.254-4.793-2.704-7.329c-0.45-2.535-0.338-6.245,0.003-7.886l3.373,1.649l0.296,4.327l3.031,3.271l-1.331,4.405
		l0.887,2.367l-1.347,1.979"/>
					<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M417.617,150.803l3.624-2.303l3.546,0.645
		l3.23,2.487l0.6,0.425c-0.191,0.052-0.551,0.037-1.188,0.181c-0.64,0.146-2.021,0.262-2.991,0.267
		c-0.979,0.005-2.421-0.364-3.412-0.523c-1.771-0.456-2.856-1.09-3.371-1.118"/>
					<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M437.996,139.5l-1.379,2.313v2.688l2-2.5
		C438.594,141.043,438.571,140.086,437.996,139.5z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M402.803,131.5h-16.186"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M384.617,104c0-1.381,1.343-2.5,3-2.5
		s3,1.119,3,2.5s-1.343,2.5-3,2.5S384.617,105.381,384.617,104z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M390.617,103.5h10.686l11.314,10"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M390.617,117c0-1.381,1.343-2.5,3-2.5
		s3,1.119,3,2.5s-1.343,2.5-3,2.5S390.617,118.381,390.617,117z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M404.572,122.677l-8.955-4.177"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M384.617,161.5c0,1.652,1.119,3,2.5,3
		s2.5-1.348,2.5-3c0-1.657-1.119-3-2.5-3S384.617,159.843,384.617,161.5z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M389.617,161.5h10.686l11.314-10"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M390.617,148c0,1.381,1.343,2.5,3,2.5
		s3-1.119,3-2.5s-1.343-2.5-3-2.5S390.617,146.619,390.617,148z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M405.479,142.5l-8.861,4.133"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M380.617,131.5c0-1.657,1.343-3,3-3
		s3,1.343,3,3c0,1.652-1.343,3-3,3S380.617,133.156,380.617,131.5z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M448.617,131.5h16.139"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M470.617,131.5c0-1.657-1.343-3-3-3
		s-3,1.343-3,3c0,1.652,1.343,3,3,3S470.617,133.156,470.617,131.5z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M465.617,103.5c0-1.657-1.119-3-2.5-3
		s-2.5,1.343-2.5,3c0,1.652,1.119,3,2.5,3S465.617,105.156,465.617,103.5z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M460.617,103.5h-10.686l-11.314,11"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M460.617,117c0-1.381-1.119-2.5-2.5-2.5
		s-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5S460.617,118.381,460.617,117z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M446.617,122.713l9.032-4.213"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M464.617,161c0,1.381-1.119,2.5-2.5,2.5
		s-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5S464.617,159.619,464.617,161z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M459.617,161.5h-10.2l-10.8-11"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M460.617,148c0,1.381-1.119,2.5-2.5,2.5
		s-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5S460.617,146.619,460.617,148z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M446.617,142.5l8.925,4.163"/>
				</g>
				</a.g>



				<a.g style={animateIcon7}>
				<g id={'iconCoop'} transform={"translate(-4, 7)"}>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M306.617,532c0-8.563,7.164-15.5,16-15.5
		c8.837,0,16,6.938,16,15.5c0,8.56-7.163,15.5-16,15.5C313.781,547.5,306.617,540.56,306.617,532z"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M322.988,516.5v7.553l-1.579,0.053
		l-1.361-1.259c-0.681-0.157-1.104-0.162-1.852-0.105l-1.089,0.896l-0.438,1.678l-0.055,1.574l0.327,1.206l0.926,0.682l1.361,0.315
		l1.197-0.577l0.546-1.154l1.089-0.053l0.485,0.158v9.747c0.403,0.322,0.775,0.349,1.243,0.341c0.606-0.373,0.771-0.746,1.153-1.119
		c0.525-0.17,1.211,0.021,1.609,0.009l0.729,0.767l0.327,1.742l-0.104,1.269l-1.2,0.777l-1.36-0.131l-0.731-0.795l-0.926-0.036
		l-0.902,0.454v7.017"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M306.617,531.865h5.936
		c0.15,0.069,0.43,0.168,0.48,0.327l-0.008,0.898l-0.477,0.658l-0.319,0.56c-0.048,0.524-0.033,0.959,0.14,1.572
		c0.396,0.342,0.665,0.388,0.998,0.582l1.938,0.03l1.448-0.336l0.604-1.188c0.018-0.271-0.122-0.604-0.104-0.873l-0.706-0.604
		l-0.526-0.437l0.104-0.85l0.859,0.015l11.854-0.063c0.004-0.231,0.011-0.471,0.019-0.702l-0.269-1.104l-0.728-1.013
		c0.056-0.219-0.046-0.464,0.01-0.684c0.041-0.202,0.239-0.381,0.28-0.586c0.231-0.146,0.593-0.396,1.009-0.479
		s0.88-0.128,1.33-0.083c0.451,0.044,1.204,0.006,1.624,0.143c0.421,0.141,1.026,0.287,1.056,0.733
		c0.027,1.754-0.846,1.438-1.133,2.148l-0.054,1.576h1.079h5.558"/>
				</g>
				</a.g>


				<a.g style={animateIcon5}>
				<g id={'iconPart'} transform="rotate(-90, 264, 529) translate(-6,-13)" >
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M256.06,529.882l-1.251,1.354
		c-0.638,0.688-1.703,0.732-2.396,0.104l0,0c-0.687-0.638-0.729-1.703-0.098-2.396l1.25-1.354"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M257.749,533.072l-2.387,2.593
		c-0.633,0.688-1.704,0.732-2.396,0.104l0,0c-0.688-0.633-0.729-1.704-0.099-2.394l2.387-2.595"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M260.641,534.926l-3.266,3.542
		c-0.633,0.687-1.703,0.729-2.391,0.099l0,0c-0.688-0.633-0.732-1.704-0.104-2.391l3.266-3.543"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M263.064,537.196l-2.744,2.979
		c-0.622,0.676-1.675,0.719-2.354,0.097l0,0c-0.677-0.623-0.72-1.676-0.098-2.352l2.425-2.639"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M260.617,541.5v2.89"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M269.644,513.5l-0.026,5.799"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M270.206,518.979
		c3.748,2.805,5.292,7.399,3.915,11.651"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M270.617,539.5v4.449"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M266.687,523.174
		c5.644,1.938,8.396,7.465,6.146,12.34c-0.595,1.291-1.511,2.453-2.682,3.405"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M260.617,513.5v3.021"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M253.746,518.6
		c0.443-0.822,1.469-1.13,2.292-0.688l2.854,1.535c0.818,0.443,1.131,1.469,0.688,2.292l0,0c-0.441,0.822-1.469,1.13-2.292,0.688
		l-2.85-1.535C253.611,520.448,253.303,519.423,253.746,518.6z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M251.971,521.689
		c0.442-0.823,1.473-1.131,2.291-0.688l2.854,1.531c0.823,0.443,1.131,1.474,0.688,2.292l0,0c-0.439,0.823-1.47,1.136-2.291,0.688
		l-2.853-1.535C251.835,523.533,251.527,522.512,251.971,521.689z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M250.979,525.073
		c0.438-0.822,1.469-1.131,2.292-0.688l1.625,0.875c0.818,0.438,1.131,1.47,0.688,2.292l0,0c-0.438,0.823-1.469,1.131-2.292,0.688
		l-1.626-0.875C250.843,526.922,250.535,525.896,250.979,525.073z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M257.01,516.421
		c0.443-0.822,1.474-1.13,2.292-0.688l1.625,0.875c0.823,0.443,1.136,1.469,0.688,2.292l0,0c-0.443,0.823-1.469,1.131-2.292,0.688
		l-1.625-0.876C256.875,518.27,256.567,517.24,257.01,516.421z"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M265.27,528.352
		c-1.303-0.468-1.977-1.901-1.504-3.2c0.424-1.177,1.648-1.854,2.875-1.596"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M263.617,537.5l4-5.221v-3.851l-2.159-0.937"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M270.518,513.791
		c8.396,3.53,12.343,13.209,8.808,21.604c-1.654,3.93-4.765,7.068-8.682,8.755"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M259.835,544.149
		c-8.366-1.813-13.679-10.063-11.867-18.431c1.292-5.959,5.957-10.608,11.925-11.878"/>
				</g>
				</a.g>


				<a.g style={animateIcon2}>
					<g id={'iconClimate'}>
					<path fill="#548235" d="M383.117,337.852l8.827,8.148l4.336-3.749l1.278-0.77l1.626-0.612l13.511-0.42l1.742-0.188l1.433-0.729
		l5.11-2.372l6.079-4.016l2.938-2.449l0.116-0.148l-0.116-0.956l-0.658-0.88l-1.161-0.497l-2.13-0.115l-1.549,0.421l-1.626,0.918
		l-4.528,2.792l-4.183,1.186l-4.336,0.153l-1.936-0.267l-2.018-0.574l5.813-1.377l1.777-0.804l1.237-1.188l0.504-1.339l0.427-1.568
		l-0.151-1.028l-0.58-0.421l-5.11,0.727l-4.336,0.727l-2.941,0.421l-6.467,0.387l-2.71,0.148l-2.013,0.535l-1.665,1.492l-1.742,2.18
		L383.117,337.852z"/>
					<path fill="#548235" d="M400.117,308l0.396,2.689l0.793,3.026l1.585,2.494l1.518,2.062l2.972,2.294l2.444,1.097l1.584,0.335
		l1.255-0.301l-0.528-1.396l-1.021-2.098l-2.014-2.396l-2.542-2.694l3.004,2.128l1.916,2.328l1.287,2.128l0.656,1.164l0.269,0.167
		l0.426-0.559v-2.028l-0.363-1.929l-0.76-1.929l-1.651-2.194l-2.24-2.095l-3.533-1.469l-3.038-0.798L400.117,308z"/>
					<path fill="#548235" d="M430.438,305.232l-0.686,2.244l-1.063,2.478l-1.678,1.927l-1.563,1.563l-2.841,1.576l-2.235,0.623
		l-1.398,0.081l-1.039-0.415l0.631-1.122l1.144-1.654l2.031-1.783l2.521-1.972l-2.848,1.431l-1.938,1.739l-1.375,1.649l-0.718,0.903
		l-0.247,0.107l-0.298-0.536l0.26-1.729l0.558-1.601l0.896-1.547l1.694-1.659l2.192-1.499l3.217-0.796l2.707-0.293L430.438,305.232z
		"/>
					<path fill="#548235" d="M405.988,295.388l-0.188,2.182l0.065,2.521l0.79,2.242l0.816,1.896l1.921,2.348l1.724,1.313l1.188,0.555
		l1.045-0.001l-0.157-1.188l-0.421-1.83l-1.146-2.248l-1.508-2.581l1.976,2.224l1.081,2.178l0.622,1.905l0.312,1.034l0.177,0.179
		l0.442-0.358l0.372-1.593l0.067-1.572l-0.243-1.651l-0.896-2.022l-1.383-2.059l-2.521-1.802l-2.248-1.189L405.988,295.388z"/>
				</g>
				</a.g>



				<a.g style={animateIcon4}>
				<g id={'iconModel'}>
					{/*<path fill="#CAE4AD"*/}
					{/*      d="M273.117,211c0-17.673,14.327-32,32-32s32,14.327,32,32s-14.327,32-32,32S273.117,228.673,273.117,211z"/>*/}
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M327.519,199.219
		c6.363,11.229,2.62,25.608-8.371,32.115c-1.729,1.021-3.577,1.811-5.503,2.345"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M304.229,239.363
		c2.77-2.813,6.219-4.863,10.014-5.962"/>
					<path fill="#548235" d="M322.569,216.146l-0.646,1.424l-0.341,1.438l-0.046,1.342l0.224,1.219l0.489,1.124l0.893,0.859l1.094,0.577
		l0.897-1.136l0.457-1.312l-0.104-1.661c-0.039-0.436-0.096-0.679-0.135-1.109l-1.117-1.646L322.569,216.146z"/>
					<path fill="#548235" d="M314.535,224.359l-1.163,1.043l-0.894,1.181l-0.584,1.208l-0.29,1.203l-0.006,1.228l0.464,1.146
		l0.766,0.974l1.285-0.674l0.947-1.015l0.569-1.562c0.146-0.414,0.188-0.66,0.328-1.074l-0.354-1.952L314.535,224.359z"/>
					<path fill="#548235" d="M319.022,220.588l-0.838,1.314l-0.542,1.374l-0.236,1.321l0.047,1.238l0.327,1.183l0.757,0.979l1,0.726
		l1.054-0.996l0.643-1.228l0.13-1.661c0.021-0.438,0.002-0.688,0.021-1.121l-0.87-1.783L319.022,220.588z"/>
					<path fill="#548235" d="M323.995,210.731l-0.146,1.56l0.142,1.471l0.39,1.284l0.604,1.082l0.828,0.9l1.118,0.526l1.221,0.193
		l0.484-1.367l0.013-1.384l-0.642-1.538c-0.178-0.399-0.311-0.611-0.484-1.015l-1.586-1.188L323.995,210.731z"/>
					<path fill="#548235" d="M323.475,205.152l0.261,1.541l0.517,1.388l0.71,1.139l0.864,0.889l1.034,0.66l1.216,0.218l1.229-0.13
		l0.112-1.442l-0.349-1.344l-1.014-1.315c-0.275-0.341-0.458-0.515-0.733-0.854l-1.842-0.736L323.475,205.152z"/>
					<path fill="#548235" d="M321.607,200.439l0.644,1.313l0.832,1.099l0.939,0.829l1.01,0.563l1.1,0.313l1.146-0.13l1.063-0.445
		l-0.277-1.324l-0.673-1.104l-1.261-0.91c-0.337-0.229-0.546-0.333-0.882-0.563l-1.844-0.175L321.607,200.439z"/>
					<path fill="#548235" d="M319.425,194.136l0.646,1.308l0.832,1.104l0.938,0.828l1.015,0.563l1.1,0.314l1.146-0.129l1.063-0.445
		l-0.282-1.324l-0.669-1.104l-1.26-0.91c-0.337-0.23-0.546-0.334-0.882-0.564l-1.845-0.17L319.425,194.136z"/>
					<path fill="#548235" d="M330.885,194l0.716,1.21l0.407,1.229l0.109,1.163l-0.164,1.067l-0.438,0.997l-0.854,0.783l-1.066,0.55
		l-0.959-0.949l-0.52-1.115l0.027-1.447c0.019-0.38,0.063-0.594,0.082-0.974l1.041-1.471L330.885,194z"/>
					<path fill="#548235" d="M334.991,200.833l0.096,1.454l-0.171,1.364l-0.396,1.188l-0.597,0.993l-0.797,0.818l-1.057,0.465
		l-1.146,0.146l-0.418-1.288l0.027-1.291l0.637-1.417c0.18-0.368,0.31-0.562,0.482-0.93l1.512-1.069L334.991,200.833z"/>
					<path fill="#548235" d="M336.493,208.005l-0.193,1.445l-0.438,1.307l-0.625,1.085l-0.776,0.857l-0.938,0.646l-1.128,0.243
		l-1.15-0.081l-0.154-1.345l0.281-1.265l0.903-1.264c0.245-0.326,0.41-0.491,0.651-0.816l1.693-0.75L336.493,208.005z"/>
					<path fill="#548235" d="M335.519,214.806l-0.462,1.384l-0.68,1.201l-0.813,0.948l-0.925,0.694l-1.052,0.46l-1.148,0.023
		l-1.114-0.297l0.101-1.35l0.518-1.188l1.125-1.07c0.302-0.271,0.495-0.401,0.798-0.679l1.804-0.418L335.519,214.806z"/>
					<path fill="#548235" d="M333.463,221.998l-0.817,1.204l-0.979,0.971l-1.047,0.688l-1.078,0.416l-1.135,0.155l-1.116-0.289
		l-0.99-0.591l0.47-1.271l0.814-0.999l1.375-0.724c0.366-0.181,0.588-0.253,0.953-0.434l1.85,0.092L333.463,221.998z"/>
					<path fill="#548235" d="M330.334,228.934l-1.198,0.831l-1.261,0.557l-1.223,0.271l-1.156,0.003l-1.115-0.261l-0.938-0.669
		l-0.714-0.906l0.89-1.021l1.121-0.641l1.543-0.184c0.406-0.038,0.639-0.026,1.045-0.064l1.694,0.747L330.334,228.934z"/>
					<path fill="#548235" d="M325.117,233.767l-1.21,0.713l-1.234,0.411L321.511,235l-1.064-0.165l-0.996-0.438l-0.783-0.85l-0.55-1.067
		l0.949-0.959l1.115-0.521l1.443,0.023c0.38,0.021,0.595,0.063,0.977,0.082l1.472,1.041L325.117,233.767z"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M291.716,199.219
		c-6.363,11.229-2.62,25.608,8.371,32.114c1.729,1.021,3.577,1.812,5.504,2.346"/>
					<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M315.006,239.363
		c-2.771-2.813-6.22-4.863-10.015-5.962"/>
					<path fill="#548235" d="M295.209,216.147l0.646,1.424l0.342,1.438l0.045,1.341l-0.222,1.219l-0.491,1.125l-0.892,0.859
		l-1.094,0.576l-0.898-1.136l-0.456-1.311l0.104-1.662c0.039-0.435,0.098-0.678,0.135-1.109l1.117-1.645L295.209,216.147z"/>
					<path fill="#548235" d="M303.242,224.359l1.164,1.043l0.894,1.18l0.584,1.208l0.291,1.205l0.01,1.227l-0.469,1.146l-0.767,0.971
		l-1.284-0.674l-0.946-1.013l-0.571-1.563c-0.145-0.414-0.188-0.659-0.327-1.073l0.354-1.952L303.242,224.359z"/>
					<path fill="#548235" d="M298.757,220.588l0.839,1.314l0.541,1.375l0.235,1.32l-0.047,1.238l-0.327,1.183l-0.757,0.979l-1,0.726
		l-1.055-0.996l-0.642-1.228l-0.13-1.66c-0.021-0.438-0.001-0.688-0.021-1.122l0.872-1.783L298.757,220.588z"/>
					<path fill="#548235" d="M293.783,210.732l0.146,1.559l-0.144,1.471l-0.391,1.284l-0.604,1.082l-0.827,0.901l-1.114,0.526
		l-1.225,0.192l-0.484-1.367l-0.013-1.384l0.642-1.538c0.178-0.399,0.31-0.611,0.483-1.015l1.587-1.188L293.783,210.732z"/>
					<path fill="#548235" d="M294.305,205.152l-0.261,1.541l-0.518,1.388l-0.71,1.139l-0.863,0.888l-1.034,0.66l-1.217,0.219
		l-1.229-0.131l-0.116-1.442l0.349-1.343l1.015-1.316c0.275-0.341,0.459-0.514,0.732-0.854l1.842-0.737L294.305,205.152z"/>
					<path fill="#548235" d="M296.171,200.439l-0.644,1.313l-0.832,1.099l-0.939,0.829l-1.009,0.563l-1.101,0.313l-1.146-0.129
		l-1.063-0.445l0.281-1.322l0.67-1.104l1.26-0.91c0.337-0.229,0.546-0.333,0.882-0.563l1.845-0.17L296.171,200.439z"/>
					<path fill="#548235" d="M297.695,193.846l-0.528,1.358l-0.73,1.166l-0.864,0.903l-0.957,0.648l-1.069,0.407l-1.149-0.029
		l-1.102-0.354l0.167-1.346l0.571-1.158l1.177-1.015c0.315-0.259,0.519-0.379,0.83-0.638l1.823-0.328L297.695,193.846z"/>
					<path fill="#548235" d="M287.042,195l-0.535,1.037l-0.308,1.058l-0.082,0.997l0.123,0.915l0.329,0.854l0.637,0.671l0.801,0.469
		l0.725-0.814l0.39-0.956l-0.021-1.24c-0.017-0.321-0.051-0.509-0.063-0.834l-0.781-1.261L287.042,195z"/>
					<path fill="#548235" d="M282.883,201.052l-0.085,1.292l0.152,1.214l0.354,1.054l0.525,0.887l0.707,0.729l0.939,0.409l1.015,0.13
		l0.371-1.144l-0.024-1.146l-0.563-1.258c-0.157-0.327-0.272-0.499-0.432-0.825l-1.344-0.95L282.883,201.052z"/>
					<path fill="#548235" d="M281.285,208.005l0.193,1.445l0.438,1.307l0.625,1.085l0.776,0.857l0.938,0.646l1.128,0.243l1.15-0.082
		l0.155-1.345l-0.282-1.265l-0.903-1.264c-0.24-0.326-0.405-0.49-0.651-0.815l-1.693-0.75L281.285,208.005z"/>
					<path fill="#548235" d="M282.26,214.807l0.461,1.383l0.681,1.201l0.813,0.948l0.928,0.695l1.049,0.46l1.148,0.022l1.114-0.297
		l-0.1-1.35l-0.519-1.188l-1.125-1.068c-0.302-0.273-0.49-0.403-0.798-0.682l-1.804-0.418L282.26,214.807z"/>
					<path fill="#548235" d="M284.315,221.999l0.817,1.204l0.979,0.971l1.046,0.688l1.079,0.416l1.134,0.155l1.116-0.289l0.991-0.59
		l-0.469-1.271l-0.816-0.995l-1.375-0.728c-0.366-0.181-0.587-0.254-0.953-0.434l-1.85,0.091L284.315,221.999z"/>
					<path fill="#548235" d="M287.444,228.934l1.198,0.831l1.261,0.556l1.223,0.271l1.156,0.002l1.115-0.26l0.938-0.669l0.714-0.906
		l-0.89-1.021l-1.121-0.641l-1.543-0.183c-0.406-0.038-0.639-0.027-1.045-0.065l-1.695,0.747L287.444,228.934z"/>
					<path fill="#548235" d="M292.117,233.767l1.383,0.712l1.41,0.411l1.329,0.11l1.22-0.164l1.141-0.439l0.896-0.849l0.624-1.069
		l-1.085-0.958L297.758,231l-1.65,0.022c-0.438,0.021-0.683,0.064-1.111,0.083l-1.682,1.041L292.117,233.767z"/>
					<path fill="#548235" d="M308.964,207.229c-1.415,0-2.563,1.148-2.563,2.563s1.146,2.563,2.563,2.563s2.563-1.146,2.563-2.563
		S310.379,207.229,308.964,207.229z M308.964,201.856c2.017,0,4.024,0.771,5.563,2.307c3.065,3.065,3.065,8.048,0,11.113
		l-5.563,5.563l-5.559-5.563c-3.07-3.065-3.07-8.048,0-11.113C304.94,202.623,306.952,201.856,308.964,201.856z"/>
				</g>
				</a.g>


				<a.g style={animateIcon8}>
				<g id={'iconDivers'} transform={"translate(0, 2)"}>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M337.412,482.334
		c-3.824-6.739-1.46-15.265,5.28-19.032c1.864-1.043,3.946-1.646,6.087-1.766"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M352.133,461.894
		c7.604,1.541,12.547,8.924,11.047,16.492c-0.227,1.135-0.592,2.231-1.091,3.28"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M360.865,483.88
		c-4.709,6.104-13.515,7.223-19.669,2.495c-0.786-0.604-1.507-1.289-2.149-2.049"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M337.471,463.434l4.756,8.211l-9.487-0.008
		L337.471,463.434z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M362.428,464.018l4.734,6.186l-6.144,4.702
		l-4.734-6.186L362.428,464.018z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M347.774,492.906
		c-2.189-1.264-2.946-4.052-1.691-6.227c1.256-2.174,4.048-2.912,6.237-1.648c2.188,1.268,2.946,4.055,1.688,6.229
		C352.755,493.434,349.963,494.172,347.774,492.906z"/>
				</g>
				</a.g>


				<a.g style={animateText0}>
				<g id="0" role="menuitem" tabIndex="0" aria-label="Neue Zugänge für Familien erschließen" onKeyDown={handleclick}>
					<text transform="matrix(1 0 0 1 80.8828 314.0002)" fontFamily="'Abel-Regular'"
					      fontWeight={hovered === 0 && !isSafari ? 'bolder' : 'normal'}
					      fontSize={isSafari ? "14.5" : "15"}>
						Neue Zugänge für Familien
					</text>
					<text transform="matrix(1 0 0 1 118.7061 330.8)" fontFamily="'Abel-Regular'"
					      fontWeight={hovered === 0  && !isSafari ? 'bolder' : 'normal'}
					      fontSize={isSafari ? "14.5" : "15"}>
						erschließen
					</text>
				</g>
				</a.g>

				<a.g style={animateText1}>
				<g id="1" role="menuitem" tabIndex="0" aria-label="Chancen der Digitalisierung nutzen" onKeyDown={handleclick}>
					<text transform="matrix(1 0 0 1 394.4512 184.5002)" fontFamily="'Abel-Regular'"
					      fontWeight={hovered === 1 && !isSafari ? 'bolder' : 'normal'}
					      fontSize={isSafari ? "14.5" : "15"}>
						Chancen der
					</text>
					<text transform="matrix(1 0 0 1 365.6621 201.3)" fontFamily="'Abel-Regular'"
					      fontWeight={hovered === 1 && !isSafari ? 'bolder' : 'normal'}
					      fontSize={isSafari ? "14.5" : "15"}>
						Digitalisierung nutzen
					</text>
				</g>
				</a.g>

				<a.g style={animateText2}>
				<g id="2" role="menuitem" tabIndex="0" aria-label="Nachhaltigkeit und Klimaverantwortung leben" onKeyDown={handleclick}>
					<text transform="matrix(1 0 0 1 371.6406 363.0002)" fontFamily="'Abel-Regular'"
					      fontWeight={hovered === 2 && !isSafari ? 'bolder' : 'normal'}
					      fontSize={isSafari ? "14.5" : "15"}>
						Nachhaltigkeit &amp;</text>
					<text transform="matrix(1 0 0 1 337.3984 379.8)" fontFamily="'Abel-Regular'"
					      fontWeight={hovered === 2 && !isSafari ? 'bolder' : 'normal'}
					      fontSize={isSafari ? "14.5" : "15"}>
						Klimaverantwortung leben
					</text>
				</g>
				</a.g>

				<a.g style={animateText3}>
					<g id="3" role="menuitem" tabIndex="0" aria-label="Kultur und Tourismus zum Gesamterlebnis entwickeln" onKeyDown={handleclick}>
				<text transform="matrix(1 0 0 1 163.6758 138.0002)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 3 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					Kultur &amp; Tourismus zum
				</text>
				<text transform="matrix(1 0 0 1 152.7686 154.8)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 3 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					Gesamterlebnis entwickeln
				</text>
				</g>
				</a.g>

				<a.g style={animateText4}>
				<g id="4" role="menuitem" tabIndex="0" aria-label="Modellregionen für unst &amp; Kultur formen" onKeyDown={handleclick}>
				<text transform="matrix(1 0 0 1 263.5957 261.0002)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 4 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					Modellregionen für
				</text>
				<text transform="matrix(1 0 0 1 253.0996 277.8)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 4 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					Kunst &amp; Kultur formen
				</text>
				</g>
				</a.g>

				<a.g style={animateText5}>
				<g id="5" role="menuitem" tabIndex="0" aria-label="partizipativ" onKeyDown={handleclick}>
				<text transform="matrix(-1.836970e-016 -1 1 -1.836970e-016 253 511)"
				      fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 5 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "15.5" : "16"}>
					partizipativ
				</text>
				</g>
				</a.g>

				<a.g style={animateText6}>
				<g id="6" role="menuitem" tabIndex="0" aria-label="kreativ" onKeyDown={handleclick}>
				<text transform="matrix(-1.836970e-016 -1 1 -1.836970e-016 289 549)"
				      fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 6 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "15.5" : "16"}>
					kreativ
				</text>
				</g>
				</a.g>

				<a.g style={animateText7}>
				<g id="7" role="menuitem" tabIndex="0" aria-label="kooperativ" onKeyDown={handleclick}>
				<text transform="matrix(-1.836970e-016 -1 1 -1.836970e-016 321 515)"
				      fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 7 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "15.5" : "16"}>
					kooperativ
				</text>
				</g>
				</a.g>

				<a.g style={animateText8}>
				<g id="8" role="menuitem" tabIndex="0" aria-label="divers" onKeyDown={handleclick}>
				<text transform="matrix(-1.836970e-016 -1 1 -1.836970e-016 354 539)"
				      fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 8 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "15.5" : "16"}>
					divers
				</text>
				</g>
				</a.g>

				<a.g style={animateIcon10}>
				<g id="10" role="menuitem" tabIndex="0" aria-label="GEMEINSAM KUNST UND KULTUR LEBEN" onKeyDown={handleclick}>
				<text transform="matrix(1 0 0 1 250 697.2737)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 10 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					GEMEINSAM KUNST
				</text>
				<text transform="matrix(1 0 0 1 250 714.0735)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 10 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					UND KULTUR LEBEN
				</text>
				</g>
				</a.g>


				{/*<circle cx={'150px'} cy={'315px'} r='5' fill={'red'}></circle>*/}
				{/*<circle cx={'428px'} cy={'190px'} r='5' fill={'red'}></circle>*/}
				{/*<circle cx={'410px'} cy={'370px'} r='5' fill={'red'}></circle>*/}
				{/*<circle cx={'228px'} cy={'140px'} r='5' fill={'red'}></circle>*/}
				{/*<circle cx={'310px'} cy={'265px'} r='5' fill={'red'}></circle>*/}

				{/*<circle cx={'250px'} cy={'480px'} r='5' fill={'red'}></circle>*/}
				{/*<circle cx={'282px'} cy={'530px'} r='5' fill={'red'}></circle>*/}
				{/*<circle cx={'318px'} cy={'485px'} r='5' fill={'red'}></circle>*/}
				{/*<circle cx={'350px'} cy={'520px'} r='5' fill={'red'}></circle>*/}

				{/*<circle cx={'610px'} cy={'510px'} r='5' fill={'red'}></circle>*/}

				<a.g style={animateIcon9}>
				<g id="9" role="menuitem" tabIndex="0" aria-label="KUNST UND KULTUR FÜR DIE ZUKUNFT SCHAFFEN" onKeyDown={handleclick}>
				<text transform="matrix(1 0 0 1 480 697.4543)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 9 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					KUNST &amp; KULTUR FÜR
				</text>
				<text transform="matrix(1 0 0 1 480 714.2542)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 9 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					DIE ZUKUNFT SCHAFFEN
				</text>
				</g>
				</a.g>


				<a.g style={animateIcon11}>
				<g id="11" role="menuitem" tabIndex="0" aria-label="DAS KULTURELLE ERBE PFLEGEN" onKeyDown={handleclick}>
				<text transform="matrix(1 0 0 1 20 697.2737)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 11 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					DAS KULTURELLE
				</text>
				<text transform="matrix(1 0 0 1 20 714.0735)" fontFamily="'Abel-Regular'"
				      fontWeight={hovered === 11 && !isSafari ? 'bolder' : 'normal'}
				      fontSize={isSafari ? "14.5" : "15"}>
					ERBE PFLEGEN
				</text>
				</g>
				</a.g>

				<path transform="translate(-30,-18)" fill="#7ABC32" d="M430.207,714.045h45.064v1.75h-45.064V714.045z"/>
				<path transform="translate(-26,-18)" fill="#7ABC32" d="M183.88,714.045h45.064v1.75H183.88V714.045z"/>

				<a.g style={animateText12}>
				<g id="12" role="menuitem" tabIndex="0" aria-label="Einleitung" onKeyDown={handleclick}>
					<text transform="matrix(1 0 0 1 600 512.5)" fontFamily="'Abel-Regular'"
					      fontWeight={hovered === 12 && !isSafari ? 'bolder' : 'normal'}
					      fontSize={isSafari ? "15.5" : "16"}>
						info
					</text>
				</g>
				</a.g>

				<Lens />
			</a.svg>

		</>
	)
}
