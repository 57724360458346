import React from "react";

export const contentMore = [
	{ id: 12,
		heading: 'EINLEITUNG',
		subheading: '',
	introdesc:
		{
			introHeader: 'Die&nbsp;neue&nbsp;Strategie für&nbsp;Kunst&nbsp;und&nbsp;Kultur des&nbsp;Landes&nbsp;Niederösterreich',
			introText: '' +
			'Bitte wählen Sie Themen zur Vertiefung im Baum' +
			'',
		},
	longdesc: [
			{descHeader: 'Überblick',
				descText: '' +
					'Schaffen, Entwickeln, Gestalten sind wesentliche Elemente des kreativen Prozesses. Diese Kernaktivitäten sowie alle Tätigkeiten, die damit in Verbindung stehen, darauf aufbauen, sie weiterführen oder auch interpretieren, sind der Boden, auf dem Kunst und Kultur entstehen, Wurzeln schlagen und wachsen. Die Erkenntnis von deren fundamentaler Bedeutung für die Gesellschaft fand in der 2016 von der Niederösterreichischen Landesregierung beschlossenen Strategie für Kunst und Kultur des Landes Niederösterreich Niederschlag.'
			},
			{
				descHeader: 'Die Überarbeitung: ein partizipativer Prozess in Zeiten der Pandemie',
				descText: 'Obwohl die grundsätzlichen Inhalte der Kulturstrategie aus dem Jahr 2016 nach wie vor Gültigkeit haben, ist jedes Strategiepapier laufend einer ' +
					'Prüfung zu unterziehen in Hinblick auf seine Aktualität.' +
					'\n' +
					'Begleitet vom Niederösterreichischen Kultursenat, der sich – wie schon 2016 – insbesondere in Gestalt seiner Vorsitzenden Elisabeth Vavra mit großem Engagement in die Neuformulierung der Strategie eingebracht hat, wurde das Vorhaben in enger Zusammenarbeit und Abstimmung mit den betroffenen Akteurinnen und Akteuren aus Kunst und Kultur umgesetzt.\n' +
					'Um auch in Zeiten der Pandemie, in denen persönlicher Kontakt nicht möglich war und Workshops in traditioneller Form nicht stattfinden konnten, möglichst viele Stakeholder*innen einzubinden, wurde unter Einbeziehung der FH St. Pölten auf digitale Mittel, online Workshops und Podcasts gesetzt. ' +
					'Via kultur.noe.at wurde laufend über den aktuellen Stand der Überarbeitung informiert und über ' +
					'<a href= "mailto:kulturstrategie@noel.gv.at">kulturstrategie@noel.gv.at</a> die Möglichkeit geboten, Vorschläge und Anregungen direkt einzubringen.' +
					''
					
				
			},
			{
				descHeader: 'Die Ziele',
				descText: 'Im Zuge der Überarbeitung der Kulturstrategie wurde auch der Zielfindung breiter Raum gewidmet. Dabei kristallisierte sich immer deutlicher heraus: Je klarer die individuelle Aufgabenstellung, desto konkreter können Ziele benannt werden. Die auf einer Metaebene angesiedelte Strategie erlaubt entsprechende Metaziele, die in Form einer Zielpyramide auf immer eindeutigere Vorgaben herunter zu brechen sind. \n' +
					'So ist die Kulturstrategie auch nur im Verbund mit dem Niederösterreichischen Kulturförderungsgesetz 1996 und den damit erlassenen Richtlinien umfassend zu sehen. Die parallel zur Überarbeitung der Kulturstrategie neu gefassten Richtlinien (verlinken) ermöglichen es, Ziele für einzelne Fachbereiche zu definieren und zur Messung der Zielerreichung mit Indikatoren zu hinterlegen. \n' +
					'Zwei wichtige Anmerkungen dazu an dieser Stelle: Gerade in den Bereichen Kunst und Kultur geht es nicht alleine um quantifizierbare Ziele, um die Beobachtung und Messung von Zahlen. Nur ein Mix aus quantitativen und qualitativen Faktoren kann zur richtigen Einschätzung einer künstlerischen oder kulturellen Aktivität führen. \n' +
					'Für alle Maßnahmen der Kulturarbeit des Landes Niederösterreich sollen folgende strategische Ziele bestimmend sein:' +
					'\n' +
					'1. Sicherung eines für Kulturarbeit notwendigen Rahmens, insbesondere die Gewährleistung der freien künstlerischen Entfaltung. Kunst und Kultur sind nicht nur formell frei und ohne Grenzen, vielmehr müssen sich alle künstlerischen und kulturellen Tätigkeiten ohne jegliche Vorgaben frei entfalten können, ohne dabei einem bestimmten, im Vorhinein ersichtlichen Zweck zu dienen.\n' +
					'2. Beachtung gesellschaftlich wichtiger Themenfelder unter Berücksichtigung der Freiheit und Zweckfreiheit von Kunst und Kultur. Gerade im Bewusstsein möglicher Spannungsfelder ist das Ermöglichen in den Vordergrund zu rücken.\n' +
					'3. Festigung und Ausbau Niederösterreichs als Kunst- und Kulturland, wobei dies nicht vordergründig als Absicherung alles Bestehenden verstanden werden soll, sondern als Auftrag zu einer Weiterentwicklung. Dabei ist auf budgetäre Vorgaben zu achten und eine Partnerschaft aller für Kunst und Kultur eintretenden Institutionen und Personen anzustreben.' +
					'\n' +
					'Diese Entwicklungsziele dienen als Wegweiser für die Kulturarbeit in Niederösterreich. Ob sie künftig stets beachtet werden, können wohl nur die Betroffenen, insbesondere die Kulturtätigen in Niederösterreich, beantworten – und das im Nachhinein. Eine wichtige begleitende Rolle bei der Umsetzung der vorliegenden Strategie soll auch dem NÖ Kultursenat zukommen, wie in Abschnitt "Organisationsstrukturen" der vorliegenden Kulturstrategie näher ausgeführt.' +
					'\n' +
					'Wichtig ist es, mit allen im Kunst- und Kulturbereich Tätigen im Dialog zu bleiben. Denn eine der vielen Auslegungen davon, was Kunst und Kultur ausmacht, ist jene, sie als Form der Kommunikation zu begreifen. Dessen sollte man sich in der Umsetzung der vorliegenden Kulturstrategie immer bewusst sein!' +
					'\n'
			},
			{
				descHeader: 'Struktur und Handlungsfelder',
				descText: '' +
					'' +
					'Die Bedeutung von Kunst und Kultur für die Gesellschaft soll in der hier vorliegenden Kulturstrategie noch klarer zum Ausdruck kommen. Denn der künstlerisch-kreative Prozess – ob in der Vergangenheit, Gegenwart oder Zukunft – bildet den Ursprung einer Vielzahl von Handlungen, die als wichtig und relevant anerkannt werden.\n' +
					'Um das zu visualisieren, wurde für die aktualisierte Strategie die Metapher des Baumes gewählt, dessen starkes und vitales Wurzelwerk Kunst und Kultur sind. Daraus erwächst ein Stamm, gebildet aus den Leitlinien, an denen sich die Weiterentwicklung von Kunst und Kultur in Niederösterreich orientiert. \n' +
					'\n' +
					'Dafür wurden vier Begriffe gefunden: <b>partizipativ -  kreativ -  kooperativ -  divers.</b> \n' +
					'Gänzlich neu ist die Festlegung auf fünf Handlungsfelder: \n' +
					'<ul>' +
					'<li><b>Neue Zugänge für Familien erschließen</b></li>' +
					'<li><b>Kultur und Tourismus zum Gesamterlebnis entwickeln</b></li>' +
					'<li><b>Modellregionen für Kunst und Kultur formen</b></li>' +
					'<li><b>Nachhaltigkeit und Klimaverantwortung leben</b></li>' +
					'<li><b>Chancen der Digitalisierung nutzen</b></li>' +
					'</ul>' +
					'Nach der Metapher des Baumes sind sie das Geäst, das reichlich Blüten und Früchte tragen soll.' +
					'\n' +
					'\n' +
					''
			},
		
		],
		infobox: [
//			{
//				InfoboxItemTitle: '',
//				InfoboxItemSubtext: '',
//				InfoboxDesc: '',
//			},
		],
	},
	{ id: 13,
		heading: 'IMPRESSUM',
		subheading: '',
		normdesc: [
			{
				normdescHeader: '',
				normdescText: '' +
					'<b>Medieninhaber, Herausgeber:</b><br>' +
					'Land Niederösterreich, Amt der NÖ Landesregierung, Abteilung Kunst und Kultur<br>' +
					'Landhausplatz 1, 3109 St. Pölten<br>' +
					'E-Mail: post.k1@noel.gv.at<br>' +
					'Tel: 02742/9005-17010<br>' +
					'Fax: 02742/9005-13029<br>' +
					'UID: ATU 37165802<br>' +
					' \n' +
					'<b>Mediendesign und Programmierung:</b>' +
					'<br>' +
					'mindfactor IT solutions eU, Mag. Michael Smuc' +
					'\n' +
					'<b>Visuelles Konzept & Evaluation Userinterface:</b>' +
					'<br>' +
					'Dr. Florian Windhager und Dr.<sup>in</sup></sup> Eva Mayr' +
					' \n' +
					'<b>Für den Inhalt verantwortlich:</b>' +
					'<br>' +
					'Land Niederösterreich, Amt der NÖ Landesregierung, Abteilung Kunst und Kultur' +
					' \n' +
					'<b>Haftungsausschluss:</b><br>' +
					'Wir prüfen und aktualisieren regelmäßig die Informationen, die sich auf unserer Website befinden. Dennoch können sich Änderungen ergeben, die hier noch nicht eingearbeitet wurden.\n' +
					'Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit unserer Websiteinhalte kann nicht übernommen werden.\n' +
					'<b>Für Inhalte von anderen Websites, die wir verlinken, kann von uns keine Verantwortung übernommen werden.</b>' +
					'\n' +
					'Diese Website wird laufend aktualisiert. Wenn Sie Rückmeldungen für uns haben, freuen wir uns über eine E-Mail an kulturstrategie@noel.gv.at.\n' +
					'<br>'
			},
//			{normdescHeader: '...', normdescText: ''},
		],
		infobox: [
//			{InfoboxItemTitle: '', InfoboxItemSubtext: '', InfoboxDesc: '',},
		],
	},
	{ id: 14,
		heading: 'DIE ORGANISATIONSSTRUKTUREN VON KUNST UND KULTUR IN NIEDERÖSTERREICH',
		subheading: '',
		longdesc: [
			{
				descHeader: 'Akteur*innen',
				descText: 'Das Kulturgeschehen in Niederösterreich wird von einer Vielzahl von Akteur*innen getragen. Die Grundlage für das Kulturland Niederösterreich schaffen unbestreitbar die Künstler*innen mit ihrem Tun, womit alle künstlerisch tätigen Menschen gemeint sind. Dazu kommen neben professionell im Kulturbereich Wirkenden auch ehrenamtlich Tätige – ebenso wie all jene, die Kunst interpretieren und weiterentwickeln. Unverzichtbarer Teil eines dialogorientierten Kulturgeschehens ist die Kunst- und Kulturvermittlung, der hoher Stellenwert zukommt. Schließlich sind die Rezipient*innen unabdingbar für das reich entwickelte Kulturleben in Niederösterreich, wobei dem Publikum nicht mehr nur die Rolle der Konsumierenden zukommt. Vielmehr ist es durch aktive Auseinandersetzung sowie Partizipation an künstlerischen und kulturellen Leistungen zunehmend involviert.'
			},
			{
				descHeader: 'Strukturen',
				descText: 'Zur Sicherung und Weiterentwicklung des Kulturlandes Niederösterreich braucht es tragfähige Strukturen. Über Jahre und Jahrzehnte aufgebaut und laufend evaluiert, bilden sie den Rahmen für künstlerisches und kulturelles Wirken. In der Landesverwaltung werden die in der vorliegenden Kulturstrategie schwerpunktmäßig beschriebenen Agenden durch die Abteilung Kunst und Kultur wahrgenommen.' +
					'\n' +
					'Eine Voraussetzung für ein vitales kulturelles Leben in Niederösterreich bietet zum einen die bestehende kulturelle Infrastruktur, die möglichst effizient und effektiv genutzt werden soll, und zum anderen deren punktueller Ausbau – etwa im Rahmen der Entwicklung des Kunst- und Kulturschwerpunktes St. Pölten 2024 (Arbeitstitel) als Modellregion. Daneben sind es Förderungen seitens des Landes Niederösterreich, die künstlerisches Schaffen und kulturelle Arbeit ermöglichen und gezielt vorantreiben. Die Anerkennung der Leistungen im künstlerischen und kulturellen Bereich, die Wertschätzung der Akteur*innen und Begegnungen auf Augenhöhe sind für die Weiterentwicklung des Kulturlandes Niederösterreich unabdingbar – ebenso wie es die faire Abgeltung von Leistungen ist. Letztlich lassen sich viele dieser Bausteine nur durch materielle Förderungen schaffen und sichern.' +
					''
			},
			{
				descHeader: 'Kooperationen',
				descText: 'Für das Land Niederösterreich sind dabei Partnerschaften und Kooperationen in allen Bereichen wichtig. Viele Projekte wären ohne das Zusammenwirken mit Stellen des Bundes, mit anderen Bundesländern und Regionen sowie den Gemeinden nicht finanzierbar. Auch die Einbindung von Mitteln der Europäischen Union wird nach Möglichkeit angestrebt. Einen bedeutenden Beitrag zur materiellen Unterstützung von Kunst- und Kulturprojekten leistet die Zivilgesellschaft, sei es durch Sponsoring-Aktivitäten von Wirtschaftsbetrieben, sei es durch Spenden von Privatpersonen oder von Fördervereinen.' +
					'\n' +
					'Eine wichtige Rolle kommt auch den Medien und ihrer Berichterstattung zu. Die mediale Auseinandersetzung mit Themen aus den Bereichen Kunst und Kultur als Teil des gesellschaftlichen Lebens ist von hoher Wichtigkeit. Dies gilt ganz besonders für die Reflexion künstlerischer und kultureller Leistungen und deren mitunter kritische Kommentierung.' +
					''
			},
			{
				descHeader: 'Landesnahe Kultureinrichtungen',
				descText: 'Während sich die Abteilung Kunst und Kultur im Amt der Niederösterreichischen Landesregierung primär darum kümmert, die Voraussetzungen für künstlerisches und kulturelles Schaffen zu gewährleisten, dieses in vielen Fällen materiell, aber auch immateriell unterstützt und als Drehscheibe für Öffentlichkeitsarbeit in diesen Bereichen fungiert, erfolgt ein relevanter Teil der operativen Kulturarbeit durch landesnahe Kultureinrichtungen ebenso wie durch eine große Zahl eigenständiger Vereine und Initiativen sowie ehrenamtlich tätige Personen.' +
					'\n' +
					'Die landesnahen Kultureinrichtungen sind zum einen in der NÖ Kulturwirtschaft GesmbH (NÖKU-Gruppe) mit aktuell 14 Tochtergesellschaften und fast 40 künstlerischen bzw. wissenschaftlichen Marken/Institutionen gebündelt. Diese Gesellschaften, die transparent und unter betriebswirtschaftlicher Führung agieren, betreiben gemäß der Vision 2025 der NÖKU-Gruppe Kulturarbeit auf höchstem Niveau und mit exzellenter künstlerischer und wissenschaftlicher Expertise.' +
					'\n' +
					'Zum anderen kann die Kultur.Region.Niederösterreich GmbH (KRN) mit ihren fünf Tochtergesellschaften als ebenbürtiges Pedant zur NÖKU-Gruppe gesehen werden. Sie ist in erster Linie als Serviceeinrichtung für die große Zahl ehrenamtlich im Bereich der Regionalkultur tätiger Gruppen zu verstehen, etwa für Chöre, Museen und Sammlungen, Volkskulturträger, regionale Kulturinitiativen u. v. m. Die Unmittelbarkeit im Kontakt zu den vielen ehrenamtlichen Initiativen ist erklärtes Ziel der Arbeit der KRN.' +
					'\n' +
					'Neben einer effizienten und effektiven Betriebsführung eint die Betriebe der NÖKU-Gruppe und der KRN das Vorgehen auf Basis strategischer Dokumente, zuletzt etwa der „Deklaration zur Bedeutung der Regionalkultur“ (KRN) oder der Vision 2025 (NÖKU-Gruppe), aber auch Leitbilder, Tätigkeitsberichte etc. Diese Dokumente stehen in Übereinstimmung mit grundsätzlichen strategischen Überlegungen der Landesverwaltung, besonders der Abteilung Kunst und Kultur, bzw. stellen deren Weiterentwicklung und Präzisierung dar.' +
					'\n' +
					'Als Spezialfall seien die Musik- und Kunstschulen hervorgehoben. Betreut durch das Musik & Kunst Schulen Management (mkm) im Rahmen der Kultur.Region.Niederösterreich, liefern sie einen fundamentale Beitrag zur kulturellen Bildung vor allem junger Menschen in Niederösterreich.' +
					''
			},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Die Umsetzung der Kulturstrategie',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Verwirklichung der Ziele der Kulturstrategie ist nur im Zusammenspiel aller Akteur*innen über einen mehrjährigen Zeitraum möglich. Die Steuerung und Unterstützung des Prozesses, besonders aber die Sichtbarmachung der Fortschritte auf dem Weg zur Umsetzung liegen im Aufgabenbereich der Abteilung Kunst und Kultur. Zusätzlich sollen besonders gelungene Projekte, die den Überlegungen der Kulturstrategie folgen, durch gezielte Präsentation sichtbar gemacht werden und so Vorbildwirkung auf andere Initiativen und Projektträger*innen entfalten. In erster Linie lässt sich das durch regelmäßige Veröffentlichungen der Best-Practice-Beispiele auf der Website kultur.noe.at bzw. jährliche Fortschrittsberichte erreichen. Letztere sollen in komprimierter Form in den schon bisher jährlich erscheinenden Kulturbericht des Landes Niederösterreich Eingang finden.' +
					'\n' +
					'Eine besondere Rolle bei der Umsetzung der Kulturstrategie kommt dem Niederösterreichischen Kultursenat zu, der bereits in den Prozess von deren Er- und Überarbeitung intensiv involviert war. Er ist als Gremium gemäß Niederösterreichischem Kulturförderungsgesetz 1996 für die Beratung der Kulturpolitik zuständig. Die jährlichen Fortschrittsberichte werden dem Kultursenat künftig zur Debatte übermittelt. Sich daraus ergebende Erkenntnisse werden vom Niederösterreichischen Kultursenat – in gut gelebter Praxis – als Empfehlungen an die Kulturpolitik weitergegeben.' +
					'\n' +
					'\n' +
					'Mit der vorliegenden Strategie für Kunst und Kultur in Niederösterreich sind die Visionen, die strategischen Ziele, die Grundlagen, Leitlinien und Handlungsfelder, vor allem die Herausforderungen und Chancen für Kunst und Kultur in Niederösterreich möglichst klar umrissen. Niemand kann die Zukunft vorhersehen, aber die Strategie für Kunst und Kultur soll bestmögliche Orientierung bieten und Klarheit schaffen, um im Zusammenwirken aller Akteur*innen das Kulturland Niederösterreich gemeinsam weiterzuentwickeln.' +
					'',
			},
		],
	},
	{ id: 15,
		heading: 'DOWNLOADS',
		subheading: '',
		normdesc: [
			{
				normdescHeader: '',
				normdescText: '',
				normdescDownload: true,
			},
//			{normdescHeader: '...', normdescText: ''},
		],
		infobox: [
//			{InfoboxItemTitle: '', InfoboxItemSubtext: '', InfoboxDesc: '',},
		],
	},
	{ id: 16,
		heading: 'DATENSCHUTZERKLÄRUNG',
		subheading: '',
		normdesc: [
			{
				normdescHeader: '',
				normdescText: '' +
					'Personenbezogene Daten werden vom Land Niederösterreich nur nach den Vorgaben der österreichischen und europäischen Datenschutzgrundverordnung (DSGVO) erhoben, verarbeitet und genutzt. Sie als Nutzer stimmen der Datenverarbeitung im Sinne dieser Erklärung zu.\n' +
					'Im Rahmen Ihres Besuchs unserer Website „Landessammlungen Niederösterreich“ erheben wir personenbezogene Daten im technisch notwendigen Umfang (Name der abgerufenen Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL und der anfragende Provider). Diese Daten können von uns nicht bestimmten Personen zugeordnet werden und werden auch nicht mit Daten aus anderen Datenquellen zusammengeführt.\n' +
					'Für detailliertere Informationen zur Datenschutz-Grundverordnung (DSGVO) klicken Sie www.noe.gv.at/datenschutz\n' +
					'\n' +
					'<b>Nutzungsbedingungen, Urheberrechte</b>\n' +
					'Sämtliche Urheber- und sonstigen Schutzrechte an dieser Website und den in ihr enthaltenen Inhalten liegen gemäß den inländischen und EU-rechtlichen Vorschriften beim Land Niederösterreich, Amt der NÖ Landesregierung, Abteilung Kunst und Kultur oder den eigens genannten sonstigen Anbietern.\n' +
					'Jede Nutzung bzw. Weiterverwendung von Inhalten (dies gilt insbesondere für Texte, Bilder, Grafiken) außerhalb der Internetseiten der Landessammlungen Niederösterreich und die Portierung auf andere Medien bedarf ausnahmslos der schriftlichen Genehmigung des Medieninhabers.\n' +
					'Die auf den Internetseiten der zum Land Niederösterreich gehörenden „Landessammlungen Niederösterreich“ wiedergegebenen Inhalte dienen der Information der Landesbürgerinnen und Landesbürger. Für enthaltene Fehler, die trotz eingehender Recherche und Aufarbeitung entstanden sind, kann keine wie immer geartete Haftung übernommen werden.\n' +
					'Weiters erklärt sich das Land Niederösterreich als nicht verantwortlich für den Inhalt von extern liegenden Internetseiten, auf die mit einem Link von den Internetseiten der „Landessammlungen Niederösterreich“ verwiesen wird oder die auf die Internetseiten der „Landessammlungen Niederösterreich“ verweisen..\n' +
					'<b>Hinweis zu Cookies</b>\n' +
					'Diese Website benutzt keine Cookies.' +
					'\n',
			},
//			{normdescHeader: '...', normdescText: ''},
		],
		infobox: [
//			{InfoboxItemTitle: '', InfoboxItemSubtext: '', InfoboxDesc: '',},
		],
	},
]

