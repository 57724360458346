import React from "react";

export const contentFields = [
	{ id: 0,
//		heading: '1 | NEUE ZUGÄNGE FÜR FAMILIEN ERSCHLIESSEN',
		heading: 'NEUE ZUGÄNGE FÜR FAMILIEN ERSCHLIESSEN',
		icon:'FieldFamily',
		iconwidth: '4',
		subheading: 'Daher das Angebot für Familien erheben, bündeln und ausbauen',
		longdesc: [
			{
				descHeader: '',
				descText: 'Niederösterreich hat sich zum Kinder- und Familienkulturland entwickelt. Der Begriff „Familie“ schließt im Sinne eines modernen Verständnisses viele Lebensformen ein. Ein Familienland hat mit familiären Bedingungen von heute – Alleinerzieher*innen, Berufstätigkeit beider Elternteile, Karenzzeit, Nachmittagsbetreuung, Patchwork- und anderen Formen der Familienbildung – sehr sorgsam umzugehen und neue Wege zu beschreiten. Kunst- und Kulturangebote als Freizeitprogramm können potenziell einen wesentlichen Beitrag zur Bewältigung neuer Herausforderungen für Familien leisten – und das nicht nur, weil Kinder und Jugendliche sinnvoll beschäftigt sind. Vielmehr lässt sich in diesem Rahmen altersadäquat auf Bedürfnisse von Kindern und Jugendlichen eingehen und ein spielerischer Zugang zum Kulturleben in all seinen Facetten ermöglichen. Damit wird Kultur zugleich zu einem Faktor, Resilienz auf individueller Ebene zu stärken.'},
			{
				descHeader: 'Schritte und Wege',
				descText: 'Kindern sind Kreativität, Neugier, Bewegungs- und Artikulationsdrang in die Wiege gelegt, und diese Motoren kindlicher Entwicklung sollen von Anbeginn gefördert werden. So kann das Land Niederösterreich etwa mit Kunst- und Kulturangeboten, die auf Drei- bis Zwölfjährige zugeschnitten sind, einen wichtigen gesellschaftspolitischen Beitrag leisten.' +
					'\n' +
					'Mit der Errichtung des „KinderKunstLabors“ (Arbeitstitel) in der Landeshauptstadt soll ein Zentrum entstehen, das weit über Niederösterreich hinaus Wirkung zeigt. Es wird neue Wege für partizipative Prozesse im Kunstfeld erforschen, die auch jüngere Altersgruppen einschließen. Auch die jüngst umgesetzte Initiative „Kultur4kids“ zielt darauf ab, an Kunst und Kultur heranzuführen: Sie bündelt das Kunst- und Kulturprogramm des Landes Niederösterreich für Kinder und Familien und animiert zum Mitmachen.' +
					'\n' +
					'Gemeinsam mit den Kulturanbietern gilt es, künftig weitere neue Angebotsformate und Vermittlungsprogramme zu entwickeln. Dazu gehören z. B. Hands-on-Stationen, die die Eigeninitiative stärken sollen. ' +
					''},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Enge Kooperation mit Institutionen anstreben, deren Kompetenz im Bereich der Familienarbeit liegt',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Das Kunst- und Kulturschaffen in Niederösterreich ist bunt, regional und inhaltlich sehr breit aufgestellt. Die Kompetenzfelder werden von vielen Institutionen aus verschiedenen Bereichen bearbeitet. Diese gilt es zusammenzuführen. Durch eine Intensivierung der Zusammenarbeit mit den für Familienangelegenheiten zuständigen Institutionen sollen bestehende Erfolgsmodelle der Kulturarbeit weiterentwickelt und neue Initiativen realisiert werden.' +
					'\n' +
					'Im Rahmen des „NÖ Familienpasses“ ist beispielsweise ein Kulturpaket für Familien geplant. Denn Teilhabe am Kulturleben darf kein Luxus, sondern muss für alle leistbar sein.' +
					'\n' +
					'Unter dem Motto „Kultur kommt zu den Kindern“ sollen Kunst und Kultur auch mobiler werden. Dafür gilt es, außerschulische Programme, Lernangebote für die Freizeit und die Unterstützung für Anbieter*innen zu erweitern sowie kostengünstige Transportmöglichkeiten und finanzierbare oder gar freie Eintritte zu schaffen.' +
					'',},
			{
				InfoboxItemTitle: 'Kreativität schon ab dem frühen Kindesalter fördern',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Ob Kunst- und Musikschulen, Blasmusikvereine, Volksmusik und Volkskultur, Jugendkulturinitiativen, Sportvereine, Pfadfinder, Freiwillige Feuerwehr oder kirchliche Institutionen: Schon heute ist die Angebotspalette zur sinnhaften kreativen Freizeitgestaltung für Kinder und Jugendliche in Niederösterreich breit. Auf dieser guten Grundlage heißt es auf- und weiterbauen. ' +
					'\n' +
					'Eine wichtige Zielgruppe bilden Kinder ab dem Alter von drei Jahren, die altersadäquat und spielerisch Zugang zu Kunst und Kultur erhalten sollen. Beispielhaft dafür kann das neu entwickelte Programm „Kunst, Kultur & Du“ stehen, das sich dezidiert an Familien mit Kleinkindern richtet: Es fördert den frühen interdisziplinären Zugang zum kreativen Schaffen.' +
					'\n' +
					'Wesentliche Aufgabe in der Zukunft muss es sein, Kindern bei ihren Entwicklungsschritten eine Stütze zu sein und damit auch die Familien zu entlasten. Dafür soll einerseits eine Bestands- und Bedarfsanalyse durchgeführt und andererseits mit wissenschaftlicher Unterstützung klar abgegrenzt werden, in welchen Phasen des kindlichen Entwicklungsprozesses welche kulturellen Angebote von Bedeutung sein können. Unter anderem wird in diesem Zusammenhang die bereits bewährte Zusammenarbeit mit dem Zentrum für Angewandte Spieleforschung im Department für Kunst- und Kulturwissenschaften an der Universität für Weiterbildung Krems weiter intensiviert.' +
					'\n' +
					'Außerdem sollen „Lesen“ und „Vorlesen“ als elementare Kulturtechniken künftig einen besonderen Stellenwert einnehmen. Nicht zuletzt lässt sich damit die Sprachkompetenz aller Kinder – besonders jener mit Migrationshintergrund – stärken. Bestehende Initiativen wie das „Kinder- und Jugendbuchfestival“ oder die vom Land Niederösterreich gegründete Leseinitiative „Zeit.Punkt.Lesen“ sowie die Angebote der öffentlichen Bibliotheken sollen in die Regionen gelangen, indem sie auf mehrere Standorte in Niederösterreich ausgeweitet werden.' +
					'',},
			{
				InfoboxItemTitle: 'Das „Kinder.Kunst.Labor“ als Zentrum für die Präsentation und Vermittlung von zeitgenössischer Kunst',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Mit einer neuen Kunst- und Kulturinstitution in St. Pölten, die sich in erster Linie an Kinder und Familien, letztlich aber an Kunstinteressierte jeden Alters richtet, setzt das Land Niederösterreich gemeinsam mit der Stadt St. Pölten ab 2024 neue Maßstäbe: Unter dem Arbeitstitel „KinderKunstLabor“ entsteht ein innovativer Ort mit hohem künstlerischen Anspruch, an dem vor allem junge Menschen zeitgenössischer bildender Kunst und Künstler*innen begegnen können und an dem kindliche Kreativität anspruchsvoll und wertungsfrei gefördert wird. Die künstlerischen Entscheidungen im „KinderKunstLabor“ werden konsequent von Kinderbeirät*innen mitgestaltet, wobei größtmögliche Zugänglichkeit und Barrierefreiheit auf allen Ebenen angestrebt wird. Hier werden junge Menschen und ihr Umfeld durch die Beschäftigung mit zeitgenössischer Kunst und Kultur gestärkt; sie erhalten die Möglichkeit, jene sozialen und kulturellen Kompetenzen zu erlangen, die erforderlich sind, um sich mit den relevanten Aufgaben der Gegenwart auseinanderzusetzen und das Rüstzeug für die Zukunft einer sich beständig wandelnden Gesellschaft zu erwerben. Ein künstlerisch anregender, sinnlicher Spielbereich im öffentlichen Raum ergänzt das Angebot und schafft einen Treffpunkt für Kinder und Familien mit Entdeckungsmöglichkeiten auf verschiedensten Ebenen.' +
					'\n' +
					'Weit über einen Ort der Begegnung mit Kunst und Kultur hinausgehend, soll das „KinderKunstLabor“ als Kompetenz- und Forschungszentrum die Qualität von Kultur- und Vermittlungsprogrammen für Kinder und Jugendliche weiterentwickeln. Auf dem Weg zum Ziel – einem interdisziplinären Vermittlungsort mit internationaler Ausrichtung – erfolgen bereits lange vor der Eröffnung Vorarbeiten zur Vernetzung mit regionalen, überregionalen und internationalen Kunst-, Kultur- und Bildungsinstitutionen. Darüber hinaus ist geplant, gemeinsam mit anderen Institutionen ein Netzwerk für qualitative Kinderkunst- und -kulturprogramme in Niederösterreich aufzubauen, das Kindern und deren Familien analog und digital leicht zugänglich sein soll.' +
					'',},
			{
				InfoboxItemTitle: 'Neue Spezialangebote für Kinder und Familien schaffen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Als Kinder- und Familienkulturland kann Niederösterreich auf einem guten Fundament aufbauen. Zunächst gilt es, auf Bestehendes zu setzen, „Highlights“ zu erheben und das Angebot zu bündeln. Zugleich sollen das Kultur- und das Kulturvermittlungsprogramm in klaren Altersabstufungen für Kinder, Jugendliche und Familien qualitätsvoll und qualitätsgesichert ausgebaut werden. Ziel muss es sein, einerseits bestehende Beziehungen zwischen dem Publikum und den Kulturanbieter*innen zu vertiefen und sich andererseits neuen, weniger kulturaffinen Zielgruppen zu öffnen, die bislang nicht oder in zu geringem Ausmaß erreicht wurden.' +
					'\n' +
					'Um der stetig steigenden Nachfrage nach kinder- und familiengerechten Angeboten nachzukommen, werden Veranstaltungsbetriebe und Museen aktiv dazu aufgefordert, Schwerpunkte auf diese Besuchergruppe zu setzen und in ihrer Programmgestaltung entsprechend zu berücksichtigen. Kulturvermittlungsangebote sollen thematisch gebündelt und institutionsübergreifend angeboten werden. ' +
					'\n' +
					'Das vorhandene Angebot soll seitens des Landes Niederösterreich laufend erhoben und aufbereitet werden, um Eltern und pädagogischem Lehrpersonal sowohl in analoger als auch in digitaler Form niederschwellig zur Verfügung zu stehen. ' +
					'\n' +
					'',},
		],
	},
	{ id: 1,
//		heading: '5 | CHANCEN DER DIGITALISIERUNG NUTZEN',
		heading: 'CHANCEN DER DIGITALISIERUNG NUTZEN',
		icon:'FieldDigit',
		iconwidth: '6',
		subheading: 'Daher neue Technologien im Kulturbereich verstärkt einsetzen und Innovationen fördern',
		longdesc: [
			{
				descHeader: '',
				descText: 'Neue elektronische bzw. digitale Kommunikationsformen haben sich längst durchgesetzt. In Zukunft werden Technologien wie Künstliche Intelligenz, Augmented Reality und smarte Assistenten die gesellschaftliche Entwicklung noch stärker beeinflussen und auch vorantreiben. Betrachtet man Digitalisierung auch als kulturhistorischen Transformationsprozess, so wird klar, dass darin Kunst und Kultur eine wichtige Rolle spielen sollten, zumal die neuen Technologien ein innovatives Feld für kreative Projekte bieten. Dafür ist es unabdingbar, dass die Kunstszene aktiv am Digitalisierungsprozess teilnimmt und das Kulturleben veränderte Alltagspraktiken, Teilhabemöglichkeiten und Nutzungsweisen der Gesellschaft berücksichtigt.' +
					'\n' +
					'Digitalisierung kann auch der Sicherung und Nutzung des kulturellen Erbes dienlich sein. Seit mehr als 20 Jahren wird in Niederösterreich das kulturelle Erbe Schritt für Schritt in Datenbanken erfasst und schrittweise auch online zugänglich gemacht. Vorhandene Datenressourcen und Erfahrungen verschiedener Kulturerbe-Einrichtungen Niederösterreichs bieten beste Voraussetzungen dafür, diesen Schatz in vielfacher Weise nutzbar zu machen. Diese neue Wissensquelle kann zum Motor der Weiterentwicklung für Wissenschaft, Kultur, Bildung und Wirtschaft werden.' +
					''},
			{
				descHeader: 'Schritte und Wege',
				descText: 'Für einen flächendeckenden Digitalisierungsschub im Kulturleben Niederösterreichs gilt es die Voraussetzungen zu schaffen. Eine zielgerichtete Umsetzung probater Maßnahmen ist eine Aufgabe der Abteilung Kunst und Kultur. Ein wichtiges Instrument sowohl administrativ als auch inhaltlich stellt dabei ein zeitgemäßes Förderwesen dar, das zum einen die Produktion originärer digitaler Kunst anregt, ermöglicht und stärker fördert. Zum anderen soll der Übertragung analoger Produktionen aus unterschiedlichen Sparten mittels elektronischer Medien stärkeres Augenmerk zukommen. Ein weiterer Schwerpunkt ist auf die digitale Aufbereitung des kulturellen Erbes zu setzen. Denn die Transformation analoger Inhalte ins Digitale ermöglicht einem breitgefächerten Publikum Zugang zum kulturellen Bereich und baut damit Barrieren ab.'},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Zielgruppen umfassend beraten und begleiten',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Zielgruppen von Digitalisierungsmaßnahmen sind Künstler*innen und Veranstalter*innen ebenso wie Museen, Vereine und Bildungseinrichtungen. Ihre Erfordernisse unterscheiden sich freilich beträchtlich, zum einen durch andersgeartete Aufgaben, zum anderen auch strukturell, und das bisweilen selbst innerhalb einer Gruppe. Die Maßnahmen zur Förderung der Digitalisierung bzw. des Einsatzes digitaler Medien und Instrumente in Kunst und Kultur sind daher auf die unterschiedlichen Bedürfnisse der Zielgruppen genau abzustimmen.' +
					'\n' +
					'Da die Digitalisierung im Bereich Kunst und Kultur teilweise noch am Anfang steht, braucht es umfassende Beratung und Information. Interessierte Personen oder Institutionen müssen bei der Erhebung des Bedarfs von Hard- und Software unterstützt werden. Neben der Vermittlung des nötigen Knowhows bedarf es auch gezielter Beratung, was das Ausschöpfen neuer Einnahmemöglichkeiten betrifft – etwa in einer einzurichtenden zentralen Beratungsstelle. Sinnvoll erscheint weiters eine stärkere Vernetzung diverser Dachverbände, um über sie die kleineren Einrichtungen im Bereich Digitalisierung besser unterstützen zu können.' +
					'',
			},
			{
				InfoboxItemTitle: 'Nutzungsrechte abstecken und faire Abgeltung sichern',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Umsetzung der „EU-Richtlinie über das Urheberrecht und die verwandten Schutzrechte im digitalen Binnenmarkt“ von 2019 macht eine Novelle des österreichischen Urheberrechts bzw. des Urhebervertragsrechts und entsprechende Anpassungen notwendig. Insbesondere gilt es, eine faire Abgeltung der Werknutzungsrechte von Kulturtätigen im Rahmen von Online-Plattformen, Streaming-Diensten etc. zu gewährleisten. Dabei ist darauf zu achten, dass Erlöse aus Lizenzierungen geschützter Inhalte nicht ausschließlich den Verwerter*innen, sondern auch den Urheber*innen bzw. reproduzierenden Künstler*innen zugutekommen.  Auf Landesebene sind Gespräche mit dem Bund zu den rechtlichen Rahmenbedingungen für eine Werknutzung und eine faire Abgeltung von Nutzungsrechten im digitalen Raum voranzutreiben. Anzustreben ist ein Regelwerk, bei dem der Fokus auch auf einer fairen Abgeltung der künstlerischen Leistung liegt. Es sollte als Grundlage für die faire Bezahlung von Nutzungsrechten und den Ankauf digitaler Werke dienen.' +
					'',
			},
			{
				InfoboxItemTitle: 'Hard- und Software ausbauen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Cloud Services sorgen dafür, dass gespeicherte Daten nachhaltig und dauerhaft verfügbar sind. Darüber hinaus bieten sie eine ideale Präsentationsplattform für digitale bzw. digital aufbereitete Kunst. In Niederösterreich wurde solch ein „virtuelles Kulturleben“ bereits beispielhaft umgesetzt – und zwar mit der Initiative „Kultur Niederösterreich FREI HAUS“, die mit einem „digitalen Medienhaus“ für Medienkunst, dem Streaming von Lesungen, Musikkonzerten, Theateraufführungen etc. Kultur frei Haus liefert. ' +
					'\n' +
					'Cloud Services gewährleisten die exakte Anpassung an den jeweiligen Bedarf sowie die notwendige IT-Sicherheit. Auf der Agenda für die Zukunft stehen die Schaffung weiterer bzw. der Ausbau bereits bestehender Cloud-Lösungen ebenso wie digitaler Kommunikations-, Kollaborations- und Experimentierräume für Künstler*innen. Sie geben Kunstschaffenden neue Möglichkeiten, miteinander zu kommunizieren, ihre Werke aufzubauen, zu verwalten und gesichert zu archivieren.' +
					'',
			},
			{
				InfoboxItemTitle: 'Originäres digitales Kunst- und Kulturschaffen adäquat fördern',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Kunstschaffende sollen durch ein gezieltes Förderwesen zum Experimentieren mit neuen digitalen Kunstformen ermutigt werden. So könnten entsprechende Calls zu experimentellen Projekten im Bereich bildende Kunst, Theater, Tanz, Musik usw. Künstler*innen dazu motivieren, sich innovative Felder im Kunstbereich zu erschließen – die Möglichkeit des Scheiterns explizit eingeschlossen! Für solche oftmals datenintensiven künstlerischen Projekte könnten etwa wertvolle Rechnerressourcen kostengünstig zur Verfügung gestellt werden.' +
					'\n' +
					'Weiters gilt es, bei Kunst- und Kulturtätigen medienspezifische technische Kenntnisse durch Weiterbildungsmaßnahmen zu stärken. Über Netzwerke (z. B. Kulturvernetzung Niederösterreich) kann Wissenstransfer innerhalb der Kulturszene geschehen. Einen wichtigen Faktor stellt schließlich die Bereitstellung digitaler Infrastruktur in Gestalt einer Präsentationsplattform für Kunst- und Kulturschaffen in Niederösterreich dar – damit sind kulturelle Inhalte für eine breite Öffentlichkeit wahrnehmbar.' +
					'',
			},
			{
				InfoboxItemTitle: ' Das kulturelle Erbe digitalisieren',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'In den vergangenen Jahren wurden in Niederösterreich zahlreiche Projekte zur digitalen Sichtbarmachung der Bestände von musealen Sammlungen und Archiven initiiert. Nachdem sich die technologischen Rahmenbedingungen im letzten Jahrzehnt grundlegend weiterentwickelt haben, lassen sich heute durch Künstliche Intelligenz und neuartige Scansysteme riesige Mengen komplexer historischer Daten zusammenführen und neu verknüpfen. Das ist Ausgangspunkt und Basis für das innovative Projekt „Time Machine Lower Austria“: Dabei geht es darum, Daten aus Niederösterreichs Kulturlandschaft (Archiven, Museen, Bibliotheken etc.) für Interessierte nutzbar zu machen, sie mit Orten zu verknüpfen und diese anhand innovativer Visualisierungstechniken dreidimensional erlebbar zu machen. Fragmentierte Informationsquellen mit einem breiten Spektrum an Inhalten – von mittelalterlichen Manuskripten über musikalische Archivalien (z. B. Volksliedarchiv der Volkskultur Niederösterreich) und historische Objekte bis zu Smartphone- oder Satellitenfotos – werden so zu einem Pool allgemein nutzbaren Wissens. Eine verstärkte Digitalisierungsoffensive, die mittels Calls die Aufnahme bedeutender Sammlungsbestände fördert bzw. bestimmte Objektgruppen sammlungsübergreifend erfasst, aufarbeitet und dokumentiert, erhöht die Wahrnehmung des Werts des kulturellen Erbes, indem sie dieses sichtbar und zugänglich macht. Dabei sollen verstärkt auch andere digitale Daten, besonders GIS-Daten, etwa aus dem „NÖ Atlas“, eingebunden werden. Das könnte den Beginn der Arbeit an einem neuen Kulturkataster markieren.' +
					'\n' +
					'Wenn es um die Erzielung eines wissenschaftlichen und ökonomischen Mehrwerts geht, stellen Gebühren- oder Registrierungspflichten für die Nutzung gemeinfreier Daten ein Hindernis dar und sind daher zu vermeiden. Soweit die angesprochenen Daten nicht von urheber- oder verwertungsrechtlichen Einschränkungen betroffen sind, müssen sie für Interessierte offen und frei verwendbar sein. Dies sollte für alle öffentlichen oder öffentlich mitfinanzierten Einrichtungen bzw. Projekte landesweit angestrebt werden, denn: Nur offene Daten sind wertvolle Daten!' +
					'',
			},
			{
				InfoboxItemTitle: 'Den Einsatz digitaler Medien in der Kulturvermittlung forcieren',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Mobile Endgeräte und Social-Media-Plattformen sind aus dem Alltag nicht mehr wegzudenken. Diesem Umstand gilt es in der Kulturarbeit noch stärker Rechnung zu tragen, um so neue Interessengruppen zu erschließen. So sollen Initiativen, die das kulturelle Erbe mittels Apps, QR-Codes oder Augmented Reality vermitteln, unterstützt und gefördert werden. Kulturvermittlung kann damit auch ortsungebunden stattfinden. Besonders förderungswürdig erscheinen gemeinschaftliche Projekte mehrerer Betreiber*innen, die beispielsweise als Plattformen für regionale Verbünde dienen. Der Einsatz digitaler Medien stellt auch einen Mehrwert für den Kulturtourismus dar. ' +
					'',
			},
			{
				InfoboxItemTitle: 'Schulung, Qualifikations- und Kompetenzaufbau unterstützen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Weiterhin intensive Unterstützung und Förderung müssen der Einführung, der Umsetzung und der Erweiterung des Angebots von (Online-)Fortbildungsveranstaltungen für Service- und Beratungsstellen im Kulturbereich, für Heimat- und Regionalforschung, für kulturelle Vereine, für Künstler*innen und Veranstalter*innen sowie für im Bereich der Kulturgütererfassung Tätige zukommen, wie es bereits beispielhaft durch die Kultur.Region.Niederösterreich geschieht.' +
					'',
			},
			{
				InfoboxItemTitle: 'Mit Digitalen Geisteswissenschaften (Digital Humanities) Jugendlichen neue Schlüsselqualifikationen vermitteln',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Verknüpfung von technischem Denken und kulturellen Inhalten bzw. künstlerischer Gestaltung ist ausdrücklich zu fördern: Sie schafft viel kreatives Potenzial, das es zu generieren gilt. Die technischen Entwicklungen der vergangenen Jahrzehnte haben die Digitalen Geisteswissenschaften als Forschungsparadigma hervorgebracht, das Jugendlichen neue Schlüsselqualifikationen vermittelt und den kreativen Nachwuchs stärkt. Umsetzung finden sie z. B. im Lehrgang „Art & Technology“ der Science Academy des Landes Niederösterreich, die sich an Jugendliche richtet. In Kooperation mit der Fachhochschule St. Pölten und Ars Electronica Linz wird zu vernetztem Denken und zur Anwendung künstlerischer Methoden, neuer Medien und digitaler Werkzeuge angeregt, um künftig zur Lösung gesellschaftlicher Herausforderungen beizutragen. Ziel ist es nicht zuletzt, Talenten Raum zur Entfaltung zu geben und sie durch diese frühe Anbindung im Land zu halten. Ein weiteres Beispiel sind regionale Projekte in den Musik- und Kunstschulen: Dort will man digitale Tools in Zukunft vermehrt für das gemeinsame künstlerische Schaffen von Jugendlichen nutzen. Als Förderung des kreativen Nachwuchses bieten sich Calls ebenso an wie Wettbewerbe, in deren Rahmen herausragende Leistungen auf diesem Gebiet ausgezeichnet werden. ' +
					'',
			},
		],
	},
	{
		id: 2,
//		heading: '4 | NACHHALTIGKEIT & KLIMAVERANTWORTUNG LEBEN',
		heading: 'NACHHALTIGKEIT & KLIMAVERANTWORTUNG LEBEN',
		subheading: 'Daher Bewusstsein schaffen, Angebote machen, Umsetzungen begleiten',
		icon:'FieldClimate',
		iconwidth: '2.8',
		longdesc: [
			{
				descText: 'Umweltschutz, Klimawandel, Nachhaltigkeit und die damit verbundenen Aufgaben sind zentrale gesellschaftliche Themen, die sich auf alle Bereiche unseres Zusammenlebens auswirken. Wir haben uns diesen Herausforderungen zu stellen und Strategien zu entwickeln, die im Sinne eines nachhaltigen, kulturverändernden Prozesses Wege zur besseren Gestaltung der Zukunft aufzeigen. ' +
					'\n' +
					'Mit dem Anfang 2021 beschlossenen NÖ Klima- und Energieprogramm bekennt sich die Landesverwaltung dazu, in ihrem Wirkungsbereich ihrer Vorbildfunktion gerecht und bis zum Jahr 2035 klimaneutral zu werden. Damit muss das Prinzip Nachhaltigkeit auch in allen Prozessen von Kunst und Kultur Berücksichtigung finden, ohne deren Kernkompetenz einzuschränken. Mit der Übernahme von Nachhaltigkeitskriterien in die Grundlagendokumente der Kunst- und Kulturförderung, wie etwa die Richtlinien zum NÖ Kulturförderungsgesetz, wird der normative Grundstein für nachhaltige Kunst- und Kulturveranstaltungen in Niederösterreich gelegt. ' +
					'\n' +
					'Niederösterreich zählt jährlich 2,8 Millionen Menschen bei Kulturveranstaltungen. Aufgrund seiner Breitenwirkung hat der Bereich Kunst und Kultur also großes Potenzial, wenn es darum geht, Bewusstsein für Umweltschutz, Klimawandel und Nachhaltigkeit zu schaffen, die damit in Verbindung stehenden Herausforderungen im öffentlichen Denken zu verankern und das aktive Handeln der Bewohner*innen und Gäste Niederösterreichs anzuregen. Denn diesen Herausforderungen unserer Zeit lässt sich nur gemeinsam begegnen!' +
					'\n' +
					'Nachhaltiges Handeln, respektvolles Zusammenleben und kulturelle Entfaltung sind wechselseitig voneinander abhängig. Durch gezielte Angebote und Steuerungsmaßnahmen kann mit den in Niederösterreich ansässigen Ausstellungshäusern und Veranstaltungsinstitutionen, insbesondere den großen Betrieben der NÖ Kulturwirtschaft GesmbH und der Kultur.Region.Niederösterreich, ein wesentlicher Beitrag zu Nachhaltigkeit und Klimaschutz geleistet werden. ',
			},
			{
				descHeader: 'Schritte und Wege',
				descText: 'Niederösterreichs Kunst- und Kulturlandschaft prägen einerseits große, öffentlichkeitswirksame Veranstaltungen, andererseits zeichnet sie sich durch ein starkes Bekenntnis zu Regionalität aus. Von großen Events bis zu solchen im kleinen Rahmen gilt es künftig, Nachhaltigkeit in den Vordergrund zu rücken und Verantwortung für das Handeln zu übernehmen. Erklärtes Ziel ist es, mit bestehenden Ressourcen schonend umzugehen, um im Kulturland Niederösterreich nachhaltige und möglichst klimaneutrale Veranstaltungen zu realisieren. Maßnahmen zur Verringerung des ökologischen Fußabdrucks betreffen Transport, Abfallvermeidung, Gebäudeinfrastruktur sowie Energieversorgung, setzen aber auch in den Bereichen Betriebsführung und Organisation an. Durch Kooperationen, Informationen und konkrete Maßnahmen soll vor allem das Spannungsfeld zwischen dem Anspruch auf Wirtschaftlichkeit und Sparsamkeit von Projekten einerseits und der Notwendigkeit von Nachhaltigkeit andererseits aufgelöst werden.' +
					'\n' +
					'So werden etwa die Kultur.Region.Niederösterreich und ihre Betriebe lokalen Veranstalter*innen und Kunstschaffenden in Zukunft ein umfassendes Informationskonzept zum Thema Nachhaltigkeit anbieten.',
			}
		],
		infobox: [
			{
				InfoboxItemTitle: 'Nachhaltige Kulturveranstaltungen bei Fördernehmer*innen, Veranstalter*innen und Künstler*innen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Verankerung der Ziele des Klima- und Energieprogrammes in den Richtlinien zum NÖ Kulturförderungsgesetz 1996 soll es ermöglichen, gezielt Steuerungsmaßnahmen hinsichtlich Kunst- und Kulturveranstaltungen zu ergreifen, die Bewusstsein für Umwelt, Klimaschutz und Nachhaltigkeit schaffen.' +
					'\n' +
					'So wird künftig bei Kulturveranstaltungen explizit der ökologische Fußabdruck thematisiert, den diese hinterlassen. Ziel ist es, die Veranstalter*innen selbst, aber auch die Besucher*innen auf ihre Verantwortung hinzuweisen und sie zu klimafreundlichen Alternativen zu motivieren. Zusätzliche finanzielle Anreize durch neue Calls sollen das Thema nachhaltige Veranstaltungen forcieren.' +
					'\n' +
					'Immaterielle Förderungen – insbesondere in Form von Beratungen durch und Kooperationen mit Landesinstitutionen – können dazu beitragen, das Spannungsfeld zwischen Wirtschaftlichkeit und Nachhaltigkeit von Kulturveranstaltungen zu entschärfen.' +
					'\n' +
					'Künftig sollen bei Veranstaltungen auch Maßnahmen zur Anpassung an den Klimawandel (z. B. Abschattungen bei Hitzebelastung) klar ausgewiesen und kommuniziert sowie in weiterer Folge deren Umsetzung geprüft werden. Eigens geschaffene Plattformen bündeln Informationen und geben sie weiter, bieten Beratung und Hilfestellung bei der Erarbeitung von Konzepten, führen Best-Practice-Beispiele an und informieren über Vernetzungsmöglichkeiten. ' +
					'\n' +
					'Dadurch soll ein sichtbares Statement für die Relevanz dieses Themas gesetzt und das Bewusstsein dafür gestärkt werden, dass Nachhaltigkeit einen hohen Stellenwert in Niederösterreich hat.' +
					'',
			},
			{
				InfoboxItemTitle: 'Bewusstsein schaffen und Projekte umsetzen und den ökologischen Fußabdruck verkleinern (Green Culture Events)',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die jährlich 2,8 Millionen Besucher*innen kultureller Veranstaltungen in Niederösterreich verbrauchen wie in jedem anderen Bereich des Alltags Ressourcen und verursachen klimaschädliche CO2-Emissionen. Insbesondere Transport und Abfälle sind wesentliche Treiber dafür. Hier gilt es strategisch anzusetzen, um im Bereich der Veranstaltungen klimabewusster und damit klimafreundlicher zu agieren.' +
					'\n' +
					'In diesem Sinne sind nachhaltige Transportmöglichkeiten anzubieten. Bei vorhandener öffentlicher Infrastruktur soll die An- und Abreise attraktiver gestaltet werden. Durch Kooperationsmöglichkeiten mit Verkehrsverbünden (z. B. VOR) lässt sich das Angebot an Kombitickets ausweiten. Kann eine Anreise nicht mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad erfolgen, sollten umweltfreundliche Alternativen zum PKW – wie Shuttledienste, Fahrgemeinschaften oder Mitfahrbörsen – angeboten werden. ' +
					'\n' +
					'Ausschlaggebend für die Entscheidung, mit dem eigenen PKW anzureisen, sind meist insbesondere das spätabendliche Veranstaltungsende und die „letzte Meile“, die sich alternativ indes mit Shuttlediensten, Fahrrädern oder E-Scootern bewältigen lässt. Damit solche Maßnahmen wirken, müssen sie zeitgerecht bereits bei der Bewerbung kommuniziert werden.' +
					'\n' +
					'Auf die CO2-Emissionen wirkt sich auch die Beschaffung aus. Daher ist verstärkt Augenmerk auf die Verwendung regionaler landwirtschaftlicher Produkte wie Wein, Obst, Gemüse und Fleisch zu richten, zumal diese in Niederösterreich in Spitzenqualität erzeugt werden. Regionale Beschaffung ist nicht nur ein wichtiger Beitrag zum Klimaschutz, sondern gibt auch der regionalen Produktion nachhaltig Auftrieb. ' +
					'\n' +
					'Durch Kooperationen mit Umweltverbänden und einschlägigen Initiativen (z. B. „Sauberhafte Feste“) lässt sich selbst bei Großveranstaltungen Umweltschutz leben und eine gleichermaßen abfallarme wie wirtschaftliche (Fest-)Kultur sicherstellen. Ressourcenschonung beginnt schon bei der Bewerbung der Events, die über digitale Medien erfolgen sollte. Vor allem der Abfallwirtschaft – Vermeidung und Recycling von Abfällen – kommt aber eine wesentliche Rolle zu. Abfallvermeidung muss für jede (Groß-)Veranstaltung bereits bei der Konzeption mitgedacht werden: durch ökologisches Einweggeschirr, ökologische Toiletten, bestmögliche Trennung und Sammlung der Abfälle unter Berücksichtigung des regionalen Abfalltrennsystems sowie Zuführung zu einer umweltgerechten Verwertung. ' +
					'\n' +
					'Die Lower Austrian Film Commission (LAFC) als zentrale Servicestelle des Landes Niederösterreich für Filmschaffende versucht exemplarisch zu zeigen, welch nachhaltige Wirkung in den Bereichen Beratung und Unterstützung erzielt werden kann. Ihr Angebot wurde in den vergangenen Jahren um ein wegweisendes Maßnahmenbündel erweitert, das zeitgemäßen Klimaschutz in der Praxis möglich machen soll: Der seit 2018 bestehende Leitfaden für nachhaltiges Filmschaffen wurde zum „Evergreen Prisma“ ausgebaut, das mit einer digitalen Plattform, einem Weiterbildungsprogramm und Netzwerkarbeit ein breites Spektrum an Wissen zur Nachhaltigkeit bietet und Filmproduktionen in diesem zukunftsweisenden Bereich auf vielseitige Weise begleitet.' +
					'\n' +
					'Für professionelle Filmschaffende und den Filmnachwuchs bietet die LAFC seit März 2021 Workshops zu Green Filming – als Dachthema und gezielt für die verschiedenen Gewerke – an. Seit Mai 2021 hat die LAFC auch die österreichweit einzige Weiterbildung zum professionellen Green Film Consultant Austria in ihrem Portfolio. Mit ihrem markanten Signet „Evergreen Icon“ hebt sie zudem Nachhaltigkeitsaspekte von Locations, Facilities, Crew und Filmprojekten in ihrer gesamten Datenbank hervor, um Filmschaffenden die Möglichkeit zu geben, ihre Erfolge im Bereich der Nachhaltigkeit sichtbar zu machen und als Vorbilder zu fungieren. Aufgrund des wachsenden Interesses an Green Filming und dessen zunehmender Bedeutung im gesamteuropäischen Kontext erweitert die LAFC als transnationales Kompetenzzentrum sukzessive ihr Angebot.' +
					'\n' +
					'Kulturbetriebe wie das Museum Niederösterreich, die Römerstadt Carnuntum oder der Brandlhof in Radlbrunn wiederum haben sich freiwillig der Zertifizierung nach dem Österreichischen Umweltzeichen unterzogen, das umweltfreundliche Dienstleistungen und Produkte kennzeichnet. Weitere Kulturbetriebe in Niederösterreich werden folgen.' +
					'',
			},
			{
				InfoboxItemTitle: 'Nachhaltige Bauwirtschaft mit nachhaltigen Betriebskonzepten forcieren',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Besonders groß ist der ökologische Fußabdruck in der Baubranche, die einen beträchtlichen Teil der globalen Ressourcen verbraucht. Nachhaltigkeit am Bau ist demnach ein Gebot der Stunde. Sie lässt sich beispielhaft im Bereich Denkmalpflege darstellen. Denn durch die Erhaltung denkmalgeschützter Gebäude und die Verlängerung ihres Lebenszyklus werden Energie- und Ressourcenverbrauch, Flächenverbrauch und Bodenversiegelung reduziert. Bei der Restaurierung denkmalgeschützter Häuser kommen natürliche, rückstandsfreie Werkstoffe zum Einsatz, was die „graue Energie“ für die Herstellung neuer Baumaterialien verringert.' +
					'\n' +
					'Bei der Sanierung bereits bestehender Objekte der Kulturinfrastruktur oder bei Neubauten gilt es, durch gezieltes Umweltmanagement, insbesondere in Sachen Emissionen, Wasser, Energie und Abfall, den Ressourcenverbrauch zu optimieren. Durch den Einsatz energiesparender Geräte oder Produkte (z. B. LED als Leuchtmittel) und durch aktives Energiemanagement (Vermeidung von Heizlüftern, Einsatz von Ventilatoren, Reduktion der Notwendigkeit der Kühlung) lässt sich auch im Bereich von Kunst und Kultur ein wesentlicher Beitrag zur Verkleinerung des ökologischen Fußabdrucks leisten. ' +
					'\n' +
					'Dabei sollte die Umstellung des Strombezugs auf langfristig zu 100 Prozent erneuerbaren Strom angestrebt werden, um so Gebäude und Veranstaltungen mit nachhaltiger Energie zu versorgen. Auch für die Wärmeversorgung sind Nachhaltigkeit und Erneuerbarkeit anzupeilen.' +
					'',
			}
		]
	},
	{ id: 3,
//		heading: '2 | KULTUR & TOURISMUS ZUM GESAMTERLEBNIS ENTWICKELN',
		heading: 'KULTUR & TOURISMUS ZUM GESAMTERLEBNIS ENTWICKELN',
		icon:'FieldTourism',
		iconwidth: '2.8',
		subheading: 'Daher miteinander kreative Wege gehen und Neues ausprobieren',
		longdesc: [
			{
				descHeader: '',
				descText: 'Niederösterreich ist ein Kulturland. Mit seinem gleichermaßen dichten wie sich regional ausdehnenden, inhaltlich ebenso breiten wie hochwertigen Kulturangebot kann es sich auch im touristischen Sinne als solches positionieren. Dabei soll sich Kulturtourismus nicht auf den Besuch von Städten und historischen Stätten beschränken. ' +
					'\n' +
					'In Niederösterreich haben die kulturelle Regionalisierung und die Schaffung kultureller Infrastruktur den Weg in die Zukunft vorgezeichnet. Die Erfahrungen aus jüngster Vergangenheit zeigen, dass Kunst und Kultur zum einen immense regionale Bedeutung haben, zum anderen aber auch kulturinteressierte Gäste Hotspots im ländlichen Raum ansteuern. Darauf gilt es – etwa durch Maßnahmen wie die neue Ausbildungsschiene der Kultur.Region.Niederösterreich für Tourismusverantwortliche in den Gemeinden – aufzubauen, will man die Regionalkultur in all ihren Facetten deutlicher sichtbar machen und Authentisches aus der Region noch professioneller vermitteln. Denn Regionalkultur ist keine Dekoration, sondern spiegelt die Verwurzelung des Menschen in Tradition und Moderne seiner Heimat wider.' +
					''
			},
			{
				descHeader: 'Schritte und Wege',
				descText: 'Niederösterreichs umfassendes Kunst- und Kulturangebot ist wichtiger Bestandteil des Tourismusangebotes im Land. So definiert auch die neue „Tourismusstrategie 2025“ das „Kunst- und Kulturerlebnis“ als eines von vier touristischen Kompetenzfeldern in Niederösterreich. Der Kultur- und der Tourismusbereich werden ihr Zusammenspiel in Konzeption und Kommunikation intensivieren und stärker mit wechselseitigen Botschaften kommunizieren: mehr touristische Inhalte in der Kulturkommunikation und mehr kulturelle Inhalte in der Tourismuskommunikation.' +
					'\n' +
					'Eine verstärkte Zusammenarbeit in jenen Bereichen, in denen Niederösterreich Spitzenleistungen von überregionaler Bedeutung erbringt, ist ein ideales Vehikel, um das Image einer Region bzw. Destination zu stärken. Eines der Ziele besteht darin, Gäste von nah und fern zu Übernachtungen und einer längeren Aufenthaltsdauer zu animieren. Je größer das Angebot, desto mehr Gründe gibt es für einen längeren Besuch – oder für einen weiteren Aufenthalt in einer Region.' +
					''
			},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Ausflüge zu nachhaltigen, authentischen (Kurz-)Urlauben ausdehnen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Corona-bedingte Krise hat vieles, das bis dahin selbstverständlich erschien, infrage gestellt. Die Gesellschaft hat sich verändert, neue Werte werden verfolgt. Das betrifft auch die Einstellung zum Urlaub, der sich über die Erholung hinaus zu einem abwechslungsreichen, sinnstiftenden Gesamterlebnis entwickelt.' +
					'\n' +
					'Niederösterreich sticht diesbezüglich, wie auch die „Tourismusstrategie 2025“ fokussiert, durch eine Besonderheit hervor: nämlich durch die einzigartige Möglichkeit, lebendige Kunst- und Kulturerfahrungen mit dem Genuss regionaler landwirtschaftlicher Produkte wie Wein, Obst und Gemüse, mit dem Erlebnis lokaler Kulinarik und authentischer Natur sowie mit den hochwertigen Angeboten des Landes im Bereich Gesundheitstourismus zu kombinieren. ' +
					'\n' +
					'Damit eine Region touristisch erfolgreich ist, braucht es neben ganzheitlichen Konzepten zur Entwicklung und Vernetzung von Erlebnisräumen auch professionelle, qualitätsvolle und authentische Dienstleistungen und Produkte. Gemeinsam mit den Tourismusverantwortlichen sollen daher strategisch neue Kulturregionen als Erlebnisräume definiert und Leitthemen wie „Festivalland Niederösterreich“ oder „Die neue Sommerfrische“ samt Leitprodukten erarbeitet werden. Daneben bedarf es einer differenzierten Aufbereitung des umfassenden Angebotes im Land nach Kategorien, beispielsweise statische, historische, dauerhafte oder temporäre Kulturerlebnisse, Festivals, Kunst im öffentlichen Raum etc. ' +
					'\n' +
					'Besonderes Augenmerk ist auf ausgewählte Zielgruppen zu legen, auf Familien und urbanes, jüngeres Publikum, das einen nachhaltigen Lebensstil pflegt, Kulturveranstaltungen und Festivals besucht, verstärkt öffentlich anreist und Niederösterreich vielleicht erst als relevante Urlaubsdestination entdecken muss.' +
					'',
			},
			{
				InfoboxItemTitle: 'Urlaubssaisonen durch Kulturangebote verlängern',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Kulturtourismus ist zum großen Teil wetterunabhängig und ganzjährig möglich, Indoor- sowie Outdoor-Lokalitäten für kulturelle Events unterschiedlicher Größe und Ausrichtung sind im Bundesland beinahe flächendeckend vorhanden – da und dort auch in Gastronomie- und Hotelleriebetrieben. ' +
					'\n' +
					'Viele Kunst- und Kulturangebote haben das Potenzial, die Saisonalität zu durchbrechen und in Kombination mit der regionalen Genusskompetenz, Kulinarik-, Natur- und Gesundheitsangeboten ein Hebel für Mehrtages- bis Ganzjahrestourismus (Stichwort „365-Tage-Destinationen“) zu werden. Urlaub soll sich über die Erholung hinaus immer mehr zum Gesamterlebnis entwickeln. ' +
					'\n' +
					'Aus der Tatsache, dass die Pro-Kopf-Ausgaben von Kulturtourist*innen jene anderer Gäste deutlich übersteigen, lassen sich Maßnahmen ableiten, um Synergieeffekte zu nutzen. Dafür ist auf eine enge Abstimmung mit den Tourismusdestinationen und der Gastronomie bzw. Hotellerie, regional auch auf die Abstimmung von Öffnungs- und Veranstaltungszeiten besonderer Wert zu legen. Die zahlreichen niederösterreichischen Regionalmuseen mit ihrer bunten Themenvielfalt können hier ebenso wie auch Brauch-Veranstaltungen und Tradition oder Kunst im öffentlichen Raum einen wesentlichen Beitrag leisten. ' +
					'\n' +
					'Regionale Ganzjahres-Kulturkalender sollen als Pilotprojekt gestartet werden: Sie können ein wichtiges Werkzeug für den Tourismus darstellen. Kulturtourismus muss digital aufholen und die vielen Chancen nutzen, die damit einhergehen (One-Stop-Lösungen, Apps, Gamification etc.). ' +
					'',
			},
			{
				InfoboxItemTitle: 'Den Destinationstyp „ländlicher Raum“ entwickeln',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Overtourism ist ein weltweites Phänomen, das international viele Destinationen – von Städten bis zu kulturhistorischen Stätten – betrifft und bei allen Beteiligten Stress hervorruft. Eine Gegenbewegung dazu stellt der „sanfte Tourismus“ in Niederösterreich dar, der in Hinkunft von neuen gesellschaftlichen Bedürfnissen getragen sein wird. Die Sehnsucht nach gesunder Umwelt und Natur, nach Entschleunigung und Abstand zum Alltag eröffnet ungeahnte Perspektiven. Hier gilt es, einen weiten Bogen zu spannen. ' +
					'\n' +
					'Städtisches Umland ist schon jetzt touristisches Naherholungsgebiet. Gäste und Tourist*innen sollen verstärkt auf die (umliegenden) ländlichen Regionen und Dörfer aufmerksam gemacht werden. So lässt sich nicht zuletzt die Aufenthaltsdauer der Besucher*innen erhöhen und können neue Kund*innen für Beherbergungsbetriebe gewonnen werden. ' +
					'\n' +
					'Der Tourismus sucht in den Destinationen nach Alleinstellungsmerkmalen – die Kultur kann den Beitrag zur Differenzierung von Regionen leisten, vermag sie doch die Unterschiedlichkeit ländlicher Räume, ihre Singularität, Echtheit, Lebensqualität etc. erlebbar machen. Regionen abseits urbaner Räume bieten große Chancen für innovative, inklusive Kultur- und Tourismusformate gegen den Mainstream, die im urbanen Bereich nicht oder nur schwer möglich sind. ' +
					'\n' +
					'Ziel muss es sein, die Kulturarbeit in den Regionen zu stärken, damit auch kulturelle Zentren zu festigen und klarer zu profilieren (regionaler USP). Die Kultur.Region.Niederösterreich hat sich hier mit der erfolgreich gestarteten Impulsreihe „Mein Ort bewegt“ verdient gemacht, die künftig Fortsetzung finden wird.' +
					'\n' +
					'Hochwertige Kulturerlebnisse sollten mit einmaligen kulturellen Events kombiniert werden. Kunst und Kultur werden immer mehr als Begegnungsraum, als Orte des Dialogs und des Diskurses verstanden. Auf die Einbindung der lokalen Bevölkerung ist demnach besonders Bedacht zu nehmen: Sie sollte die regionstypischen Angebotspakete als authentische Botschafterin (mit-)tragen. Leidenschaftliche Gastgeber*innen wirken bei touristischen wie auch kulturellen Angeboten anziehend auf Besucher*innen. Aber auch die gelebte „Grätzelwirtschaft“ kann und soll vermehrt durch kulturelle Aspekte bereichert werden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Rahmenbedingungen schaffen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Produktentwicklung auf Regions- bzw. Destinationsebene verlangt gleichermaßen klare Strukturen wie den Mut zur Bündelung und Straffung dieser Strukturen. Je stärker Destinationsmanagement sowohl touristische als auch kulturelle Aspekte berücksichtigt und Fachleute aus Kulturverwaltung und -vernetzung sowie Kulturanbieter*innen einbindet, desto erfolgreicher wird es sein. Als überlegenswert seien hier beispielsweise die „Landpartien“ von Kunst im öffentlichen Raum Niederösterreich, Museumsverbünde und das Modell der „Kulturcoaches“ genannt.' +
					'\n' +
					'Die wissenschaftliche Begleitung der Zusammenführung von Tourismus, Kultur und Wirtschaft kann sich in den kommenden Jahren als essenziell erweisen. Durch die gemeinsame Analyse und die fokussierte Aus- und Weiterbildung soll das Verständnis füreinander gefördert und eine gemeinsame Sprache gefunden werden. So lassen sich beispielsweise neue Ansätze der Kunst- und Kulturvermittlung für den Tourismus zugänglich und nutzbar machen. ' +
					'\n' +
					'Um den Kulturtourismus zu beleben, sollen die landesnahen Kulturinstitutionen (NÖKU-Betriebe) im Sinne eines Empfehlungsmarketings ausgewählte, für ihr Zielpublikum besonders relevante (kultur-)touristische Angebote mitbewerben – dazu kann man auch die Kulturveranstalter*innen einladen. Großen Kulturakteur*innen kommt wiederum die wichtige Aufgabe zu, kleinere hervorzuheben oder „mitzunehmen“ und dadurch Synergien zum wechselseitigen Nutzen zu heben. Und schließlich sind ganz allgemein touristische Anbieter*innen aufgerufen, auf Angebote im Bereich Kunst und Kultur aufmerksam zu machen!' +
					'\n' +
					'Ganz wesentlich wird es vor allem sein, innovative Förderperspektiven für Kunst und Kultur im ländlichen Raum anzudenken und Regionalentwicklung durch kulturelles Engagement im Konnex mit öffentlichen Verkehrsanbindungen weiter anzustoßen. Damit geht nicht zwangsläufig die Neuerrichtung kultureller Infrastruktur einher – vielmehr können etwa Leerstandsbespielungen in den Fokus rücken. Neben ecoplus, der Wirtschaftsagentur des Landes Niederösterreich, sollen lokale Sponsor*innen aus Wirtschaft, Gastronomie und Hotellerie sowie Einzelpersonen motiviert werden, authentische und imagebildende Vorhaben zu fördern oder als Partner*innen dafür aufzutreten. ' +
					'',
			},


		],
	},
	{ id: 4,
//		heading: '3 | MODELLREGIONEN FÜR KUNST UND KULTUR FORMEN',
		heading: 'MODELLREGIONEN FÜR KUNST UND KULTUR FORMEN',
		icon:'FieldModel',
		iconwidth: '5',
		subheading: 'Daher Kriterien definieren, Vorzeigeprojekte entwerfen und St. Pölten 2024 als Musterregion zum Erfolg führen',
		longdesc: [
			{
				descHeader: '',
				descText: 'Die Definition einer Modellregion stellt Neuland dar. Die genannten Kriterien bieten einen Anhaltspunkt, werden aber für andere Regionen als den Zentralraum St. Pölten neu zu bestimmen sein. Im Kern soll es aber jedenfalls um regionale Entwicklung gehen, vorangetragen durch künstlerische und kulturelle Prozesse. Stets wird das Zusammenspiel von Akteur*innen und von Institutionen ganz verschiedener Ausrichtung von zentraler Bedeutung sein, um gemeinsam mehr zu erreichen, als das eigene Aufgabengebiet umfasst.' +
					'\n' +
					'Die Modellregionen der Zukunft sind heute noch nicht fassbar. Viel hängt vom Wollen der Akteur*innen, von gemeinsamen Zielvorstellungen und von brach liegenden Potenzialen ab. Die Region um den Semmering weist gute Voraussetzungen dafür auf. Und so wäre es keine Überraschung, wenn nach St. Pölten 2024 der Semmering und seine Umgebung eine neue Modellregion bilden würden.' +
					''
			},
		],
		infobox: [
			{
				InfoboxItemTitle: 'Kunst und Kultur als Faktoren der regionalen Entwicklung',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Kunst und Kultur wohnt die Kraft inne, wesentliche Beiträge zu einer gesellschaftlichen Weiterentwicklung zu leisten. Das Erkennen relevanter Themen, deren Beleuchtung aus allen Betrachtungswinkeln – besonders aus Minderheitenperspektiven – sind ganz grundsätzliche Anliegen von Kunst und Kultur. In einer weiteren Sichtweise geht es um Kommunikation, um Kontroverse und um produktive Weiterentwicklung.' +
					'\n' +
					'Das kann bis zur Suche, Findung oder auch Neudefinition des eigenen Selbstbildnisses oder gar der eigenen Identität führen. Der Akt der Auseinandersetzung mit und des Aushandelns von Themen bildet eine wesentliche Grundlage, ganz besonders in einem größeren regionalen Zusammenhang.' +
					'',
			},
			{
				InfoboxItemTitle: 'Niederösterreichische Landesausstellungen',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Seit Jahrzehnten sind die NÖ Landesausstellungen Schrittmacher einer von Kunst und Kultur vorangetriebenen regionalen Entwicklung. Dabei haben sie stets sich wandelnde gesellschaftliche Rahmenbedingungen aufgegriffen und zur Diskussion gestellt. Mehr und mehr fand eine Neuausrichtung statt, die vom Ausgangspunkt, einer „Ausstellung“, zu einer Plattform regionaler, kulturell fundierter Entwicklung führte. Obwohl erfolgreich und mit viel Zuspruch durch das Publikum ausgezeichnet, gilt es, weitere Schritte zu setzen, damit Niederösterreichische Landesausstellungen auch in Zukunft Anregungen dafür liefern, wie sich Herausforderungen meistern lassen. Dabei soll noch stärker als bisher in kulturellen Modellregionen gedacht und die Verschränkung von Akteur*innen im regionalen kulturellen Bereich, besonders von örtlichen Museumsbetreiber*innen, intensiviert werden. Die Schaffung von Modellregionen ist als wesentliches Ziel bei der Gestaltung von NÖ Landesausstellungen zu definieren.' +
					'',
			},
			{
				InfoboxItemTitle: 'Kunst- und Kulturschwerpunkt St. Pölten 2024 (Arbeitstitel)',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Was den Kunst- und Kulturbereich betrifft, befindet sich die niederösterreichische Landeshauptstadt in einer neuen Gründer- und Profilierungsphase – und das ungeachtet ihrer bewegten Geschichte, die bis in Römische Zeit zurückreicht.' +
					'\n' +
					'Die Ernennung zur Landeshauptstadt 1986 markierte eine Zäsur in der städtischen Entwicklung. Neben Einrichtungen der Landesverwaltung und städtischen Institutionen sowie Vereinen und Initiativen der freien Szene haben sich seither weit über die Stadtgrenzen wirkende Kultureinrichtungen des Landes etabliert: vom Festspielhaus St. Pölten, Museum Niederösterreich und NÖ Landesarchiv über NÖ Landesbibliothek, Klangturm und Bühne im Hof bis zum Landestheater Niederösterreich, das freilich schon vor 200 Jahren als Bürgertheater gegründet wurde. ' +
					'\n' +
					'Ausgehend von der Zivilgesellschaft mit einer starken Verankerung im Bildungsbereich (FH St. Pölten, New Design University) brachte die Bewerbung St. Pöltens als Kulturhauptstadt Europas 2024 neuen Elan in die kulturelle Weiterentwicklung. Die Stadt St. Pölten und das Land Niederösterreich gründeten eine gemeinsame Trägergesellschaft, die den Prozess der Ideenfindung und -ausformulierung sowie die Erarbeitung zweier „Bid Books“ im Dialog mit der Zivilgesellschaft und unter Einbindung von Kunst- und Kulturtätigen, Kulturmanager*innen, Kulturinitiativen sowie interessierten Akteur*innen begleitete. Obwohl die Wahl zur Europäischen Kulturhauptstadt 2024 nicht auf St. Pölten fiel, wurde sofort die Entscheidung getroffen, alle wesentlichen Kulturinfrastrukturprojekte der Bewerbung – von der Renovierung der Ehemaligen Synagoge St. Pölten bis zum Neubau des „KinderKunstLabors“ (Arbeitstitel) – dennoch umzusetzen.' +
					'\n' +
					'Auf Basis des umfassenden Vorbereitungsprozesses und dieser kulturpolitischen Vision soll der „Kunst- und Kulturschwerpunkt St. Pölten 2024“ (Arbeitstitel) zum Vorzeigemodell einer nachhaltigen Weiterentwicklung werden und gemeinsam mit allen Kultureinrichtungen die Landeshauptstadt St. Pölten im Zusammenspiel mit ihrem Umland als bedeutendsten Kulturstandort und kulturelles Zentrum profilieren. ' +
					'\n' +
					'Damit soll auch der überregionale und europäische Blick auf St. Pölten gelenkt werden. Kunst und Kultur sollen Besucher*innen nach St. Pölten, aber auch in die umliegende Region mit ihrem bestehenden dichten kulturellen Angebot bringen. Solcherart soll St. Pölten mit seinem Umland im Jahr 2024 zur kulturellen und kulturtouristischen Musterregion werden.' +
					'',
			},
			{
				InfoboxItemTitle: 'Kennzeichnende Faktoren einer Modellregion am Beispiel St. Pöltens',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Bei der Beschreibung einer Modellregion stehen strukturelle und inhaltliche Argumente gegenüber geografischen Grenzziehungen im Vordergrund. Voraussetzungen stellen das Zusammenspiel der politischen Entscheidungsträger*innen mit Kunst-, Kultur- und Bildungsinstitutionen sowie mit Vertreter*innen der Zivilgesellschaft, die gemeinsame Festlegung verbindlicher inhaltlicher Ziele und Ansprüche bzw. Qualitätskriterien sowie die kontinuierliche Zusammenarbeit aller betroffenen Stellen dar.' +
					'\n' +
					'Im Sinne einer effizienten Vorgangsweise sollte Vorhandenes bestmöglich genutzt und einbezogen werden. Die Darstellung eines sorgsam kuratierten künstlerischen Programms und dessen Kommunikation sollen Anziehungskraft nach innen und außen entfalten. Der Fokus der Eigen- und Koproduktionen liegt auf St. Pölten, künstlerische Angebote aus dem Umland werden kommunikativ mitgenommen, wenn sie thematisch kooperieren bzw. die Qualitätsstandards der künstlerischen Leitung erfüllen.' +
					'\n' +
					'Auf den Modellfall St. Pölten angewandt, sollen die Landeshauptstadt und das Umland einander ergänzen und ein neues Gemeinschafts-, Zusammengehörigkeits- und Nachbarschaftsgefühl hervorbringen. Die Präsentation und die Sichtbarmachung künstlerischer und wissenschaftlicher Institutionen und Initiativen aus St. Pölten, dem Umland und allenfalls ganz Niederösterreich spielen eine wichtige Rolle in der identitätsstiftenden und -verstärkenden Positionierung als Lebens-, Wirtschafts- und Kulturraum.' +
					'\n' +
					'Um Kunst und Kultur breiten Bevölkerungsschichten einfacher als bisher zugänglich zu machen, gilt es Zugangsbarrieren zu identifizieren und weitestmöglich abzubauen. Kunst- und Kulturvermittlung, Integration, Inklusion und Partizipation sollen nicht nur Schlagwörter darstellen, sondern in allen Bereichen in konkreten Handlungen Niederschlag finden. Insbesondere ist dabei auch der öffentliche Raum gezielt in künstlerische Aktivitäten einzubeziehen, um eine niederschwellige Begegnung und Auseinandersetzung mit künstlerischen Inhalten zu ermöglichen.' +
					'',
			},
			{
				InfoboxItemTitle: 'Vorzeigeprojekte aus den vier Handlungsfeldern ',
				InfoboxItemSubtext: '',
				InfoboxDesc: 'Die Modellregion St. Pölten samt Umland soll ab dem Jahr 2024 Vorbildwirkung entfalten. Dies kann, soll aber nicht ausschließlich entlang der Festlegungen dieser Strategie für Kunst und Kultur erfolgen. In den vier zuvor definierten Handlungsfeldern gilt es Vorzeigeprojekte zu entwickeln:' +
					'\n' +
					'1) <b>Kinder und Familien:</b> Mit dem „KinderKunstLabor“ erhält St. Pölten 2024 eine in Europa einzigartige neue Kunst- und Kulturinstitution, einen innovativen Ort der Begegnung von jüngeren Besucher*innen mit zeitgenössischer (bildender) Kunst und Künstler*innen. Anstelle einer „Erziehung zur Kunst“ sind entdeckungsgeleitete und handlungsorientierte Konzepte die sinnlich-ästhetische Grundlage für Kunstvermittlung sowie Lern- und Bildungsprozesse. Angeregt durch die künstlerischen Impulse des Hauses können Kinder in einer geschützten Laborsituation abseits von Alltagsroutinen und Regeln selbst tätig werden.' +
					'\n' +
					'2) <b>Klimaschutz und Nachhaltigkeit:</b> Schon in der Planung der Kulturinfrastruktur wird ein Schwerpunkt auf Nachhaltigkeit, auf Sanierung und Modernisierung gelegt, wie z. B. mit der Renovierung der ehemaligen Synagoge in St. Pölten. Als Vorzeigeeinrichtung für den Bereich Klimaschutz gilt der aus dem Kunst- und Kulturverein LAMES und der Nachbarschaftsinitiative Sonnenpark hervorgegangene „Verein für Kunst, Kultur und Natur am Spratzerner Kirchenweg 81–83“ (Arbeitstitel): Mit seinen Projekten lädt er die Zivilgesellschaft dazu ein, sich aktiv zu engagieren – etwa im neu errichteten Klimaforschungslabor, das insbesondere Schulen zur Verfügung steht, oder in den Gemeinschaftsgärten, z. B. für aus ihrer Heimat geflüchtete Menschen. Bei der nun anstehenden Programmplanung muss es gelingen, zukunftsweisende künstlerische Vision und soziale Verantwortung mit ökologischer Orientierung in Einklang zu bringen.' +
					'\n' +
					'3) <b>Digitalisierung:</b> Die Covid-19-Pandemie hat die Verwundbarkeit auch des kulturellen Geschehens deutlich vor Augen geführt. Im Sinne einer Erhöhung der Resilienz wird es in der Programmierung des spartenübergreifend angedachten Programms für St. Pölten 2024 notwendig werden, Projekte oder Teile davon auch als Beitrag zur angestrebten sozialen Inklusion digital zu konzipieren. Zugleich muss klar sein, dass die in St. Pölten stattfindenden Projekte auf gesellschaftliche Relevanz und Aktualität abzielen, was es notwendig macht, an den Ort des künstlerischen Schaffens zu kommen, um ungewöhnliche Programmpunkte zu erleben, die ihrerseits unwiederbringliche Momente entstehen lassen. Die digitale Sichtbarmachung der archäologischen Ausgrabungen am Domplatz in Kooperation mit der Fachhochschule St. Pölten mittels Virtual und Augmented Reality könnte einen solchen Höhepunkt bieten und die angepeilte Rolle St. Pöltens als Zentrum einer Modellregion stärken.' +
					'\n' +
					'4) <b>Kulturtourismus:</b> Viel deutlicher als in der Vergangenheit soll die Landeshauptstadt mit einem klaren kulturellen Profil regionale, auf das gesamte Umland wirkende, und überregionale, auch internationale Anziehungskraft entfalten, die eine intensivere emotionale Bindung an St. Pölten schafft. Dabei gilt es, einen Verbund mit anderen relevanten kulturellen Institutionen in der Region, etwa in Krems und Grafenegg, in Melk und auf der Schallaburg oder auch bis Lilienfeld und Neulengbach anzustreben. Die nicht zuletzt quantitative Weiterentwicklung des Kulturtourismus in dieser Region ist ein erklärtes Ziel.' +
					'',
			},
		],
	},
]

