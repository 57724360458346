import React, {useState, useRef, useEffect, useLayoutEffect} from 'react'
import {useSpring, a, config} from '@react-spring/web';
import parse from "html-react-parser";
import DOMPurify from "dompurify";

import {useStore} from "./store";
import {Bullet, IconFetcher} from "./icons";
import Longtext from "./longtext";
import {contentGuidelines} from "../assets/content_guidelines";

function Guidelines() {

//	console.log("------ Guidelines -----");

	const hovered = useStore(state => state.hovered);
	const focus = useStore(state => state.focus);
	const setFocus = useStore(state => state.setFocus);

	const refVis = useRef([]);
	const refContainer = useRef();

	const [selected, setSelected] = useState(-1);


	const [show, setShow] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const setMoreInfo = useStore(state => state.setMoreInfo);
	const moreInfo = useStore(state => state.moreInfo);
	const [anim, setAnim] = useState(false);

	var field = contentGuidelines.find((d) => d.id === hovered);
	var longdesc = field.longdesc;
	var infobox = field.infobox;

	// Add a hook to make all links open a new window
	DOMPurify.addHook('afterSanitizeAttributes', function (node) {
		// set all elements owning target to target=_blank
		if ('target' in node) {
			node.setAttribute('target', '_blank');
		}
		// set non-HTML/MathML links to xlink:show=new
		if (
			!node.hasAttribute('target') &&
			(node.hasAttribute('xlink:href') || node.hasAttribute('href'))
		) {
			node.setAttribute('xlink:show', 'new');
		}
	});

	useEffect(()=>{
		if (focus > -1) {
			refContainer.current.focus()
			setFocus(-1)
		}
	}, [focus])


	useLayoutEffect(()=>{
		if (hovered !== selected) {
			setSelected( -1)
			setTimeout(()=>{
				setSelected(hovered)
			}, 300)
		}
	}, [hovered])


	const {o1} = useSpring({
		to: {o1: anim > -1 && refVis.current[anim] ? refVis.current[anim].getBoundingClientRect().height : 0},
//		delay: moreInfo > -1 ? 1700 : 700,
		config: config.default,
		onRest: () => setIsOpen(!isOpen),
	})

	const {o2} = useSpring({
		to: {o2: anim > -1 ? 1 : 0},
		config: config.default,
	})

	const {o3} = useSpring({
		to: {o3: selected > -1 ? 1 : 0},
		config: config.default,
	})

	const isize = [3, 2, 2.5, 2.5];
	const imargin = [0, 0, 0, 0]

	return (
		<div className="App">
			{selected !== -1 &&
			<a.div className="Container" style={{opacity: o3}}>
				<div className='TextContainer'>
					<div ref={e => refContainer.current = e} tabIndex={0} className={'HeadingContainer'}>
						<div className={'headings'}>
							<div className={'heading'}>{field.heading}</div>
							<div className={'subheading'}>{field.subheading}</div>
						</div>
						<div className={'icon'} style={{width: isize[hovered-5]*1.0+'em', marginTop: imargin[hovered-5]}}><IconFetcher icon={field.icon}/></div>
					</div>
					{longdesc && longdesc.length > 0 && longdesc.map((desc, i) => {
						let key = "hov_"+hovered+"item_"+i;
						return (
							<div className={'descContainer'} key={'longdesc' + i}>
								<div tabIndex={desc.descHeader ? 0 : -1} ref={refVis[i]}
								     className={'descHeader'}>{desc.descHeader ? desc.descHeader : ''}</div>
								<Longtext key={key} val={desc.descText ? desc.descText : ''} new={1} index={i}/>
							</div>
						)
					})
					}
					{infobox && infobox.length > 0 && <div className={'Infobox'}>
						{infobox.map((item, i) => {
							let index = i + longdesc.length;
							return (
								<div tabIndex={0} key={'ii' + index} className={'InfoboxItem'}>
									<div className={'InfoboxItemHeader'}>
										<div className={'bullet'}><Bullet/></div>
										<div className={'InfoboxItemTitle'}>
											{item.InfoboxItemTitle}
										</div>
									</div>
									<div className={'InfoboxItemSubtext'}>
										{item.InfoboxItemSubtext}
										{moreInfo !== index &&
											<button className={'link'} onClick={() => {
												refVis.current[index].focus();
												setAnim(index);
												setTimeout(() => {
													setMoreInfo(index);
												}, 10)
											}}
											>
												[mehr]
											</button>
										}
									</div>
									<a.div width='auto' style={{
										overflow: 'hidden',
										opacity: moreInfo === index ? o2 : 0,
										height: o1.to(h => moreInfo === index ? h + 'px' : '0px'),
									}}
									>
										{/*<div style={{overflow: 'hidden', height: moreInfo === index ? o : '0%'}}>*/}
										{/*{moreInfo === index &&*/}
										<div tabIndex={index === anim ? 0 : -1} ref={el => refVis.current[index] = el} className={'InfoboxDesc'}>
											{item.InfoboxDesc.split('\n').map((line, ii, arr) => {
												let key = 'pmo' + ii + '_' + i;
												const lineOut = <span className='descText'
												                      key={i}>{parse(DOMPurify.sanitize(line))}</span>;
												return <div className={'descPar'} key={key}>{lineOut}</div>;
											})}
										</div>
										{/*}*/}
									</a.div>
									<div key={'tw' + index} className={'InfoboxItemSubtext'}>
										{moreInfo === index &&
											<button className={'link'} onClick={() => {
												setAnim(-1);
												// wait a little bit to show div collapsing
												// ToDo: make this dependent in 'isOpen'
												setTimeout(() => {
													setMoreInfo(-1)
												}, 400)
											}}
											>
												{anim > -1 ? '[weniger]' : ''}
											</button>}
									</div>
								</div>
							)
						})}
						<div style={{height: '0vh', opacity: 0}}>...</div>
					</div>
					}
				</div>
			</a.div>
			}
		</div>
	);
}

export default Guidelines;
