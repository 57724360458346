import React, {useState, useEffect} from 'react'
import {useStore} from "./store";
import {useSpring, a, config} from '@react-spring/web';
import * as d3 from "d3";

export function Lens() {

	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);

	const [show, setShow] = useState(hovered);
	const [reset, doReset] = useState(false);

//	var debug = true;
	var debug = false;

	var timer = null;

	const roundlenses = [
		{x: 155, y: 300}, // field 1
		{x: 426, y: 160}, // field 2
		{x: 408, y: 350}, // field 3
		{x: 229, y: 128}, // field 4
		{x: 315, y: 240}, // field 5
	]
	const squaredlenses = [
		{x: 232, y: 420, width: 38, height: 150}, // guideline 1
		{x: 265, y: 420, width: 38, height: 150}, // guideline 2
		{x: 299.5, y: 420, width: 38, height: 150}, // guideline 3
		{x: 330, y: 420, width: 38, height: 150}, // guideline 4
		{x: 462, y: 675, width: 175, height: 47}, // focus 1
		{x: 230, y: 675, width: 150, height: 47}, // focus 2
		{x: 2, y: 675, width: 130, height: 47}, // focus 3
		{x: 585, y: 489, width: 52, height: 37}, // info
	]

	const handleSelected = (index) => {
//		setShow(false)
		timer = setTimeout(() => {
			setHovered(index)
			setShow(-1)
		}, 150)
	}

	const {o} = useSpring({
//		from: {o: 1},
		to: {o: show === -1 ? 1 : 0},
		delay: 100,
		config: config.default,
//		config: {duration: 800},
		onRest: ()=> {
			setShow(hovered)
		},
		onStart: ()=> {
			doReset(true)
		},
	})

	useEffect(()=>{

		if (reset) {
	        const svg = d3.selectAll('#treeSVG')
			const sel = svg.selectAll("circle[id^='anim']")
				.filter((d,i)=> i !== hovered)
				.attr('opacity', 0);
			const sel1 = svg.selectAll("rect[id^='anim']")
				.filter((d,i,a)=> a[i].id !== 'anim'+hovered)
				.attr('opacity', 0);

			doReset(false)
		}

	}, [reset])


	return (
		<>

			{squaredlenses.map((d, i) => {
				let index = i + roundlenses.length;

				let offset1 = d.height > d.width ? d.height / 2 : 0;
				let offset2 = d.width > d.height ? d.width / 2 : 0;

				let offset1w = d.height > d.width ? d.width : 0;
				let offset1h = d.width > d.height ? d.height : 0;

				let offset1r = d.width > d.height ? d.width : 0;

				let offsetSpecial = i === 7 ? 6 : 0;  // special offset for very small lenses => info

				let offsetopacity = d.height > d.width ? 0.2 : 0.05;

				return (
					<g key={'lens' + index}
						//	onMouseEnter={() => setHovered(true)}
						//	onMouseLeave={() => setHovered(false)}
					>
						{/*{hovered === index &&*/}
							<g>
								<radialGradient id={"grada" + index}
								                cx={d.x + offset2} cy={d.y + offset1} r={offset1r * 0.4 + offset1w * 2}
								                fx={d.x + offset2} fy={d.y + offset1} fr='10'
								                gradientUnits="userSpaceOnUse">
									<stop offset="0" stopColor={'#FFFFFF'} stopOpacity={0}/>
									<stop offset="0.8" stopColor={'#FFFFFF'} stopOpacity={0}/>
									<stop offset="1" stopColor={'#AAAAAA'} stopOpacity={offsetopacity}/>
								</radialGradient>
								<a.rect id={'anim' + index} fill={"url(#grada" + index + ")"} stroke="grey" strokeWidth="0"
								      strokeMiterlimit="10"
								      x={d.x} y={d.y} width={d.width} height={d.height}
								      rx={'15'} ry={'15'}
								        opacity={ hovered === index ? o.to(d => show === -1 ? d : 1) : 0 }/>
								/>

								<radialGradient id={"gradb" + index}
								                cx={d.x + offset2} cy={d.y + offset1+offsetSpecial} r={offset1 + offset2}
								                fx={d.x + offset2} fy={d.y + offset1} fr='20'
								                gradientUnits="userSpaceOnUse">
									<stop offset="0" stopColor={'#FFFFFF'} stopOpacity={0}/>
									<stop offset="0.8" stopColor={'#FFFFFF'} stopOpacity={0}/>
									<stop offset="1" stopColor={'#FFFFFF'} stopOpacity={1}/>
								</radialGradient>
								<a.rect id={'anim' + index} fill={"url(#gradb" + index + ")"} stroke="grey" strokeWidth="0"
								      strokeMiterlimit="10"
								      x={d.x} y={d.y} width={d.width} height={d.height}
								      rx={'15'} ry={'15'}
								        opacity={ hovered === index ? o.to(d => show === -1 ? d : 1) : 0 }/>

								<linearGradient id={"gradc" + index} x1={d.x} y1={d.y} x2={d.x + offset1w}
								                y2={d.y + offset1h}
								                gradientUnits="userSpaceOnUse">
									<stop offset="0" stopColor={'#FFFFFF'} stopOpacity={0}/>
									<stop offset="0.85" stopColor={'#FFFFFF'} stopOpacity={0}/>
									{/*<stop  offset="0.9" stopColor={'#AAAAAA'} stopOpacity={0.5}/>*/}
									{/*<stop  offset="0.95" stopColor={'#AAAAAA'} stopOpacity={0.0}/>*/}
									{/*<stop  offset="1"    stopColor={'#FFFFFF'} stopOpacity={1}/>*/}
									<stop offset="1" stopColor={'#AAAAAA'} stopOpacity={0.7}/>
								</linearGradient>
								<a.rect id={'anim' + index} fill={"url(#gradc" + index + ")"} stroke="grey" strokeWidth="0"
								      strokeMiterlimit="10"
								      x={d.x} y={d.y} width={d.width} height={d.height}
								      rx={'15'} ry={'15'}
								        opacity={ hovered === index ? o.to(d => show === -1 ? d : 1) : 0 }/>

								<linearGradient id={"gradd" + index} x1={d.x + offset1w} y1={d.y + offset1h} x2={d.x}
								                y2={d.y}
								                gradientUnits="userSpaceOnUse">
									<stop offset="0" stopColor={'#FFFFFF'} stopOpacity={0}/>
									<stop offset="0.85" stopColor={'#FFFFFF'} stopOpacity={0}/>
									<stop offset="1" stopColor={'#AAAAAA'} stopOpacity={0.6}/>
								</linearGradient>
								<a.rect id={'anim' + index} fill={"url(#gradd" + index + ")"} stroke="grey" strokeWidth="3"
								      strokeMiterlimit="10"
								      x={d.x} y={d.y} width={d.width} height={d.height}
								      rx={'15'} ry={'15'}
								        opacity={ hovered === index ? o.to(d => show === -1 ? d : 1) : 0 }/>

							</g>
						{/*}*/}
					</g>

				)
			})}

			{roundlenses.map((d, i) => {
				let index = i;
				return (
					<g key={'lens' + index}
						//	onMouseEnter={() => setHovered(true)}
						//	onMouseLeave={() => setHovered(false)}
					>
						{/*{hovered === index &&*/}
							<g>
								<radialGradient id={"grad" + index} cx={d.x} cy={d.y} r="90" fx={d.x} fy={d.y} fr='15'
								                gradientUnits="userSpaceOnUse">
									{/*<stop offset="0%" stopColor={'rgba(199,199,199,0.01)'}/>*/}
									{/*<stop offset="70%" stopColor={'rgba(199,199,199,0.1)'}/>*/}
									{/*<stop offset="100%" stopColor={'rgba(255,255,255,0.9)'}/>*/}
									<stop offset="0" stopColor={'#C7C7C7'} stopOpacity={0.01}/>
									<stop offset="0.7" stopColor={'#C7C7C7'} stopOpacity={0.1}/>
									<stop offset="1" stopColor={'#FFFFFF'} stopOpacity={1}/>
								</radialGradient>
								<a.circle id={'anim' + index} fill={"url(#grad" + index + ")"} stroke="grey" strokeWidth="3"
								        strokeMiterlimit="10"
								        cx={d.x} cy={d.y} r="90"
								        opacity={ hovered === index ? o.to(d => show === -1 ? d : 1) : 0 }/>
							</g>
						{/*}*/}
					</g>

				)
			})}

			{squaredlenses.map((d, i) => {
				let index = i + roundlenses.length
				return (
					<g key={'sqlensint' + index} id={index}
					   onMouseOver={() => handleSelected(index)}
					   onMouseOut={() => clearTimeout(timer)}
					   onClick={()=>handleSelected(index)}
					>
						<rect
							x={d.x} y={d.y} width={d.width} height={d.height}
							fill="red" strokeWidth="1"
							strokeMiterlimit="10" fillOpacity={debug ? 1 : 0}
							opacity={'0.5'}
						/>
					</g>
				)
			})}

			{roundlenses.map((d, i) => {
				let index = i;
				return (
					<g key={"lensint" + index} id={index}
					   onMouseOver={() => handleSelected(index)}
					   onMouseOut={() => clearTimeout(timer)}
					   onClick={()=>handleSelected(index)}
					>
						<circle fill="red" strokeWidth="1"
						        strokeMiterlimit="10" fillOpacity={debug ? 1 : 0}
						        cx={d.x} cy={d.y} r="50" opacity={'0.5'}/>
					</g>
				)
			})}

		</>

	)
}
