import React from 'react'

export function IconFetcher(arg) {
	if (arg.icon === '') return;
	switch (arg.icon) {
		case 'FieldDigit':
			return <FieldDigit/>
			break;
		case 'FieldClimate':
			return <FieldClimate/>
			break;
		case 'FieldFamily':
			return <FieldFamily/>
			break;
		case 'FieldTourism':
			return <FieldTourism/>
			break;
		case 'FieldModel':
			return <FieldModel/>
			break;
		case 'GuidelineCoo':
			return <GuidelineCoop/>
			break;
		case 'GuidelineCreat':
			return <GuidelineCreat/>
			break;
		case 'GuidelinePart':
			return <GuidelinePart/>
			break;
		case 'GuidelineDivers':
			return <GuidelineDivers/>
			break;

		default:
			return null;
	}

}

export function BackButton() {
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%" viewBox="0 0 133 30"
		     enableBackground="new 0 0 133 30">
			<text transform="matrix(1 0 0 1 46.5918 22)" fill="#548235" fontFamily="'Abel-Regular'"
			      fontSize="18" fontWeight={'bold'}>zurück
			</text>
			<g>
				<g>
					<line fill="none" stroke="#548235" strokeWidth="2" strokeMiterlimit="10" x1="106.169" y1="17.5"
					      x2="123.5"
					      y2="17.5"/>
					<g>
						<polygon fill="#548235"
						         points="112.796,25.68 114.16,24.215 106.938,17.502 114.16,10.787 112.796,9.322 104,17.502 			"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<line fill="none" stroke="#548235" strokeWidth="2" strokeMiterlimit="10" x1="15" y1="17.5"
					      x2="32.331"
					      y2="17.5"/>
					<g>
						<polygon fill="#548235"
						         points="25.704,25.68 24.34,24.215 31.563,17.502 24.34,10.787 25.704,9.322 34.5,17.502 			"/>
					</g>
				</g>
			</g>
		</svg>
	)
}


export function NextButton() {
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%" viewBox="0 0 60.082 47.672"
		     enableBackground="new 0 0 60.082 47.672">
			<g>
				<polygon fill="#FFFFFF" stroke="#969696" strokeWidth="2" strokeMiterlimit="10" points="13.482,32.034 32.447,32.034
		32.447,38.883 46.166,24.066 32.447,9.117 32.447,16.08 13.482,16.08 	"/>
			</g>
		</svg>

	)
}

export function PreviusButton() {
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%" viewBox="0 0 60.082 47.672"
		     enableBackground="new 0 0 60.082 47.672">
			<g>
				<polygon fill="#FFFFFF" stroke="#969696" strokeWidth="2" strokeMiterlimit="10" points="45.166,16.08 26.201,16.08
		26.201,9.117 12.482,24.066 26.201,38.883 26.201,32.034 45.166,32.034 	"/>
			</g>
		</svg>

	)
}


export function FieldDigit() {
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%"
		     viewBox="0 0 91.333 65.333"
		     enableBackground="new 0 0 91.333 65.333">
			<g>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M24.667,32.667c0-11.046,8.954-20,20-20
		s20,8.954,20,20s-8.954,20-20,20S24.667,43.712,24.667,32.667z"/>
				<path fill="#548235" d="M51.54,14.19l-3.803,1.04l1.385,3.582l-3.911-0.244l-0.326,2.851l-2.77,0.487l0.407,2.523l4.563-0.325
		L50.833,25l-0.326,3.338l-4.155-0.163l-2.77-1.547l-4.646,0.65l-2.77,5.861l2.2,3.908l5.704,0.326l0.568,3.175l1.061,1.546
		l0.082,2.85l1.874,1.223l4.97-2.851l0.163-4.478l3.748-4.152l-3.179-1.628l-1.223-3.501l2.771,1.384l1.385,1.386l0.896-2.933
		l-2.036-1.791l3.26-0.73l1.629,4.64l1.386-4.396c-0.876-1.271,1.413-2.358,0.538-3.628c-0.491-1.31-1.999-3.153-3.046-4.377
		c-0.946-1.117-1.817-1.784-2.66-2.427c-0.844-0.64-1.523-1.02-2.285-1.443c-1.231-0.807-1.918-0.862-2.249-1.075"/>
				<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M33.872,16.667l0.795,4.746l-4.594,1.438
		l-0.795,3.392l-1.944,0.424l0.442-2.373l-1.109,0.362c0.061-0.309,0.787-1.456,1.289-2.188s1.034-1.432,1.725-2.196
		c0.938-1.134,2.458-2.279,2.699-2.567"/>
				<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M28.667,43.881
		c-1.165-2.387-2.254-4.793-2.704-7.329c-0.45-2.535-0.338-6.245,0.003-7.886l3.373,1.652l0.296,4.327l3.031,3.268l-1.331,4.408
		l0.887,2.367l-1.347,1.978"/>
				<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M37.667,50.969l3.624-2.303l3.546,0.645
		l3.23,2.487l0.6,0.425c-0.191,0.052-0.551,0.037-1.188,0.181c-0.637,0.146-2.019,0.259-2.991,0.264
		c-0.976,0.005-2.421-0.364-3.412-0.523c-1.768-0.456-2.856-1.09-3.371-1.118"/>
				<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M58.045,39.667l-1.379,2.313v2.687l2-2.5
		C58.643,41.209,58.621,40.252,58.045,39.667z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M22.852,31.667H6.667"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M4.667,4.167c0-1.381,1.343-2.5,3-2.5
		s3,1.119,3,2.5s-1.343,2.5-3,2.5S4.667,5.547,4.667,4.167z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M10.667,3.667h10.686l11.314,10"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M10.667,17.167c0-1.381,1.343-2.5,3-2.5
		s3,1.119,3,2.5s-1.343,2.5-3,2.5S10.667,18.547,10.667,17.167z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M24.622,22.843l-8.955-4.177"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M4.667,61.667c0,1.655,1.119,3,2.5,3
		s2.5-1.345,2.5-3c0-1.657-1.119-3-2.5-3S4.667,60.009,4.667,61.667z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M9.667,61.667h10.686l11.314-10"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M10.667,48.167c0,1.381,1.343,2.5,3,2.5
		s3-1.119,3-2.5s-1.343-2.5-3-2.5S10.667,46.786,10.667,48.167z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M25.528,42.667l-8.861,4.133"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M0.667,31.667c0-1.657,1.343-3,3-3s3,1.343,3,3
		c0,1.655-1.343,3-3,3S0.667,33.322,0.667,31.667z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M68.667,31.667h16.139"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M90.667,31.667c0-1.657-1.343-3-3-3
		s-3,1.343-3,3c0,1.655,1.343,3,3,3S90.667,33.322,90.667,31.667z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M85.667,3.667c0-1.657-1.119-3-2.5-3
		s-2.5,1.343-2.5,3c0,1.655,1.119,3,2.5,3S85.667,5.322,85.667,3.667z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M80.667,3.667H69.981l-11.314,11"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M80.667,17.167c0-1.381-1.119-2.5-2.5-2.5
		s-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5S80.667,18.547,80.667,17.167z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M66.667,22.879l9.032-4.213"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M84.667,61.167c0,1.381-1.119,2.5-2.5,2.5
		s-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5S84.667,59.786,84.667,61.167z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M79.667,61.667h-10.2l-10.8-11"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M80.667,48.167c0,1.381-1.119,2.5-2.5,2.5
		s-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5S80.667,46.786,80.667,48.167z"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8"
				      d="M66.667,42.667l8.925,4.163"/>
			</g>
		</svg>
	)
}

export function FieldClimate() {
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%"
		     viewBox="0 0 47.32 50.612" enableBackground="new 0 0 47.32 50.612">
			<g>
				<path fill="#548235" d="M0,42.464l8.827,8.148l4.336-3.749l1.278-0.767l1.626-0.612l13.511-0.42l1.742-0.19l1.433-0.728l5.11-2.372
		l6.079-4.016l2.94-2.449l0.116-0.151l-0.116-0.956l-0.658-0.88l-1.161-0.497l-2.13-0.115l-1.549,0.421l-1.626,0.918l-4.528,2.792
		l-4.183,1.186l-4.336,0.153l-1.935-0.267l-2.016-0.574l5.81-1.377l1.78-0.804l1.237-1.187l0.504-1.339l0.427-1.568l-0.154-1.031
		l-0.58-0.421l-5.11,0.727l-4.336,0.727l-2.942,0.42l-6.466,0.385l-2.71,0.151l-2.013,0.535l-1.665,1.492l-1.742,2.18L0,42.464z"/>
				<path fill="#548235" d="M17,12.612l0.396,2.692l0.793,3.026l1.585,2.494l1.518,2.062l2.972,2.294l2.444,1.097l1.584,0.335
		l1.255-0.301l-0.528-1.396l-1.022-2.095l-2.014-2.396l-2.542-2.694l3.004,2.128l1.916,2.328l1.287,2.128l0.659,1.164l0.266,0.167
		L31,25.082v-2.028l-0.363-1.929l-0.76-1.929l-1.651-2.194l-2.243-2.095l-3.533-1.466l-3.038-0.798L17,12.612z"/>
				<path fill="#548235" d="M47.32,9.844l-0.683,2.244l-1.063,2.478l-1.676,1.924l-1.564,1.563l-2.841,1.576l-2.235,0.623l-1.401,0.081
		l-1.039-0.415l0.631-1.122l1.144-1.654l2.031-1.783l2.521-1.972l-2.848,1.431l-1.938,1.739l-1.375,1.649l-0.715,0.906l-0.247,0.107
		l-0.298-0.536l0.258-1.729l0.557-1.598l0.896-1.547l1.694-1.659l2.192-1.499l3.217-0.796l2.707-0.293L47.32,9.844z"/>
				<path fill="#548235" d="M22.871,0l-0.185,2.182l0.065,2.518l0.79,2.245l0.817,1.896l1.92,2.348l1.724,1.313l1.188,0.555
		l1.044-0.001l-0.158-1.19l-0.421-1.83L28.51,7.787l-1.508-2.581l1.976,2.224l1.081,2.178l0.622,1.905l0.309,1.034l0.177,0.179
		l0.443-0.361l0.373-1.59l0.068-1.575l-0.243-1.651l-0.896-2.024l-1.383-2.057l-2.518-1.802l-2.248-1.189L22.871,0z"/>
			</g>
		</svg>
	)
}

export function FieldModel() {
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%"
		     viewBox="5 10 52 55" enableBackground="new 0 0 52 55">
			{/*<path fill="none" d="M0,32C0,14.327,14.327,0,32,0s32,14.327,32,32S49.673,64,32,64S0,49.673,0,32z"/>*/}
			<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M50.401,20.219
	c6.366,11.229,2.62,25.608-8.371,32.115c-1.729,1.021-3.577,1.811-5.503,2.345"/>
			<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M27.112,60.362
	c2.77-2.813,6.219-4.866,10.014-5.962"/>
			<path fill="#548235" d="M45.452,37.146l-0.645,1.424l-0.341,1.438l-0.046,1.342l0.224,1.219l0.489,1.124l0.89,0.859l1.094,0.577
	l0.9-1.136l0.457-1.309l-0.105-1.661c-0.039-0.436-0.096-0.679-0.135-1.112l-1.117-1.643L45.452,37.146z"/>
			<path fill="#548235" d="M37.418,45.358l-1.163,1.043l-0.894,1.178l-0.584,1.208l-0.29,1.203l-0.006,1.228l0.464,1.145l0.766,0.972
	l1.285-0.674l0.947-1.012l0.572-1.562c0.143-0.414,0.188-0.66,0.328-1.074l-0.356-1.952L37.418,45.358z"/>
			<path fill="#548235" d="M41.905,41.588l-0.838,1.317l-0.542,1.374l-0.236,1.321l0.047,1.238l0.327,1.183l0.757,0.978l1,0.726
	l1.054-0.996l0.64-1.228l0.13-1.661c0.022-0.438,0.002-0.687,0.023-1.121l-0.872-1.783L41.905,41.588z"/>
			<path fill="#548235" d="M46.878,31.73l-0.146,1.557l0.142,1.471l0.39,1.284l0.604,1.082l0.828,0.903l1.118,0.526l1.221,0.193
	l0.485-1.367l0.012-1.384l-0.639-1.538c-0.178-0.399-0.311-0.611-0.487-1.012l-1.586-1.19L46.878,31.73z"/>
			<path fill="#548235" d="M46.357,26.151l0.261,1.541l0.517,1.385l0.71,1.139l0.864,0.889l1.034,0.66l1.216,0.218l1.229-0.13
	l0.115-1.445l-0.349-1.341l-1.014-1.318c-0.275-0.341-0.458-0.512-0.733-0.852l-1.842-0.739L46.357,26.151z"/>
			<path fill="#548235" d="M44.49,21.438l0.644,1.31l0.832,1.099l0.939,0.829l1.01,0.563l1.1,0.314l1.146-0.13l1.063-0.445l-0.28-1.324
	l-0.67-1.104l-1.261-0.91c-0.337-0.229-0.546-0.333-0.882-0.563l-1.844-0.172L44.49,21.438z"/>
			<path fill="#548235" d="M42.308,15.136l0.646,1.308l0.832,1.101l0.938,0.828l1.012,0.563l1.1,0.314l1.146-0.129l1.064-0.445
	l-0.282-1.324l-0.669-1.104l-1.26-0.91c-0.337-0.23-0.546-0.334-0.882-0.564l-1.844-0.17L42.308,15.136z"/>
			<path fill="#548235" d="M53.768,15l0.713,1.21l0.41,1.232L55,18.605l-0.164,1.067l-0.438,0.997l-0.851,0.783L52.479,22l-0.959-0.949
	L51,19.936l0.027-1.447c0.019-0.38,0.063-0.594,0.082-0.974l1.041-1.471L53.768,15z"/>
			<path fill="#548235" d="M57.874,21.833l0.096,1.454l-0.171,1.367l-0.397,1.188l-0.594,0.993l-0.797,0.821l-1.057,0.462l-1.145,0.146
	l-0.418-1.288l0.027-1.291l0.637-1.417c0.177-0.368,0.307-0.562,0.482-0.93l1.512-1.069L57.874,21.833z"/>
			<path fill="#548235" d="M59.376,29.005l-0.193,1.445l-0.438,1.307l-0.625,1.085l-0.776,0.857l-0.941,0.647l-1.128,0.243l-1.15-0.081
	l-0.154-1.345l0.281-1.262l0.903-1.264c0.245-0.326,0.41-0.491,0.654-0.816l1.693-0.75L59.376,29.005z"/>
			<path fill="#548235" d="M58.401,35.806l-0.462,1.384l-0.677,1.201l-0.816,0.948l-0.925,0.694l-1.049,0.46l-1.151,0.026l-1.114-0.297
	l0.101-1.35l0.515-1.187l1.125-1.07c0.302-0.273,0.495-0.404,0.798-0.679l1.804-0.418L58.401,35.806z"/>
			<path fill="#548235" d="M56.346,42.998l-0.82,1.204l-0.979,0.971l-1.047,0.688l-1.078,0.416l-1.135,0.155l-1.116-0.289l-0.99-0.591
	l0.467-1.271l0.817-0.999l1.375-0.724c0.366-0.181,0.588-0.253,0.953-0.434l1.85,0.092L56.346,42.998z"/>
			<path fill="#548235" d="M53.217,49.934l-1.198,0.831l-1.261,0.557l-1.223,0.27l-1.156,0.003l-1.115-0.261l-0.938-0.669l-0.714-0.906
	l0.89-1.021l1.121-0.641l1.543-0.184c0.406-0.038,0.639-0.026,1.045-0.064l1.694,0.747L53.217,49.934z"/>
			<path fill="#548235" d="M48,54.767l-1.21,0.713l-1.234,0.411L44.394,56l-1.067-0.165l-0.996-0.438l-0.783-0.85L41,53.479
	l0.949-0.959L43.064,52l1.446,0.026c0.38,0.02,0.595,0.063,0.974,0.082l1.472,1.041L48,54.767z"/>
			<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M14.599,20.219
	c-6.366,11.229-2.62,25.608,8.371,32.114c1.729,1.021,3.577,1.812,5.504,2.346"/>
			<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M37.889,60.362
	c-2.771-2.813-6.22-4.866-10.015-5.962"/>
			<path fill="#548235" d="M18.092,37.146l0.644,1.424l0.342,1.438l0.045,1.341L18.9,42.568l-0.491,1.125l-0.889,0.859l-1.094,0.576
	l-0.901-1.136l-0.456-1.308l0.105-1.662c0.039-0.435,0.097-0.678,0.135-1.112l1.117-1.642L18.092,37.146z"/>
			<path fill="#548235" d="M26.125,45.358l1.164,1.043l0.894,1.177l0.584,1.208l0.291,1.205l0.007,1.227l-0.466,1.146l-0.767,0.97
	l-1.284-0.674l-0.946-1.01l-0.574-1.563c-0.142-0.414-0.188-0.659-0.327-1.073l0.356-1.952L26.125,45.358z"/>
			<path fill="#548235" d="M21.64,41.588l0.839,1.317l0.541,1.375l0.235,1.32l-0.047,1.238l-0.327,1.183l-0.757,0.978l-1,0.726
	l-1.055-0.996l-0.639-1.228l-0.13-1.66c-0.022-0.438-0.001-0.687-0.023-1.122l0.872-1.783L21.64,41.588z"/>
			<path fill="#548235" d="M16.666,31.731l0.146,1.556l-0.141,1.471l-0.391,1.284l-0.604,1.082l-0.827,0.904l-1.117,0.526l-1.222,0.192
	l-0.486-1.367l-0.011-1.384l0.639-1.538c0.178-0.399,0.31-0.611,0.486-1.012l1.587-1.19L16.666,31.731z"/>
			<path fill="#548235" d="M17.188,26.151l-0.261,1.541l-0.518,1.385l-0.71,1.139l-0.863,0.888l-1.034,0.66l-1.217,0.219l-1.229-0.131
	l-0.116-1.445l0.349-1.34l1.015-1.319c0.275-0.341,0.459-0.511,0.732-0.851l1.842-0.74L17.188,26.151z"/>
			<path fill="#548235" d="M19.054,21.438l-0.644,1.31l-0.832,1.099l-0.939,0.829l-1.009,0.563l-1.101,0.313l-1.146-0.129l-1.063-0.445
	l0.281-1.323l0.67-1.104l1.26-0.91c0.337-0.229,0.546-0.333,0.882-0.563l1.845-0.17L19.054,21.438z"/>
			<path fill="#548235" d="M20.578,14.846l-0.528,1.358l-0.733,1.166l-0.864,0.906l-0.957,0.648l-1.069,0.407l-1.152-0.029
	l-1.099-0.352l0.167-1.345l0.571-1.158l1.177-1.015c0.315-0.259,0.516-0.379,0.83-0.638l1.823-0.328L20.578,14.846z"/>
			<path fill="#548235" d="M9.925,16L9.39,17.037l-0.308,1.058L9,19.092l0.123,0.915l0.329,0.854l0.637,0.671L10.89,22l0.722-0.814
	l0.39-0.956l-0.021-1.24c-0.014-0.324-0.048-0.509-0.062-0.834l-0.781-1.261L9.925,16z"/>
			<path fill="#548235" d="M5.766,22.052l-0.085,1.292l0.152,1.214l0.354,1.054l0.525,0.884l0.707,0.729l0.939,0.409l1.015,0.13
	l0.371-1.144L9.72,25.473l-0.563-1.258c-0.157-0.327-0.272-0.499-0.429-0.825l-1.344-0.95L5.766,22.052z"/>
			<path fill="#548235" d="M4.168,29.005l0.193,1.445l0.438,1.307l0.625,1.085L6.2,33.699l0.941,0.647L8.27,34.59l1.15-0.082
	l0.155-1.345l-0.282-1.262L8.39,30.638c-0.243-0.326-0.408-0.49-0.654-0.815l-1.693-0.75L4.168,29.005z"/>
			<path fill="#548235" d="M5.143,35.807l0.461,1.383l0.678,1.201l0.816,0.948l0.925,0.695l1.049,0.46l1.151,0.025l1.114-0.297
	l-0.1-1.35l-0.516-1.186l-1.125-1.07c-0.302-0.274-0.493-0.404-0.798-0.68L6.995,35.52L5.143,35.807z"/>
			<path fill="#548235" d="M7.198,42.999l0.82,1.204l0.979,0.971l1.046,0.688l1.079,0.416l1.134,0.155l1.116-0.289l0.991-0.59
	l-0.467-1.271l-0.818-0.998l-1.375-0.725c-0.366-0.181-0.587-0.254-0.953-0.434l-1.85,0.091L7.198,42.999z"/>
			<path fill="#548235" d="M10.327,49.934l1.198,0.831l1.261,0.556l1.223,0.271l1.156,0.002l1.115-0.26l0.938-0.669l0.714-0.906
	l-0.89-1.021l-1.121-0.641l-1.543-0.183c-0.406-0.038-0.639-0.027-1.045-0.065l-1.695,0.747L10.327,49.934z"/>
			<path fill="#548235" d="M15,54.767l1.383,0.712l1.41,0.411L19.122,56l1.22-0.164l1.141-0.439l0.895-0.849l0.624-1.069l-1.085-0.958
	L20.641,52l-1.653,0.025c-0.435,0.021-0.68,0.064-1.111,0.083l-1.682,1.041L15,54.767z"/>
			<path fill="#548235" d="M31.847,28.229c-1.415,0-2.563,1.148-2.563,2.563s1.146,2.563,2.563,2.563s2.563-1.146,2.563-2.563
	S33.262,28.229,31.847,28.229z M31.847,22.855c2.014,0,4.024,0.769,5.561,2.304c3.068,3.068,3.068,8.048,0,11.116l-5.561,5.561
	l-5.559-5.561c-3.07-3.068-3.07-8.048,0-11.116C27.823,23.623,29.835,22.855,31.847,22.855z"/>
		</svg>
	)
}


export function FieldFamily() {
	return (
		<svg
			x="0px" y="0px"
			width="100%" height="100%"
			viewBox="0 0 68 37" enableBackground="new 0 0 68 37">
			<g>
				<path fill="#548235"
				      d="M31,4.5C31,2.015,33.015,0,35.5,0S40,2.015,40,4.5C40,6.983,37.985,9,35.5,9S31,6.984,31,4.5z"/>
				<path fill="#548235"
				      d="M55,11.5C55,9.565,56.567,8,58.5,8S62,9.565,62,11.5c0,1.933-1.567,3.5-3.5,3.5S55,13.433,55,11.5z"/>
				<path fill="#548235" d="M9,11c0-1.657,1.343-3,3-3s3,1.343,3,3c0,1.655-1.343,3-3,3S9,12.655,9,11z"/>
				<path fill="#548235" d="M22,15.408c2.266-0.459,3.304-3.216,5.57-3.675L35.122,11l9.252,2.848L49,12.193l-4.626,4.135l-4.815-0.46
		l-0.564,5.237l4.343,2.205l-1.039,8.636l-2.265-5.788l-5.948-0.827l-2.266,6.064L33.518,37l-6.229-5.329l2.643-8.544l0.567-7.166
		c-0.976-0.275-1.668-1.012-2.644-1.286L22,15.408z"/>
				<path fill="#548235" d="M50,15.236c1.284,0.206,2.829,0.943,4.112,1.149l3.19-0.088h3.566L68,14
		c-1.325,1.296-1.954,1.354-3.279,2.649l-2.46,2.121l-0.694,4.949l4.348,0.265l1.739,6.805l-4.087-3.005l-5.652,0.619
		c-0.928,2.238-3.246,3.063-4.87,4.597l1.827-5.304l0.869-3.977l-0.782-4.33c-0.659-0.619-1.752-0.796-2.41-1.414L50,15.236z"/>
				<path fill="#548235" d="M21,15c-1.718,0.024-2.768,0.762-4.486,0.786l-5.25-0.313l-6.205-0.079L0,17.74l4.677,0.203l3.341,1.313
		l-0.571,5.359l-1.622,2.129l-4.393-1.262l5.061,4.494l3.816-2.365l4.773,2.208L14.318,34l3.914-6.546l-4.011-2.759l-0.382-5.203
		c1.02-0.631,2.8-0.631,3.818-1.262L21,15z"/>
			</g>
		</svg>

	)
}

export function FieldTourism() {
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%"
		     viewBox="0 0 50.333 50.333"
		     enableBackground="new 0 0 50.333 50.333">
			<g>
				<path fill="#548235" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M25.08,0.667
		c13.579,0,24.587,10.969,24.587,24.5c0,13.529-11.008,24.5-24.587,24.5c-1.698,0-3.355-0.171-4.956-0.498l-0.448-0.104
		l-0.646-0.202c-4.326-1.838-7.361-6.142-7.361-11.155c0-6.688,5.396-12.109,12.052-12.109l0.223,0.022l2.193-0.215
		c3.212-0.756,6.046-2.772,7.812-5.681c2.353-3.876,2.437-8.727,0.221-12.683c-1.663-2.969-4.425-5.084-7.609-5.952
		c-1.566-0.303-2.025-0.216-3.04-0.324c-0.37,0.002-0.697,0.019-1.004,0.041l-0.363,0.038l0.418-0.053
		C23.391,0.709,24.231,0.667,25.08,0.667z"/>
				<path fill="#548235" stroke="#548235" strokeMiterlimit="8" d="M30.781,11.662l-0.006,0.063c-0.237,0.954-0.625,1.928-1.171,2.872
		c-2.18,3.774-6.031,5.63-8.604,4.146c-2.573-1.486-2.892-5.75-0.712-9.525c0.544-0.943,1.194-1.767,1.902-2.449l0.304-0.215
		l1.045,2.099c0.632,0.826,1.436,1.545,2.395,2.101c0.96,0.554,1.984,0.891,3.018,1.023L30.781,11.662z"/>
				<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M24.918,15.801
		c-0.937,0.793-2.34,0.677-3.133-0.26c-0.195-0.231-0.342-0.5-0.43-0.79"/>
				<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M21.987,11.989
		c0.557-0.172,1.16,0.043,1.481,0.527"/>
				<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M25.137,12.884
		c0.559-0.171,1.16,0.044,1.483,0.528"/>
				<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M17.233,16.667
		c-1.062-0.52-2.343-0.117-2.917,0.914"/>
				<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M12.398,14.893
		c0.35-0.466,0.964-0.646,1.51-0.442"/>
				<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M15.473,13.767
		c0.352-0.466,0.966-0.646,1.512-0.445"/>
				<path fill="none" stroke="#548235" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M17.249,16.686
		c-0.838-0.892-2.042-0.777-2.689,0.254c-0.127,0.203-0.228,0.436-0.295,0.685"/>
				<path fill="none" stroke="#548235" strokeMiterlimit="8" d="M17.787,8.444L17.83,8.49c0.558,0.81,1.031,1.745,1.377,2.778
		c1.385,4.133,0.224,8.248-2.593,9.192c-2.817,0.942-6.226-1.643-7.608-5.774c-0.347-1.033-0.534-2.063-0.576-3.047l0.041-0.371
		l2.267,0.608c1.037,0.073,2.106-0.055,3.159-0.405c1.05-0.352,1.979-0.896,2.764-1.58L17.787,8.444z"/>
				<path fill="#CAE4AD" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M15.667,42.627l6.733-4.961l11.132,0.122
		l7.135,4.878L15.667,42.627z"/>
				<path fill="#CAE4AD" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M26.958,30.66
		c-0.916,0-1.66,0.741-1.66,1.658c0,0.916,0.744,1.66,1.66,1.66c0.917,0,1.66-0.744,1.66-1.66S27.875,30.66,26.958,30.66z
		 M26.958,27.179c1.303,0,2.605,0.497,3.6,1.491c1.989,1.989,1.989,5.212,0,7.2l-3.6,3.601l-3.6-3.6
		c-1.988-1.989-1.988-5.212,0-7.201C24.351,27.676,25.655,27.179,26.958,27.179z"/>
				<path fill="none" stroke="#E2F0D9" strokeWidth="0.6667" strokeLinecap="round" strokeMiterlimit="8"
				      d="M24.911,15.815
		c-0.668,1.107-1.914,1.381-2.785,0.612c-0.441-0.39-0.72-0.996-0.769-1.673"/>
				<path fill="none" stroke="#548235" strokeWidth="1.3333" strokeMiterlimit="8" d="M0.667,25.167c0-13.531,10.745-24.5,24-24.5
		s24,10.969,24,24.5c0,13.529-10.745,24.5-24,24.5S0.667,38.697,0.667,25.167z"/>
			</g>
		</svg>
	)
}

export function GuidelineCoop() {
	return (
		<div>
			<svg x="0px" y="0px"
			     width="100%" height="100%" viewBox="0 0 33.333 32.333"
			     enableBackground="new 0 0 33.333 32.333">
				<circle cx='16.6' cy='16' r='15' fill='#FAFAFA' strokeWidth='0.0' stroke='none'/>
				<g>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M0.667,16.167c0-8.562,7.164-15.5,16-15.5
		c8.837,0,16,6.938,16,15.5c0,8.56-7.163,15.5-16,15.5C7.831,31.667,0.667,24.726,0.667,16.167z"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M17.038,0.667v7.553l-1.579,0.053l-1.361-1.259
		c-0.681-0.157-1.106-0.162-1.852-0.105l-1.089,0.894L10.72,9.479l-0.055,1.574l0.327,1.206l0.926,0.682l1.361,0.315l1.198-0.577
		l0.545-1.154l1.089-0.053L16.6,11.63v9.747c0.403,0.325,0.775,0.349,1.243,0.341c0.606-0.373,0.77-0.746,1.153-1.119
		c0.526-0.17,1.211,0.024,1.61,0.009l0.729,0.765l0.327,1.744l-0.104,1.266l-1.202,0.78l-1.361-0.131l-0.731-0.795l-0.926-0.036
		l-0.903,0.454v7.014"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M0.667,16.034h5.936
		c0.15,0.069,0.427,0.168,0.48,0.327l-0.008,0.898l-0.474,0.658l-0.322,0.56c-0.048,0.524-0.033,0.959,0.14,1.572
		c0.395,0.342,0.665,0.388,0.998,0.582l1.938,0.033l1.448-0.336l0.604-1.19c0.018-0.271-0.122-0.604-0.104-0.873l-0.706-0.604
		l-0.529-0.437l0.104-0.848l0.859,0.013l11.856-0.06c0.004-0.234,0.01-0.471,0.016-0.704l-0.266-1.102l-0.728-1.013
		c0.056-0.217-0.046-0.463,0.01-0.681c0.041-0.203,0.239-0.381,0.28-0.586c0.234-0.146,0.593-0.396,1.009-0.48
		c0.416-0.084,0.88-0.128,1.33-0.083c0.451,0.044,1.204,0.006,1.624,0.143c0.421,0.138,1.026,0.287,1.056,0.733
		c0.027,1.754-0.846,1.438-1.133,2.15l-0.054,1.577h1.079h5.558"/>
				</g>
			</svg>

		</div>
	)
}

export function GuidelineCreat() {
	return (
		<div>
			<svg x="0px" y="0px"
			     width="100%" height="100%" viewBox="0 0 27.341 37.336"
			     enableBackground="new 0 0 27.341 37.336">
				<circle cx='13.7' cy='13.5' r='12' fill='#FAFAFA' strokeWidth='0.0' stroke='none'/>
				<g>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M10.044,26.153
		c-6.896-2.003-10.861-9.215-8.858-16.11c2.004-6.895,9.218-10.859,16.11-8.856c6.896,2.003,10.861,9.216,8.858,16.11
		c-0.991,3.41-3.335,6.269-6.486,7.906"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M9.701,26.669l7.97,3.119H9.104l8.134,3.644
		H8.67l2.928,1.792l0.217,1.445h2.656v-1.389l1.683-0.405"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round" strokeMiterlimit="8"
					      d="M21.551,15.169H19.17"
					/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round" strokeMiterlimit="8"
					      d="M6.551,15.169H4.17"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round" strokeMiterlimit="8"
					      d="M13.17,6.169V8.55"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round" strokeMiterlimit="8"
					      d="M8.277,9.896
		L6.591,8.212"/>
					<path fill="none" stroke="#404040" strokeWidth="1.6667" strokeLinecap="round" strokeMiterlimit="8"
					      d="M18.556,10.671
		l1.686-1.686"/>
				</g>
			</svg>

		</div>
	)
}

export function GuidelineDivers() {
	return (
		<div>
			<svg x="0px" y="0px"
			     width="100%" height="100%" viewBox="0 0 36.515 33.319"
			     enableBackground="new 0 0 36.515 33.319">
				<circle cx='18' cy='15' r='14' fill='#FAFAFA' strokeWidth='0.0' stroke='none'/>
				<g>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M5.83,21.461
		C2.006,14.722,4.37,6.199,11.11,2.429c1.864-1.043,3.946-1.646,6.087-1.763"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M20.551,1.021
		c7.604,1.541,12.547,8.924,11.047,16.492c-0.227,1.135-0.592,2.234-1.091,3.28"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M29.283,23.007
		c-4.709,6.105-13.515,7.223-19.669,2.495c-0.786-0.604-1.507-1.289-2.149-2.046"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M5.889,2.561l4.753,8.211l-9.487-0.008
		L5.889,2.561z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M30.846,3.145L35.58,9.33l-6.144,4.702
		l-4.734-6.186L30.846,3.145z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M16.192,32.034
		c-2.189-1.264-2.946-4.052-1.691-6.227c1.256-2.174,4.048-2.912,6.237-1.648c2.188,1.265,2.946,4.053,1.689,6.228
		C21.173,32.561,18.381,33.299,16.192,32.034z"/>
				</g>
			</svg>
		</div>
	)
}

export function GuidelinePart() {
	return (
		<div>
			<svg x="0px" y="0px"
			     width="100%" height="100%" viewBox="0 0 35 35"
			     enableBackground="new 0 0 35 35">
				<circle cx='17' cy='18' r='17' fill='#FAFAFA' strokeWidth='0.0' stroke='none'/>
				<g transform="rotate(-90, 18, 17)">
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M9.113,16.705l-1.251,1.356
		c-0.635,0.688-1.703,0.732-2.393,0.101l0,0c-0.687-0.635-0.729-1.703-0.098-2.393l1.25-1.356"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M10.802,19.895l-2.387,2.593
		c-0.633,0.688-1.704,0.732-2.393,0.101l0,0c-0.688-0.633-0.73-1.704-0.099-2.393l2.387-2.593"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M13.694,21.749l-3.263,3.542
		c-0.633,0.687-1.703,0.729-2.391,0.099l0,0c-0.688-0.633-0.732-1.704-0.101-2.391l3.263-3.543"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M16.118,24.019l-2.744,2.979
		c-0.622,0.676-1.675,0.719-2.353,0.097l0,0c-0.677-0.623-0.72-1.676-0.098-2.352l2.425-2.636"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M13.67,28.323v2.89"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M22.697,0.323L22.67,6.122"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M23.259,5.802
		c3.748,2.805,5.292,7.399,3.915,11.651"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M23.67,26.323v4.452"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M19.74,9.997
		c5.644,1.939,8.396,7.465,6.149,12.34c-0.595,1.291-1.511,2.453-2.682,3.405"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M13.67,0.323v3.022"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M6.799,5.423
		c0.443-0.822,1.469-1.13,2.292-0.688l2.852,1.535c0.821,0.443,1.131,1.469,0.688,2.292l0,0c-0.443,0.822-1.469,1.13-2.292,0.688
		l-2.85-1.535C6.665,7.271,6.356,6.246,6.799,5.423z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M5.024,8.512
		c0.442-0.823,1.47-1.131,2.291-0.688l2.852,1.534c0.823,0.443,1.131,1.471,0.688,2.292l0,0c-0.441,0.823-1.469,1.133-2.292,0.688
		l-2.851-1.535C4.888,10.359,4.581,9.335,5.024,8.512z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M4.032,11.896
		c0.441-0.822,1.469-1.131,2.292-0.688l1.625,0.875c0.821,0.441,1.131,1.47,0.688,2.292l0,0c-0.441,0.823-1.469,1.131-2.292,0.688
		l-1.626-0.875C3.896,13.745,3.588,12.719,4.032,11.896z"/>
					<path fill="#FFFFFF" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M10.063,3.244
		c0.443-0.822,1.471-1.13,2.292-0.688l1.625,0.875c0.823,0.443,1.133,1.469,0.688,2.292l0,0c-0.443,0.823-1.469,1.131-2.292,0.688
		l-1.625-0.876C9.928,5.093,9.621,4.065,10.063,3.244z"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M18.323,15.175
		c-1.3-0.468-1.974-1.901-1.504-3.2c0.424-1.177,1.651-1.857,2.875-1.596"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8"
					      d="M16.67,24.323l4-5.218v-3.851l-2.159-0.934"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M23.571,0.614
		c8.397,3.533,12.343,13.209,8.808,21.607c-1.654,3.93-4.765,7.068-8.682,8.755"/>
					<path fill="none" stroke="#595959" strokeWidth="1.3333" strokeMiterlimit="8" d="M12.888,30.972
		C4.522,29.159-0.791,20.909,1.021,12.541C2.313,6.582,6.978,1.933,12.943,0.663"/>
				</g>
			</svg>

		</div>
	)
}


export function Bullet() {
	return (
		<div>
			<svg x="0px" y="0px" width="10px" height="10px" viewBox="0 0 44.25 44.125">
				<radialGradient id="SVGID_1_" cx="46.2324" cy="37.3105" r="22.2098"
				                gradientTransform="matrix(1 0 0 -1 -20.9902 47.5254)" gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#FFFFFF"/>
					<stop offset="1" stopColor="#5EBD00"/>
				</radialGradient>
				<path fill="url(#SVGID_1_)" stroke="#000000" strokeWidth="0.5" strokeMiterlimit="10" d="M43.154,22.089
	c0,11.46-9.445,20.759-20.898,20.759c-11.46,0-20.901-9.299-20.901-20.759c0-11.454,9.441-20.756,20.901-20.756
	C33.709,1.333,43.154,10.635,43.154,22.089z"/>
				<radialGradient id="SVGID_2_" cx="47.6152" cy="37.1504" r="32.4084"
				                gradientTransform="matrix(1 0 0 -1 -20.9902 47.5254)" gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#FFFFFF"/>
					<stop offset="1" stopColor="#153801"/>
				</radialGradient>
				<path opacity="0.44" fill="url(#SVGID_2_)" stroke="#000000" strokeMiterlimit="10"
				      enableBackground="new    "
				      d="M43.154,22.089
	c0,11.472-9.271,20.759-20.898,20.759S1.354,33.561,1.354,22.089c0-11.471,9.272-20.756,20.901-20.756S43.154,10.618,43.154,22.089z
	"/>
				<radialGradient id="SVGID_3_" cx="46.2324" cy="37.3105" r="22.2098"
				                gradientTransform="matrix(1 0 0 -1 -20.9902 47.5254)" gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#FFFFFF"/>
					<stop offset="1" stopColor="#2B5402"/>
				</radialGradient>
				<path opacity="0.15" fill="url(#SVGID_3_)" stroke="#000000" strokeWidth="0.5" strokeMiterlimit="10" d="M43.154,22.089
	c0,11.46-9.445,20.759-20.899,20.759c-11.459,0-20.901-9.299-20.901-20.759c0-11.454,9.441-20.756,20.901-20.756
	C33.709,1.333,43.154,10.635,43.154,22.089z"/>
			</svg>
		</div>
	)
}





export function FocusPast() {
	const hovered = false;
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%" viewBox="0 0 140 40">
			<text transform="matrix(1 0 0 1 0 20)" fontFamily="'Abel-Regular'"
			      fontWeight={hovered === 10 ? 'bolder' : 'normal'}
			      fontSize={hovered === 10 ? "15.0" : "15"}>
				DAS KULTURELLE
			</text>
			<text transform="matrix(1 0 0 1 0 37)" fontFamily="'Abel-Regular'"
			      fontWeight={hovered === 10 ? 'bolder' : 'normal'}
			      fontSize={hovered === 10 ? "15.0" : "15"}>
				ERBE PFLEGEN
			</text>
		</svg>
	)
}

export function FocusPresence() {
	const hovered = false;
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%" viewBox="0 0 140 40">
			<text transform="matrix(1 0 0 1 0 20)" fontFamily="'Abel-Regular'"
			      fontWeight={hovered === 10 ? 'bolder' : 'normal'}
			      fontSize={hovered === 10 ? "15.0" : "15"}>
				GEMEINSAM KUNST
			</text>
			<text transform="matrix(1 0 0 1 0 37)" fontFamily="'Abel-Regular'"
			      fontWeight={hovered === 10 ? 'bolder' : 'normal'}
			      fontSize={hovered === 10 ? "15.0" : "15"}>
				UND KULTUR LEBEN
			</text>
		</svg>
	)
}

export function FocusFuture() {
	const hovered = false;
	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%" viewBox="0 0 140 40">
			<text transform="matrix(1 0 0 1 0 20)" fontFamily="'Abel-Regular'"
			      fontWeight={hovered === 10 ? 'bolder' : 'normal'}
			      fontSize={hovered === 10 ? "15.0" : "15"}>
				KUNST &amp; KULTUR FÜR
			</text>
			<text transform="matrix(1 0 0 1 0 37)" fontFamily="'Abel-Regular'"
			      fontWeight={hovered === 10 ? 'bolder' : 'normal'}
			      fontSize={hovered === 10 ? "15.0" : "15"}>
				DIE ZUKUNFT SCHAFFEN
			</text>
		</svg>
	)
}


export function HappyLittleTree() {

	const fillcolor = 'none'

//	const strokeColor = '#548235'
	const strokeColor = '#595959'

//	const ColorStem = '#996633'
	const ColorStem = '#595959'

//	const ColorArrows = '#548235'
	const ColorArrows = '#595959'

	const strokeWidth = 15

	return (
		<div>
			<svg
				width="100%" height="100%" viewBox="0 0 778.965 586.191"
			>
				{/* Wien*/}
				<path fill={fillcolor} stroke={strokeColor} strokeWidth={strokeWidth/3} strokeMiterlimit="8" d="
				M497.971,250.224l-7.572,0.408l-1.84,6.541
	l-20.473,6.217l1.637,5.473l-3.377,5.722l14.533,7.521l4.707-3.271l10.236,2.863l8.191-5.722l4.705,0.082l3.578,3.837h4.709v-3.759
	l-4.709-2.779l-0.104-14.222l-5.32-1.471l1.533-2.205l-4.813-0.732l-2.354,2.045l-2.561-2.129L497.971,250.224L497.971,250.224z
	"></path>
				{/*Rest*/}
				<path fill={fillcolor} stroke={strokeColor} strokeWidth={strokeWidth} strokeMiterlimit="8" d="
	 M275.943,118.894l27.077,2.232l4.046,9.699l13.068-7.708l72.514,25.854l9.337-4.476l20.852,7.71l-1.559,7.456l22.408,8.95
	l8.404-3.73l38.902,7.215l4.047-12.432l42.014,3.482l0.621,9.695l12.764,3.979l14.314-3.729l10.271,5.719l8.402,34.309
	l-24.275,25.115l21.787,17.151l-0.623,17.65l16.188,6.716l-11.203,24.857l-2.488,0.244v-4.223l-16.18-4.227l-24.9,8.449l2.178,6.717
	l-23.652,16.9l-16.498-11.686l-23.959,39.281l9.332,3.729l4.363,15.164l-12.762,12.434l4.361,11.432l-17.125,14.92l-16.498-3.23
	l-4.045-12.184l-19.918,0.25l-0.625-4.729l-19.91-8.205l3.112-6.711l-19.917-4.727l-5.291-13.424l-40.459-10.445l-2.803-8.949
	l-15.252-0.742l-2.489,5.719l-10.268-1.99l-0.934,8.951l-77.185-1.236l-4.979-18.148l11.515-4.73l-7.47-9.197l-10.269,1.49
	l-32.995-21.629l7.473-38.282l12.449,1.24l1.245,7.211l34.232,2.238l7.158-9.199l15.873,1.244l6.847-12.432l-13.693-3.977
	l7.158-10.441l-16.184-16.905l9.026-7.455l-14.006-7.457l-9.025,3.729l-9.959-7.208l1.868-18.646l13.071-8.704l1.556-13.676
	l24.587,4.724l3.112-4.222l-7.158-9.448l7.468-10.938l-3.734-11.936L275.943,118.894L275.943,118.894z"/>

				<path fill={ColorStem} d="M517.985,460.99l-48.662,5.379l-37.594-7.377v-47.725h-18.243v55.004l55.181,10.66l0.375-0.041
	l26.543,18.629c1.115,0.783,9.238-7.08,7.969-7.973l-17.813-12.502l33.867-3.744L517.985,460.99z"/>
				<g>
					<path fill={ColorStem} d="M359.079,364.16l-18.725,0.086l-0.016,95.156l-32.834,5.41l-28.821-4.512l-28.644,4.5l2.722,8.898
		l27.65-5.031l5.938,3.5l-24.733,8.861l3.707,8.018c0,0,42.288-15.201,42.542-15.314l51.093-7.729L359.079,364.16z"/>
					<path fill={ColorStem} d="M439.381,489.062l-30.931-8.92l-13.717-11.803c0,0-0.234-60.324-0.236-78.59h-18.362l-0.275,81.41
		l-10.69,10.564l-35.244,4.045l1.685,10.305l39.36-4.52l15.779-13.133l15.154,10.699l-8.2,6.414l8.468,7.602l12.898-10.086
		l20.275,5.846L439.381,489.062z"/>
				</g>
				<g>
					<g>
						<polygon fill="none" points="438.467,373.949 429.706,373.949 429.706,340.252 500.417,245.093 500.417,225.576
			509.178,225.576 509.178,247.177 438.467,342.336 		"/>
						<g>
							<polygon fill="none"
							         points="513.485,227.719 504.75,215.04 496.012,227.719 			"/>
						</g>
					</g>
				</g>
				<g>
					<polygon fill="none" points="353.487,338.59 344.727,338.59 344.727,322.06 271.714,310.896 271.714,294.09 280.477,294.09
		280.477,304.775 353.487,315.937 	"/>
					<g>
						<polygon fill="none" points="284.784,296.232 276.047,283.554 267.311,296.232 		"/>
					</g>
				</g>
				<g>
					<g>
						<polygon fill="none" points="383.268,349.103 374.505,349.103 374.505,316.547 316.096,257.517 316.096,206.73 324.856,206.73
			324.856,254.87 383.268,313.9 		"/>
						<g>
							<polygon fill="none"
							         points="329.164,208.871 320.43,196.194 311.69,208.871 			"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<polygon fill="none" points="410.099,361.484 401.336,361.484 401.336,331.89 415.936,282.187 415.936,249.697
			424.698,249.697 424.698,285.983 410.099,335.685 		"/>
						<g>
							<polygon fill="none"
							         points="429.004,251.837 420.27,239.159 411.533,251.837 			"/>
						</g>
					</g>
				</g>
				<g transform="rotate(180, 103, 478)" opacity="0.3">
					<line fill={ColorArrows} x1="103.397" y1="394.594" x2="103.397" y2="563.42"/>
					<g>

						<line fill="none" stroke={ColorArrows} strokeWidth="16" strokeMiterlimit="8" x1="103.397"
						      y1="394.594"
						      x2="103.397" y2="494.527"/>
						<g>
							<polygon fill={ColorArrows} points="55.8,480.521 103.66,563.42 151.528,480.521 			"/>
						</g>
					</g>
				</g>
				<g transform="rotate(180, 670, 478)" opacity="0.3">
					<line fill={ColorArrows} x1="670.395" y1="394.595" x2="670.395" y2="563.42"/>
					<g>

						<line fill="none" stroke={ColorArrows} strokeWidth="16" strokeMiterlimit="8" x1="670.395"
						      y1="394.595"
						      x2="670.395" y2="494.527"/>
						<g>
							<polygon fill={ColorArrows}
							         points="622.798,480.521 670.658,563.42 718.526,480.521 			"/>
						</g>
					</g>
				</g>
			</svg>
		</div>
	)
}

export function Burger(props) {

	return (
		<svg x="0px" y="0px"
		     width="100%" height="100%" viewBox="0 0 823 600">
			<rect x="155" y="122" fill={props.arg ? 'darkred' : "#8DD050"} width="549" height="28"/>
			<rect x="155" y="286" fill={props.arg ? 'darkred' : "#8DD050"} width="549" height="28"/>
			<rect x="155" y="450" fill={props.arg ? 'darkred' : "#8DD050"} width="549" height="28"/>
		</svg>
	)

}

export function download(props) {

	return (
		<svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 84 81.333">
			<polygon fill="#0071BC" points="76,74.667 8,74.667 8,52.667 15,52.667 15,67.667 69,67.667 69,52.667 76,52.667 "/>
			<g id="downloadIcon"><g><polygon fill="#0071BC" points="73.158,36.492 57.754,36.492 57.754,7.873 26.178,7.873 26.178,36.007 10.777,36.007 41.583,58.893"/></g></g>
		</svg>

	)

}


export function Arrow(props) {

	return (
		<svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 50 37">
			<path
				d="m 47.2143,29.428576 h -27.85 v 8 l -15.15,-16 15.15,-16.0000005 v 8.0000005 h 27.85 z"
				fill="#ff8c1c"></path>
		</svg>

	)

}

