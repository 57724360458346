import React, {useState, useRef, useEffect} from 'react'
import {useSpring, useSprings, a, config} from '@react-spring/web';
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import * as d3 from "d3";

import {useStore} from "./store";
import {HappyLittleTree} from "./icons";
import {Bullet, IconFetcher} from "./icons";
import Longtext from "./longtext";
import {contentMore} from "../assets/content_more";
import {useWindowDimensions} from "../utils/hooks";
import logo100 from "../ressources/100_Jahre_NOE.png";

function More() {

//	console.log("------ More -----");

	const hovered = useStore(state => state.hovered);

	var field = contentMore.find((d) => d.id === hovered);
	var longdesc = field ? field.longdesc : [];
	var normdesc = field ? field.normdesc : [];
	var infobox = field ? field.infobox : [];

	const refVis = useRef([]);

	const [isOpen, setIsOpen] = useState(false);

	const setMoreInfo = useStore(state => state.setMoreInfo);
	const moreInfo = useStore(state => state.moreInfo);
	const [anim, setAnim] = useState(false);
	const [toggle, setToggle] = useState(false);

	const [previous, setPrevious] = useState(-1)

	const executeScroll = (d) => {
//		console.log("scrolling", d)
		if (refVis.current[d])  {refVis.current[d].scrollIntoView({behavior: 'smooth', block: 'start'})}
	}

	useEffect(() => {
		if (moreInfo > -1) {
			setPrevious(moreInfo)
			setTimeout(()=>executeScroll(moreInfo), 10)
		}
		if (moreInfo === -1 && previous > -1) {
			setTimeout(()=>executeScroll(previous), 10)
			setTimeout(()=>setPrevious(-1), 2000)
		}
	}, [moreInfo])


	useEffect(() => {
		setToggle(hovered <= 4 ? true : false);
		setAnim(-1)
		setMoreInfo(-1)
	}, [hovered])


	const {o1} = useSpring({
		to: {o1: anim > -1 && refVis.current[anim] ? refVis.current[anim].getBoundingClientRect().height : 0},
//		delay: moreInfo > -1 ? 1700 : 700,
		config: config.default,
		onRest: () => setIsOpen(!isOpen),
	})

	const {o2} = useSpring({
		to: {o2: anim > -1 ? 1 : 0},
		config: config.default,
	})


	const [springs, set, stop] = useSprings(5, index => ({
		opacity: 1,
	}))

	useEffect(() => {
		if (toggle) {
			set(index => ({opacity: 0}))
		}
	}, [toggle])


	const {height, width} = useWindowDimensions();
	const [offset, setOffset] = useState('-3vh');

	const scaleOffset = d3.scaleLinear()
		.domain([600, 900, 1200])
		.range([70, 85, 85])

	useEffect(()=>{
		setOffset(scaleOffset(height) + 'vh');
	}, [width, height])


	return (
		<div className={'XX1'}>
			<Footer></Footer>
			{/*<Header></Header>*/}
			{
				field && hovered <= 16 && hovered >= 12  &&
				<div className='mobileTextContainer'>
					<div className={'contentIntro'} style={{height:offset}}>
					<div className={'HeadingContainer'}>
						<div className={'headings'}>
							{ hovered === 12 &&
								<div width={width/4}>
									<img src={logo100} className={'mobileLogo100'} alt={"Logo 100 Jahre Niederösterreich"}></img>
								</div>
							}
							{/*{field.heading.length > 0 && <div className={'heading'}>{field.heading}</div>}*/}
							{field.subheading.length > 0 && <div className={'subheading'}>{field.subheading}</div>}
						</div>
						<div className={'mobileicon'} style={{width: parseInt(field.iconwidth)*4+'vw'}}><IconFetcher icon={field.icon}/></div>
					</div>
					{normdesc && normdesc.length > 0 && normdesc.map((desc, i) => {
							return (
								<div className={'descContainer'} key={'normdesc' + i}>
									{desc.normdescHeader && <div className={'normdescHeader'}>
										{parse(DOMPurify.sanitize(desc.normdescHeader))}
									</div>}
									<div className={'normdescText'}>
										{desc.normdescText.split('\n').map((line, ii, arr) => {
											let key = 'normpmo' + ii + '_' + i;
											const lineOut = <span className='normdescText'
											                      key={i}>{parse(DOMPurify.sanitize(line))}</span>;
											return <div className={'normdescPar'} key={key}>{lineOut}</div>;
										})}
									</div>
									{desc.normdescDownload &&
										<a className="link" href="KulturSTRe_151121_xs.pdf" download target="_blank">
											<div className="downloadLine">
												<span className="downloadIcon">
													<svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 84 81.333">
														<polygon fill="#0071BC" points="76,74.667 8,74.667 8,52.667 15,52.667 15,67.667 69,67.667 69,52.667 76,52.667 "/>
														<g id="downloadIcon"><g><polygon fill="#0071BC" points="73.158,36.492 57.754,36.492 57.754,7.873 26.178,7.873 26.178,36.007 10.777,36.007 41.583,58.893"/></g></g>
													</svg>
												</span>
												<span><b>Download: NÖ Kulturstrategie 2021 (pdf, 1.8 MB)</b></span>
											</div>
										</a>
									}
								</div>
							)
						})
					}
					{longdesc && longdesc.length > 0 && longdesc.map((desc, i) => {
						let key = "hov_"+hovered+"item_"+i;
						return (
							<div className={'descContainer'} key={'longdesc' + i}>
								<div ref={el => refVis.current[i] = el}
								     className={'descHeader'}>{desc.descHeader ? desc.descHeader : ''}</div>
								<Longtext key={key} val={desc.descText ? desc.descText : ''}
								          new={1} index={i} wasActive={previous === i ? previous : -1}/>
							</div>
						)
					})
					}
					{infobox && infobox.length > 0 && <div className={'Infobox'}>
						{infobox.map((item, i) => {
							let index = i + longdesc.length;
							return (
								<div key={'ii' + index} className={'InfoboxItem'}>
									<div className={'InfoboxItemHeader'}>
										<div className={'bullet'}><Bullet/></div>
										<div className={'InfoboxItemTitle'}>
											{item.InfoboxItemTitle}
										</div>
									</div>
									<div className={'InfoboxItemSubtext'}>
										{item.InfoboxItemSubtext}
										{moreInfo !== index &&
											<span className={'link'} onClick={() => {
												setAnim(index);
												setTimeout(() => {
													setMoreInfo(index);
												}, 10)
											}}
											>
												[mehr]
											</span>
										}
									</div>
									<a.div width='auto' style={{
										overflow: 'hidden',
										opacity: moreInfo === index ? o2 : 0,
										height: o1.to(h => moreInfo === index ? h + 'px' : '0px'),
									}}
									>
										{/*<div style={{overflow: 'hidden', height: moreInfo === index ? o : '0%'}}>*/}
										{/*{moreInfo === index &&*/}
										<div ref={el => refVis.current[index] = el} className={'InfoboxDesc'}>
											{item.InfoboxDesc.split('\n').map((line, ii, arr) => {
												let key = 'pmo' + ii + '_' + i;
												const lineOut = <span className='descText'
												                      key={i}>{parse(DOMPurify.sanitize(line))}</span>;
												return <div className={'descPar'} key={key}>{lineOut}</div>;
											})}
										</div>
										{/*}*/}
									</a.div>
									<div key={'tw' + index} className={'InfoboxItemSubtext'}>
										{moreInfo === index &&
											<span className={'link'} onClick={() => {
												setAnim(-1);
												// wait a little bit to show div collapsing
												// ToDo: make this dependent in 'isOpen'
												setTimeout(() => {
													setMoreInfo(-1)
												}, 400)
											}}
											>
												{anim > -1 ? '[weniger]' : ''}
											</span>}
									</div>
								</div>
							)
						})}
					</div>
					}
					</div>
				</div>
			}
		</div>
	);
}

function Footer() {

	const [toggle, setToggle] = useState(false);

	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);

	const iconconfig = {
		mass: 5.5,
		tension: 300,
		friction: 42,
		precision: 0.001,
		velocity: 0.01
	}

	function hoverarc() {
//		d3.select("#moCircle").selectAll("path").attr("fill", "green").attr("opacity", 0)
//		d3.selectAll("path").filter(function () {return d3.select(this).attr("id") === "arc" + hovered})
//			.attr("fill", "#548235")
//			.attr("opacity", 0.8)
	}

	const {animSurface} = useSpring({
		to: {animSurface: !toggle ? -150 : -30},
//		config: {duration: 1000},
		config: config.wobbly,
	})


	const {i1} = useSpring({to: {i1: toggle ? '9vh' : '-12vh',}, delay: 300, config: iconconfig,})
	const {i2} = useSpring({to: {i2: toggle ? '11vh' : '-12vh',}, delay: 400, config: iconconfig,})
	const {i3} = useSpring({to: {i3: toggle ? '10vh' : '-12vh',}, delay: 500, config: iconconfig,})
	const {i4} = useSpring({to: {i4: toggle ? '12vh' : '-12vh',}, delay: 600, config: iconconfig,})
	const {i5} = useSpring({to: {i5: toggle ? '9vh' : '-12vh',}, delay: 700, config: iconconfig,})
	const {back} = useSpring({to: {back: toggle ? 1 : 0,}, delay: 1200, config: {duration: 1000}})

	useEffect(() => {
		const arcAngles = [
			{id: 0, start: -19, stop: -13},
			{id: 3, start: -11, stop: -6},
			{id: 4, start: -3, stop: +2},
			{id: 2, start: 4, stop: 9},
			{id: 1, start: 12, stop: 18},
		]

		arcAngles.map((d, i) => {
			var arc = d3.arc().innerRadius(145).outerRadius(146)
				.startAngle(d.start * (Math.PI / 180))
				.endAngle(d.stop * (Math.PI / 180));

			d3.select("#moCircle1").append("g")
				.append("path")
				.attr("id", "arc" + d.id)
				.attr("class", "arc")
				.attr("d", arc)
				.attr("opacity", 0)
				.attr("fill", "green")
				.attr("transform", "translate(50,175)");
		})
	}, [])

	useEffect(() => {
//			setToggle(hovered === 4 ? true : false);
		setToggle(hovered >= 12 && hovered <= 16 ? true : false);
//		console.log("hovered", hovered);
		hoverarc();
	}, [hovered])

	return (
		<div className={'mobileFooter'} style={{height: '300px'}}>
			<div>
				{/*<a.div className={'moCircle1'} style={{bottom: animSurface, pointerEvents: 'none'}}>*/}
				{/*	<svg*/}
				{/*		id="moCircle1" x="0px" y="0px"*/}
				{/*		width="100%"*/}
				{/*		height="100%"*/}
				{/*		viewBox="0 0 100 60">*/}
				{/*		/!*viewBox="0 0 300 360">*!/*/}

				{/*		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse"*/}
				{/*		                x1="50" y1="50" x2="50" y2="5">*/}
				{/*			<stop offset="0" stopColor='#FFFFFF' stopOpacity={1}/>*/}
				{/*			<stop offset="0.7" stopColor='#FFFFFF' stopOpacity={1}/>*/}
				{/*			<stop offset="1" stopColor='#FFFFFF' stopOpacity={0}/>*/}
				{/*		</linearGradient>*/}
				{/*		<rect x="0" y="5" fill="url(#SVGID_1_)" stroke="'#0000FF'" strokeWidth={1}*/}
				{/*		      strokeMiterlimit="10" width="300" height="50"*/}
				{/*		/>*/}

				{/*		<circle cx='50' cy='175' r='150' strokeWidth='0.3' stroke='#548235'*/}
				{/*		        fill='#CAE4AD'></circle>*/}
				{/*	</svg>*/}
				{/*</a.div>*/}
				{/*<a.div className={'fieldIcon1'} style={{bottom: i1}}*/}
				{/*       onClick={() => setHovered(0)}><FieldFamily/></a.div>*/}
				{/*<a.div className={'fieldIcon2'} style={{bottom: i2}}*/}
				{/*       onClick={() => setHovered(3)}><FieldTourism/></a.div>*/}
				{/*<a.div className={'fieldIcon3'} style={{bottom: i3}}*/}
				{/*       onClick={() => setHovered(4)}><FieldModel/></a.div>*/}
				{/*<a.div className={'fieldIcon4'} style={{bottom: i4}}*/}
				{/*       onClick={() => setHovered(2)}><FieldClimate/></a.div>*/}
				{/*<a.div className={'fieldIcon5'} style={{bottom: i5}}*/}
				{/*       onClick={() => setHovered(1)}><FieldDigit/></a.div>*/}
				{/*XXX*/}
				<a.div className={'backButton'} style={{opacity: back}}
				       onClick={() => setHovered(99)}><HappyLittleTree/></a.div>
				{/*<a.div className={'nextButton'} style={{opacity: back}}*/}
				{/*       onClick={() => setHovered(99)}>*/}
				{/*	/!*<NextButton/>*!/*/}
				{/*		weiter*/}
				{/*</a.div>*/}
			</div>
		</div>
	)
}


export default More;
