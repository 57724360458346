import React, {useState, useRef, useEffect} from 'react'
import {useSpring, useSprings, a, config} from '@react-spring/web';
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import * as d3 from "d3";

import {useStore} from "./store";
import {HappyLittleTree} from "./icons";
import {Bullet, FieldFamily, FieldClimate, FieldDigit, FieldTourism, IconFetcher, FieldModel} from "./icons";
import Longtext from "./longtext";
import {contentFields} from "../assets/content_fields";
import {useWindowDimensions} from "../utils/hooks";

function Fields() {

//	console.log("------ Fields -----");

	const hovered = useStore(state => state.hovered);

	var field = contentFields.find((d) => d.id === hovered);
	var longdesc = field ? field.longdesc : [];
	var infobox = field ? field.infobox : [];

	const refVis = useRef([]);

	const [isOpen, setIsOpen] = useState(false);

	const setMoreInfo = useStore(state => state.setMoreInfo);
	const moreInfo = useStore(state => state.moreInfo);
	const [anim, setAnim] = useState(false);
	const [toggle, setToggle] = useState(false);

	// Add a hook to make all links open a new window
	DOMPurify.addHook('afterSanitizeAttributes', function (node) {
		// set all elements owning target to target=_blank
		if ('target' in node) {
			node.setAttribute('target', '_blank');
		}
		// set non-HTML/MathML links to xlink:show=new
		if (
			!node.hasAttribute('target') &&
			(node.hasAttribute('xlink:href') || node.hasAttribute('href'))
		) {
			node.setAttribute('xlink:show', 'new');
		}
	});


	useEffect(() => {
		setToggle(hovered <= 4 ? true : false);
		setAnim(-1)
		setMoreInfo(-1)
	}, [hovered])


	const {o1} = useSpring({
		to: {o1: anim > -1 && refVis.current[anim] ? refVis.current[anim].getBoundingClientRect().height : 0},
//		delay: moreInfo > -1 ? 1700 : 700,
		config: config.default,
		onRest: () => setIsOpen(!isOpen),
	})

	const {o2} = useSpring({
		to: {o2: anim > -1 ? 1 : 0},
		config: config.default,
	})


	const [springs, set, stop] = useSprings(5, index => ({
		opacity: 1,
	}))

	useEffect(() => {
		if (toggle) {
			set(index => ({opacity: 0}))
		}
	}, [toggle])


	const {height, width} = useWindowDimensions();
	const [offset, setOffset] = useState('-3vh');

	const height1 = useStore(state => state.height1)
	const heightHead = useStore(state => state.heightHead)

	const scaleOffset = d3.scaleLinear()
		.domain([600, 900])
		.range([62, 72])

	useEffect(()=>{
		setOffset(scaleOffset(height) + 'vh');
	}, [width, height])

//	console.log("h1=", height1);
//	console.log("hHead=", heightHead);

	return (
		<div className={'XX'}>
			<Footer></Footer>
			{/*<Header></Header>*/}
			{
				field && hovered <= 4 &&
				<div className='mobileTextContainer'>
					{/*<div className={'content'} style={{height: offset}}>*/}
					<div className={'content'} style={{height: (height1-heightHead-heightHead/5)+'px'}}>
					<div className={'HeadingContainer'}>
						<div className={'mobileheadings'}>
							<div className={'heading'}>{field.heading}</div>
							<div className={'subheading'}>{field.subheading}</div>
						</div>
						<div className={'mobileicon'} style={{width: parseFloat(field.iconwidth)*1.3+'vmax'}}><IconFetcher icon={field.icon}/></div>
					</div>
					{longdesc && longdesc.length > 0 && longdesc.map((desc, i) => {
						let key = "hov_"+hovered+"item_"+i;
						return (
							<div className={'descContainer'} key={'longdesc' + i}>
								<div ref={refVis[i]}
								     className={'descHeader'}>{desc.descHeader ? desc.descHeader : ''}</div>
								<Longtext key={key} val={desc.descText ? desc.descText : ''} new={1} index={i}/>
							</div>
						)
					})
					}
					{infobox && infobox.length > 0 && <div className={'Infobox'}>
						{infobox.map((item, i) => {
							let index = i + longdesc.length;
							return (
								<div key={'ii' + index} className={'InfoboxItem'}>
									<div className={'InfoboxItemHeader'}>
										<div className={'bullet'}><Bullet/></div>
										<div className={'InfoboxItemTitle'}>
											{item.InfoboxItemTitle}
										</div>
									</div>
									<div className={'InfoboxItemSubtext'}>
										{item.InfoboxItemSubtext}
										{moreInfo !== index &&
											<span className={'link'} onClick={() => {
												setAnim(index);
												setTimeout(() => {
													setMoreInfo(index);
												}, 10)
											}}
											>
												[mehr]
											</span>
										}
									</div>
									<a.div width='auto' style={{
										overflow: 'hidden',
										opacity: moreInfo === index ? o2 : 0,
										height: o1.to(h => moreInfo === index ? h + 'px' : '0px'),
									}}
									>
										{/*<div style={{overflow: 'hidden', height: moreInfo === index ? o : '0%'}}>*/}
										{/*{moreInfo === index &&*/}
										<div ref={el => refVis.current[index] = el} className={'InfoboxDesc'}>
											{item.InfoboxDesc.split('\n').map((line, ii, arr) => {
												let key = 'pmo' + ii + '_' + i;
												const lineOut = <span className='descText'
												                      key={i}>{parse(DOMPurify.sanitize(line))}</span>;
												return <div className={'descPar'} key={key}>{lineOut}</div>;
											})}
										</div>
										{/*}*/}
									</a.div>
									<div key={'tw' + index} className={'InfoboxItemSubtext'}>
										{moreInfo === index &&
											<span className={'link'} onClick={() => {
												setAnim(-1);
												// wait a little bit to show div collapsing
												// ToDo: make this dependent in 'isOpen'
												setTimeout(() => {
													setMoreInfo(-1)
												}, 400)
											}}
											>
												{anim > -1 ? '[weniger]' : ''}
											</span>}
									</div>
								</div>
							)
						})}
					</div>
					}
					</div>
				</div>
			}
		</div>
	);
}

function Footer() {


	const [toggle, setToggle] = useState(false);
	const [immediate, setImmediate] = useState(false);

	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);

	const {height, width} = useWindowDimensions();
	const [offset, setOffset] = useState('-3vh');

	const heightHead = useStore(state => state.heightHead);
	const height1 = useStore(state => state.height1);

	const myref = useRef();

	const scaleOffset = d3.scaleLinear()
		.domain([600, 700, 900, 1400])
		.range([-6.5, -5, -3, -3])

	useEffect(()=>{
//		console.log("new height", height, scaleOffset(height))
		setOffset(scaleOffset(height) + 'vh');
		setTimeout (()=> {
//			console.log(myref.current ? myref.current.getBoundingClientRect() : '')
		}, 3000)
	}, [toggle, width, height])

	const iconconfig = {
		mass: 5.5,
		tension: 300,
		friction: 42,
		precision: 0.001,
		velocity: 0.01
	}

	function hoverarc() {
		d3.select("#moCircle").selectAll("path").attr("fill", "green").attr("opacity", 0)
		d3.selectAll("path").filter(function () {return d3.select(this).attr("id") === "arc" + hovered})
			.attr("fill", "#548235")
			.attr("opacity", 0.8)
	}

	const {animSurface} = useSpring({
		to: {animSurface: !toggle ? '-150vh' : offset},
//		config: {duration: 1000},
		config: config.wobbly,
	})


	const {i1} = useSpring({to: {i1: toggle ? '9vh' : '-12vh',}, delay: immediate ? 90 : 300, config: iconconfig,})
	const {i2} = useSpring({to: {i2: toggle ? '11vh' : '-12vh',}, delay: immediate ? 90 : 400, config: iconconfig,})
	const {i3} = useSpring({to: {i3: toggle ? '11vh' : '-12vh',}, delay: immediate ? 90 : 500, config: iconconfig,})
	const {i4} = useSpring({to: {i4: toggle ? '12vh' : '-12vh',}, delay: immediate ? 90 : 600, config: iconconfig,})
	const {i5} = useSpring({to: {i5: toggle ? '9vh' : '-12vh',}, delay: immediate ? 90 : 700, config: iconconfig,})
	const {back} = useSpring({to: {back: toggle ? 1 : 0,}, delay: immediate ? 0 : 1200,
		config: {duration: immediate ? 500 : 1000}})

	useEffect(() => {
		const arcAngles = [
			{id: 0, start: -19, stop: -13},
			{id: 3, start: -11, stop: -6},
			{id: 4, start: -3, stop: +2},
			{id: 2, start: 4, stop: 9},
			{id: 1, start: 12, stop: 18},
		]

		arcAngles.map((d, i) => {
			var arc = d3.arc().innerRadius(145).outerRadius(146)
				.startAngle(d.start * (Math.PI / 180))
				.endAngle(d.stop * (Math.PI / 180));

			d3.select("#moCircle").append("g")
				.append("path")
				.attr("id", "arc" + d.id)
				.attr("class", "arc")
				.attr("d", arc)
				.attr("opacity", 0)
				.attr("fill", "green")
				.attr("transform", "translate(50,175)");
		})
	}, [])

	useEffect(() => {
//			setToggle(hovered === 4 ? true : false);
		setToggle(hovered <= 4 ? true : false);
//		console.log("YY hovered", hovered);
		hoverarc();
	}, [hovered])


	function changeMenu () {
		setImmediate(true)
		setToggle(false)
		setTimeout (()=>{
			setHovered(5)
			setImmediate(false)
		}, 250)
	}

	const refCircle=useRef();
//	console.log("refCircle", refCircle.current ? refCircle.current.getBoundingClientRect():'')
//	console.log("refCircle", height1, heightHead);

	return (
		<div key={"fields"} className={'mobileFooter'} style={{height: '300px'}}>
			<div>
				<a.div ref={refCircle} className={'moCircle'} style={{bottom: animSurface, pointerEvents: 'none'}}>
					<svg
						id="moCircle" x="0px" y="0px"
						width="100%"
						height="100%"
						viewBox="0 0 100 60">
						{/*viewBox="0 0 300 360">*/}

						{/*<linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse"*/}
						{/*                x1="40" y1="50" x2="40" y2="5">*/}
						{/*	<stop offset="0" stopColor='#FFFFFF' stopOpacity={1}/>*/}
						{/*	<stop offset="0.85" stopColor='#FFFFFF' stopOpacity={1}/>*/}
						{/*	<stop offset="1" stopColor='#FFFFFF' stopOpacity={0}/>*/}
						{/*</linearGradient>*/}
						{/*<rect x="0" y="5" fill="url(#SVGID_10_)"*/}
						{/*      stroke="#0000FF" strokeWidth={0}*/}
						{/*      strokeMiterlimit="10" width="300" height="40"*/}
						{/*/>*/}

						<circle cx='50' cy='175' r='150' strokeWidth='0.1' stroke='#548235'
						        fill='#CAE4AD'></circle>
					</svg>
				</a.div>
				<a.div className={'fieldIcon1'} style={{bottom: i1}}
				       onClick={() => setHovered(0)}><FieldFamily/></a.div>
				<a.div className={'fieldIcon2'} style={{bottom: i2}}
				       onClick={() => setHovered(3)}><FieldTourism/></a.div>
				<a.div ref={myref} className={'fieldIcon3'} style={{bottom: i3}}
				       onClick={() => setHovered(4)}><FieldModel/></a.div>
				<a.div className={'fieldIcon4'} style={{bottom: i4}}
				       onClick={() => setHovered(2)}><FieldClimate/></a.div>
				<a.div className={'fieldIcon5'} style={{bottom: i5}}
				       onClick={() => setHovered(1)}><FieldDigit/></a.div>
				<a.div className={'backButton'} style={{opacity: back}}
				       onClick={() => setHovered(99)}><HappyLittleTree/></a.div>
				<a.div className={'nextButton'} style={{opacity: back, pointerEvents: toggle ? 'auto' : 'none'}}
				       onClick={() => changeMenu()}>
					{/*<NextButton/>*/}
						weiter
				</a.div>
			</div>
		</div>
	)
}

export default Fields;
