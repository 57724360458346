import React, {useState, useRef, useEffect} from 'react'
import {useSpring, useSprings, a, config} from '@react-spring/web';
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import * as d3 from "d3";

import {useStore} from "./store";
import {HappyLittleTree} from "./icons";
import {Bullet, IconFetcher} from "./icons";
import {FocusPast, FocusPresence, FocusFuture} from "./icons";
import Longtext from "./longtext";
import {contentFocus} from "../assets/content_focus";
import {useWindowDimensions} from "../utils/hooks";

function Focus() {

//	console.log("------ mobile Focus  -----");

	const hovered = useStore(state => state.hovered);

	var field = contentFocus.find((d) => d.id === hovered);
	var longdesc = field ? field.longdesc : [];
	var infobox = field ? field.infobox : [];

	const refVis = useRef([]);

	const [isOpen, setIsOpen] = useState(false);

	const setMoreInfo = useStore(state => state.setMoreInfo);
	const moreInfo = useStore(state => state.moreInfo);
	const [anim, setAnim] = useState(false);
	const [toggle, setToggle] = useState(false);

	// Add a hook to make all links open a new window
	DOMPurify.addHook('afterSanitizeAttributes', function (node) {
		// set all elements owning target to target=_blank
		if ('target' in node) {
			node.setAttribute('target', '_blank');
		}
		// set non-HTML/MathML links to xlink:show=new
		if (
			!node.hasAttribute('target') &&
			(node.hasAttribute('xlink:href') || node.hasAttribute('href'))
		) {
			node.setAttribute('xlink:show', 'new');
		}
	});


	useEffect(() => {
		setToggle(hovered <= 11 && hovered >= 9 ? true : false);
		setAnim(-1)
		setMoreInfo(-1)
	}, [hovered])


	const {o1} = useSpring({
		to: {o1: anim > -1 && refVis.current[anim] ? refVis.current[anim].getBoundingClientRect().height : 0},
		config: config.default,
		onRest: () => setIsOpen(!isOpen),
	})

	const {o2} = useSpring({
		to: {o2: anim > -1 ? 1 : 0},
		config: config.default,
	})

	const [springs, set, stop] = useSprings(5, index => ({
		opacity: 1,
	}))

	useEffect(() => {
		if (toggle) {
			set(index => ({opacity: 0}))
		}
	}, [toggle])

	const {height, width} = useWindowDimensions();
	const [offset, setOffset] = useState('-3vh');

	const height3 = useStore(state => state.height3)
	const heightHead = useStore(state => state.heightHead)

	const scaleOffset = d3.scaleLinear()
		.domain([600, 900])
		.range([68, 77])

	useEffect(()=>{
		setOffset(scaleOffset(height) + 'vh');
	}, [width, height])

	// Clean HTML string and write into our DIV
//		var dirty='<a href="mindfactor.at" target="XX">yyy</a>';
//		var clean = DOMPurify.sanitize(dirty);

	return (
		<div className={'XX2'}>
			<FooterFocus></FooterFocus>
			{/*<Header></Header>*/}
			{
				field && hovered <= 11 && hovered >= 9 &&
				<div className='mobileTextContainer'>
					<div className={'content'} style={{height: (height3-heightHead-heightHead/5)+'px'}}>

					<div className={'HeadingContainer'}>
						<div className={'headings'}>
							<div className={'heading'}>{field.heading}</div>
							<div className={'subheading'}>{field.subheading}</div>
						</div>
						<div className={'mobileicon'} style={{width: parseInt(field.iconwidth)*4+'vw'}}><IconFetcher icon={field.icon}/></div>
					</div>
					{longdesc && longdesc.length > 0 && longdesc.map((desc, i) => {
						let key = "hov_"+hovered+"item_"+i;
						return (
							<div className={'descContainer'} key={'longdesc' + i}>
								<div ref={refVis[i]}
								     className={'descHeader'}>{desc.descHeader ? desc.descHeader : ''}</div>
								<Longtext key={key} val={desc.descText ? desc.descText : ''} new={1} index={i}/>
							</div>
						)
					})
					}
					{infobox && infobox.length > 0 && <div className={'Infobox'}>
						{infobox.map((item, i) => {
							let index = i + longdesc.length;
							return (
								<div key={'ii' + index} className={'InfoboxItem'}>
									<div className={'InfoboxItemHeader'}>
										<div className={'bullet'}><Bullet/></div>
										<div className={'InfoboxItemTitle'}>
											{item.InfoboxItemTitle}
										</div>
									</div>
									<div className={'InfoboxItemSubtext'}>
										{item.InfoboxItemSubtext}
										{moreInfo !== index &&
											<span className={'link'} onClick={() => {
												setAnim(index);
												setTimeout(() => {
													setMoreInfo(index);
												}, 10)
											}}
											>
											[mehr]
											</span>
										}
									</div>
									<a.div width='auto' style={{
										overflow: 'hidden',
										opacity: moreInfo === index ? o2 : 0,
										height: o1.to(h => moreInfo === index ? h + 'px' : '0px'),
									}}
									>
										{/*<div style={{overflow: 'hidden', height: moreInfo === index ? o : '0%'}}>*/}
										{/*{moreInfo === index &&*/}
										<div ref={el => refVis.current[index] = el} className={'InfoboxDesc'}>
											{item.InfoboxDesc.split('\n').map((line, ii, arr) => {
												let key = 'pmo' + ii + '_' + i;
												const lineOut = <span className='descText'
												                      key={i}>{parse(DOMPurify.sanitize(line))}</span>;
												return <div className={'descPar'} key={key}>{lineOut}</div>;
											})}
										</div>
										{/*}*/}
									</a.div>
									<div key={'tw' + index} className={'InfoboxItemSubtext'}>
										{moreInfo === index &&
											<span className={'link'} onClick={() => {
												setAnim(-1);
												// wait a little bit to show div collapsing
												// ToDo: make this dependent in 'isOpen'
												setTimeout(() => {
													setMoreInfo(-1)
												}, 400)
											}}
											>
												{anim > -1 ? '[weniger]' : ''}
											</span>}
									</div>
								</div>
							)
						})}
					</div>
					}
					</div>
				</div>
			}
		</div>
	);
}

function FooterFocus() {

	const [toggle, setToggle] = useState(false);
	const [immediate, setImmediate] = useState(false);

	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);

	const {height, width} = useWindowDimensions();
	const [offset, setOffset] = useState('-3vh');

	const scaleOffset = d3.scaleLinear()
		.domain([600, 700, 900])
		.range([-6.2, -5, -3.5])

	useEffect(()=>{
		setOffset(scaleOffset(height) + 'vh');
	}, [width, height])


	const iconconfig = {
		mass: 5.5,
		tension: 300,
		friction: 42,
		precision: 0.001,
		velocity: 0.01
	}

	function hoverarc() {
		d3.select("#moCircle2").selectAll("rect").attr("fill", "grey").attr("opacity", 0.01)
		d3.selectAll("rect").filter(function () {return d3.select(this).attr("id") === "arc" + hovered})
			.attr("fill", "#777777")
			.attr("opacity", 0.8)
	}

	const {animSurface} = useSpring({
		to: {animSurface: !toggle ? '-150vh' : offset},
//		config: {duration: 1000},
		config: config.wobbly,
	})


	const {i1} = useSpring({to: {i1: toggle ? '9vh' : '-12vh',}, delay: immediate ? 90 : 300, config: iconconfig,})
	const {i2} = useSpring({to: {i2: toggle ? '9vh' : '-12vh',}, delay: immediate ? 90 : 400, config: iconconfig,})
	const {i3} = useSpring({to: {i3: toggle ? '9vh' : '-12vh',}, delay: immediate ? 90 : 500, config: iconconfig,})
	const {back} = useSpring({to: {back: toggle ? 1 : 0,}, delay: immediate ? 0 : 1200, config: {duration: 1000}})

	useEffect(() => {
		const rects = [
			{id: 11,  x: 5,   w: 22  },
			{id: 10, x: 35,  w: 25  },
			{id: 9, x: 68,  w: 29  },
		]

		rects.map((d, i) => {

			d3.select("#moCircle2").append("g")
				.append("rect")
				.attr("id", "arc" + d.id)
				.attr("class", "arc")
				.attr("x", d.x)
				.attr("y", 22)
				.attr("height", 1)
				.attr("width", d.w)
				.attr("opacity", 0.01)
				.attr("fill", "grey")
				.attr("transform", "translate(0,12)");
		})
	}, [])

	useEffect(() => {
//			setToggle(hovered === 4 ? true : false);
		setToggle(hovered <= 11 && hovered >= 9 ? true : false);
//		console.log("hovered", hovered);
		hoverarc();
	}, [hovered])

	function changeMenu () {
		setImmediate(true)
		setToggle(false)
		setTimeout (()=>{
			setHovered(0)
			setImmediate(false)
		}, 250)
	}

	return (
		<div key={"fields"} className={'mobileFooter'} style={{height: '300px'}}>
			<div>
				<a.div className={'moCircle2'} style={{bottom: animSurface, pointerEvents: 'none'}}>
					<svg
						id="moCircle2" x="0px" y="0px"
						width="100%"
						height="100%"
						viewBox="0 0 100 60">
						{/*viewBox="0 0 300 360">*/}

						{/*<linearGradient id="SVGID_1_1" gradientUnits="userSpaceOnUse"*/}
						{/*                x1="50" y1="50" x2="50" y2="5">*/}
						{/*	<stop offset="0" stopColor='#FFFFFF' stopOpacity={1}/>*/}
						{/*	<stop offset="0.7" stopColor='#FFFFFF' stopOpacity={1}/>*/}
						{/*	<stop offset="1" stopColor='#FFFFFF' stopOpacity={0}/>*/}
						{/*</linearGradient>*/}
						{/*<rect x="0" y="5" fill="url(#SVGID_1_1)" stroke="'#0000FF'" strokeWidth={1}*/}
						{/*      strokeMiterlimit="10" width="300" height="50"*/}
						{/*/>*/}

						<circle cx='50' cy='175' r='150' strokeWidth='0.1' stroke='#624D28'
						        fill='#E4DBCA' opacity={0}></circle>
					</svg>
				</a.div>
				<a.div className={'focusIcon1'} style={{bottom: i1}}
				       onClick={() => setHovered(11)}><FocusPast/></a.div>
				<a.div className={'focusIcon2'} style={{bottom: i2}}
				       onClick={() => setHovered(10)}><FocusPresence/></a.div>
				<a.div className={'focusIcon3'} style={{bottom: i3}}
				       onClick={() => setHovered(9)}><FocusFuture/></a.div>
				<a.div className={'backButton'} style={{opacity: back}}
				       onClick={() => setHovered(99)}><HappyLittleTree/></a.div>
				<a.div className={'nextButton'} style={{opacity: back , pointerEvents: toggle ? 'auto' : 'none'}}
				       onClick={() =>changeMenu()}>
					{/*<NextButton/>*/}
						weiter
				</a.div>
			</div>
		</div>
	)
}

export default Focus;
