import React, {useState, useEffect, useRef} from 'react'
import TruncateMarkup from 'react-truncate-markup'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import {useSpring, a, config} from '@react-spring/web';

import {useWindowDimensions} from "../utils/hooks";
import {useStore} from "./store";
import {isSafari} from "react-device-detect";


export default function Longtext(arg) {

	const {height, width} = useWindowDimensions();

	const hovered = useStore(state => state.hovered);

	const isMo = useStore(state => state.isMobile);

	const focus = useStore(state => state.focus);
	const setFocus = useStore(state => state.setFocus);

	var longText = arg.val && arg.val !== 'NULL' ? arg.val : ' ';
	var index = parseInt(arg.index);
	var wasActive = parseInt(arg.wasActive);

	const setMoreInfo = useStore(state => state.setMoreInfo);
	const moreInfo = useStore(state => state.moreInfo);

	const [isOpen, setIsOpen] = useState(false);
	const [anim, setAnim] = useState(-1);
	const [preanim, setPreanim] = useState(false);

	const colref = useRef([]);
	const expref = useRef();

	const [bounding, setBounding] = useState();

	// hover changed
	useEffect(()=> {
		setIsOpen(false)
		setAnim(-1)
		setPreanim(false)
		setBounding(null)
	},[hovered])


	useEffect(() => {
//		setBounding(!bounding && colref.current ? colref.current.getBoundingClientRect().height : 0)  // XXX check why no bounding
		setBounding(colref.current ? colref.current.getBoundingClientRect().height : 0)
	}, [colref, width, height, hovered])


	const {o1} = useSpring({
		to: {o1: moreInfo > -1 && colref.current ? bounding - 15 : 70},
		config: config.default,
		onRest: () => setIsOpen(!isOpen),
	})

	const {o2} = useSpring({
		to: {o2: !preanim ? bounding - 15 : 0},
		config: config.default,
	})

	useEffect(()=>{
		if (moreInfo > -1 && moreInfo !== index) {
			setAnim(-1);
		}
	}, [moreInfo])

	// truncate by default if a new item is selected
	useEffect(() => {
		if (arg.new && arg.new !== -1) {
			setMoreInfo(-1);
		}
	}, [arg.new]);

	//	longText = longText.replace(/<br.>/g, '\n');
	//	longText = longText.replace(/\n{1,4}/g, '\n');
	//	longText = longText.replace(/\r\n/g, '\n');
	//	longText = longText.replace(/\n\r/g, '\n');
	//	longText = longText.replace(/\n\n/g, '\n');
	//	longText = longText.replace(/\n\n/g, '\n');
	//	longText = longText.replace(/\n$/g, '');

	const selstyle = {borderLeft: '3px solid #DEDEDE', paddingLeft: '5px'}
	const noselstyle = {borderLeft: '3px solid #FAFAFA', paddingLeft: '5px'}

	// https://react-truncate-markup-patrik-piskay.vercel.app/
	const readMoreEllipsis = (
		<span>
            &nbsp;...{' '}
			<button className={'link'} onClick={() => {
				if (colref.current) {
					isMo || isSafari ? colref.current.blur(): colref.current.focus() // avoid focus on mobile
				}
				setAnim(index)
				setMoreInfo(index)
			}}>
	            [mehr]
	        </button>
        </span>
	);


	return (
		<div tabIndex={0} ref={colref} style={moreInfo === index || wasActive === index ? selstyle : noselstyle}>
			<TruncateMarkup
				lines={anim === index ? 500 : 5}
				tokenize="words"
				ellipsis={readMoreEllipsis}
//				onTruncate={() => console.log('truncate')}
			>
				<a.div
					ref={expref}
					className={'descText'}
					style={{
						height: preanim ? o2.to (h => h + 'px') : o1.to(h => anim > -1 ? h + 'px' : 'auto')
				}}
				>
					{longText.split('\n').map((line, i, arr) => {
						let key = 'pmo_' + index + '_' + i;
						const lineOut = <span className='descText'
						                      key={i}>{parse(DOMPurify.sanitize(line))}</span>;
						return <div className={'descPar'} key={key}>{lineOut}</div>;
					})}
				</a.div>
			</TruncateMarkup>
			{anim === index &&
				<button className={"linkLess"} onClick={() => {
					setPreanim(true);
					if (isSafari || isMo) colref.current.blur(); // avoid focus on mobile
					setTimeout(()=>{
						setAnim(-1)
						setMoreInfo(-1)
						setPreanim(false);
					}, 240)
				}}>
				[weniger]
				</button>
			}
		</div>
	)

}
