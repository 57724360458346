import React, {useEffect, useState} from "react";
import {useStore} from "./store";
import {Burger} from "./icons";


export default function Mobileheader() {

	const [show, setShow] = useState(false)
	const [title, setTitle] = useState('Home')
	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);

	const handleclick = (e) => {
		setHovered(e)
		setShow(false)
	}


	useEffect(() => {
		if (hovered === -1 || hovered === 99) setTitle('Home')
		if (hovered >= 0 && hovered <= 4) setTitle('Handlungsfelder')
		if (hovered >= 5 && hovered <= 8) setTitle('Leitlinien')
		if (hovered >= 9 && hovered <= 11) setTitle('Fokus')
		if (hovered === 12) setTitle('Einleitung')
		if (hovered === 13) setTitle('Impressum')
		if (hovered === 14) setTitle('Organisationsstrukturen')
		if (hovered === 15) setTitle('PDF herunterladen')
		if (hovered === 16) setTitle('Datenschutzerklärung')
	}, [hovered])


	return (
		<div className={'head'}>
			<div className={'mobileHeader'}>
				<div className={'mobileheadBox'}>
					<div className={'headTitle'}>{title}</div>
					<div className={'mobileBurger'} onClick={() => setShow(!show)}>
						<Burger arg={show}/>
					</div>
				</div>
			</div>
			{show &&
				<div className={'mobileBurgerMenu'}>
					<div className={'mobileList'}>
						<div className={'mobileBurgerItem'} onClick={() => handleclick(99)}>Home</div>
						<div className={'mobileBurgerItem'} onClick={() => handleclick(12)}>Einleitung</div>
						<div className={'mobileBurgerItem'} onClick={() => handleclick(14)}>Organisationsstrukturen</div>
						<div className={'mobileBurgerItem'} onClick={() => handleclick(15)}>PDF herunterladen</div>
						<div className={'mobileBurgerItem'} onClick={() => handleclick(13)}>Impressum</div>
						<div className={'mobileBurgerItemLast'} onClick={() => handleclick(16)}>Datenschutzerklärung</div>
						{/*<div className={'mobileBurgerItem'} onClick={() => handleclick(15)}>Download als PDF</div>*/}
					</div>
				</div>
			}
		</div>
	)
}
