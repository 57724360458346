import logo from './logo.svg';
import './App.css';
import './css/mobileApp.css';

import React, {useState, useRef, useEffect, useLayoutEffect} from 'react'
import {useSpring, a, config} from '@react-spring/web';
import {isMobileOnly, BrowserTypes, isIOS, isSafari, deviceDetect} from 'react-device-detect';
import * as qs from 'query-string';
import {useWindowDimensions} from "./utils/hooks";

import {useStore} from "./components/store";
import Tree from "./components/tree";
import Header from "./components/header";
import Fields from "./components/fields";
import Guidelines from "./components/guidelines";
import Focus from "./components/focus";
import More from "./components/more";

import MobileTree from "./components/mobiletree";
import Mobileheader from "./components/mobileheader";
import MobileFields from "./components/mobilefields";
import Mobileguidelines from "./components/mobileguidelines";
import MobileFocus from "./components/mobilefocus";
import MobileMore from "./components/mobilemore";
import * as d3 from "d3";
import {FieldModel, FocusPresence, GuidelineCreat} from "./components/icons";

function App() {

//	console.log("------ APP -----");

	const [init, setInit] = useState(false)

	const {height, width} = useWindowDimensions();

	const setDevice = useStore(state => state.setDevice);

	const setHeightHead = useStore(state => state.setHeightHead);
	const setHeight1 = useStore(state => state.setHeight1);
	const setHeight2 = useStore(state => state.setHeight2);
	const setHeight3 = useStore(state => state.setHeight3);

	const refFull = useRef();
	const refHead = useRef();
	const refHeight1 = useRef();
	const refHeight2 = useRef();
	const refHeight3 = useRef();

	const setIsMo = useStore(state => state.setIsMobile);
	const isMo = useStore(state => state.isMobile);

	const setIsLandscape = useStore(state => state.setIsLandscape);
	const isLandscape = useStore(state => state.isLandscape);

	const setQuery = useStore(state => state.setQuery);
	const debugLevel = useStore(state => state.debugLevel);
	const setDebugLevel = useStore(state => state.setDebugLevel);

	const hovered = useStore(state => state.hovered);
	const setHovered = useStore(state => state.setHovered);

	const {animTree} = useSpring({
		to: {animTree: hovered < 99 ? '150vh' : '0vh'},
		delay: 10,
//		config: {duration: 1000},
		config: config.slow,
	})

	useLayoutEffect(()=>{
//		console.log("dimensions changed: ", height, width, isMobileOnly)
//		console.log("landscape mode ", width > height ? 'ON' : 'OFF')
		setIsMo(isMobileOnly ? true : false)
		setIsLandscape( width > height ? true : false)

		if (width > height && isMobileOnly && (hovered === 99 || hovered === -1)) {
			setHovered(12)
		}

	}, [height, width])

	// check the query
	useLayoutEffect(() => {
		// parse URL/query string parameters
		// ex: ?debug=1 switches to debugmode
		let parsed = qs.parse(window.location.search);
		setQuery({queryParams: parsed});

		if (parsed.debug && parseInt(parsed.debug) > -1 && parseInt(parsed.debug) < 2) {
			console.log("debug level: ", parsed.debug);
			if (parseInt(parsed.debug) === 1) {
				setDebugLevel(1);
			}
		}

	}, [init, width, height])


	// check if device is mobile or screensize changed
	useEffect(() => {
//		console.log("device ismobile? ", isMobileOnly);
//				console.log("device ? ", BrowserTypes);
//				console.log("device safari? ", isSafari);
//				console.log("device landscape? ", isLandscape);

		setInit(true);
//		setHovered(isMobileOnly && !isLandscape ? 99 : Math.floor(Math.random() * 4))

		const stations = [
			{station: 1,  delay: 2500},
			{station: 6,  delay: 4000},
			{station: 9,  delay: 5500},
			{station: 12, delay: 7000},
		]

		if (!isMobileOnly && height < width) {
			stations.map((d, i) => {
				setTimeout(()=>{
					setHovered(d.station)
				}, d.delay)
			})
		}

	}, [])


	const [offset, setOffset] = useState('3vh');

	const scaleOffset = d3.scaleLinear()
		.domain([500, 600, 900])
		.range([0, 0, 6])

	useEffect(()=>{
		setOffset(scaleOffset(height) + 'vh');
	}, [width, height])

	useEffect(()=>{

		setHeightHead(refHead.current ? refFull.current.getBoundingClientRect().height/100*7 : 50)
		setHeight1(refHeight1.current ? refHeight1.current.getBoundingClientRect().y : 500)
		setHeight2(refHeight2.current ? refHeight2.current.getBoundingClientRect().y : 500)
		setHeight3(refHeight3.current ? refHeight3.current.getBoundingClientRect().y : 500)

	}, [width, height, init])

	return (
		<>
			{/*<div className={'dummyline'}></div>*/}
			{/*<div className={'dummyline2'}> </div>*/}
			<div className={'dummy'} ref={e => refFull.current = e}></div>

			{isMo && !isLandscape ?
				<div className="mobileApp">
					{/* place some dummy icons to measure height */}
					<div ref={e => refHeight1.current = e} className={'fieldIcon3'} style={{bottom: '11vh', opacity: 0.0}}><FieldModel/></div>
					<div ref={e => refHeight2.current = e} className={'guidelineIcon2'} style={{bottom: '10.8vh', opacity: 0.0}}><GuidelineCreat/></div>
					<div ref={e => refHeight3.current = e} className={'focusIcon2'} style={{bottom: '9vh', opacity: 0.0}}><FocusPresence/></div>
					{/*<Footer></Footer>*/}
					<div className="mobileContainer">
						{isMobileOnly}
						<div ref={e => refHead.current = e}>
						<Mobileheader></Mobileheader>
						</div>
						<div className='mobileTreeContainer'>
							<a.div className={'mobiletree'} style={{top: animTree}}>
								<div className={'mobiletitle'}>Strategie für&nbsp;Kunst&nbsp;und&nbsp;Kultur des&nbsp;Landes&nbsp;Niederösterreich
								</div>
								<div className={'mobilesubtitle'}>Bitte wählen Sie Ihr Themenfeld:</div>
								<div className={'mobiletreeSVG'} style={{marginTop: offset}}><MobileTree/></div>
							</a.div>
						</div>
						<MobileFields></MobileFields>
						<MobileMore></MobileMore>
						<Mobileguidelines></Mobileguidelines>
						<MobileFocus></MobileFocus>
					</div>
				</div>
				:
				<div className="App">
					{/*<Header></Header>*/}
					<header className="Container">
						<div className='TreeContainer'>
							<div className={'header'}>
								<Header tabIndex={0}/>
								<div tabIndex={0} className={'title'}>Strategie für Kunst und Kultur
									des Landes Niederösterreich
							<       div className={'subtitle'}>Bitte wählen Sie Ihr Themenfeld:</div>
								</div>
							</div>
							<div className={'tree'}><Tree/></div>
						</div>
						{hovered >= 0 && hovered <= 4 && <Fields tabIndex={6} />}
						{hovered >= 5 && hovered <= 8 && <Guidelines tabIndex={7} />}
						{hovered >= 9 && hovered <= 11 && <Focus tabIndex={8} />}
						{hovered >= 12 && hovered <= 16 && <More tabIndex={9}/>}
					</header>
				</div>
			}

		</>
	);
}

export default App;
